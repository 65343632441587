<ng-container *ngIf="!hasTier else page">
    <app-alternative-state-page
        headerIcon="assets/centralisedManagement/icons/subscription_required.svg"
        header="auditing_upgrade_title"
        messageString="auditing_upgrade_message"
        imagePath="assets/centralisedManagement/empty-state/samlNoTier.svg"
        [redirectToButton]="true">
    </app-alternative-state-page>
</ng-container>
<ng-template #page>
    <div class="component-main">
        <div class="content-space-between">
            <div class="content-left">
                <h1 id="component-title">{{ 'auditing' | myLbTranslate }}</h1>
            </div>
        </div>
        <div class="sitInlineWithSearch">
            <span>{{ 'rows' | myLbTranslate | titlecase }}:</span>
            <app-dropdown
                dropDownId="show-rows"
                [selectedKey]="dropdown.rows[0]?.key"
                [displayAsInput]="true"
                [content]="dropdown.rows"
                [dropdownWidth]="250"
                (selectedOut)="updateTable($event)">
            </app-dropdown>
        </div>

        <app-table
            id="audit-list"
            [data]="tableRows"
            [showPaginator]="false"
            [allowMultiSelect]="false">
            <app-column [flexGrow]="0.5" prop="timestamp" label="auditing_session_time"></app-column>
            <app-column [flexGrow]="0.5" prop="performedBy" label="user"></app-column>
            <app-column
                [flexGrow]="0.5" prop="action" label="auditing_activity_type" [cellTemplate]="activityType">
                <ng-template let-row #activityType>
                    <ng-container *ngIf="row.actionImageName">
                        <img ngSrc="{{row.actionImageName}}" height="20" width="20" alt="{{row.actionImageName}} icon">
                    </ng-container>
                    <span>{{ row.action | sentenceCase }}</span>
                </ng-template>
            </app-column>
            <app-column [flexGrow]="2" prop="details" label="auditing_session_log"></app-column>
        </app-table>
    </div>
</ng-template>
