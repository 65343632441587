<div class="component-main">
    <div class="content">
        <h1>{{ (editId ? 'ip_edit_group' : 'ip_add_group') | myLbTranslate }}</h1>
    </div>
    <form [formGroup]="formGroup">
        <div class="form-group">
            <label for="name">{{ 'name' | myLbTranslate }}*</label>
            <div class="form-content">
                <input
                    title="{{'name' | myLbTranslate }}"
                    type="text"
                    size="30"
                    name="name"
                    id="name"
                    formControlName="name"/>
                <app-validation-messages [control]="formGroup.get('name')"></app-validation-messages>
                <app-help tag="ip-group_name"></app-help>
            </div>
        </div>
        <div class="form-group">
            <label for="networkIp">{{ 'network_address' | myLbTranslate }}*</label>
            <div class="form-content">
                <input
                    title="{{'network_address' | myLbTranslate }}"
                    type="text"
                    size="30"
                    id="networkIp"
                    formControlName="networkIp"/>
                <app-validation-messages [control]="formGroup.get('networkIp')"></app-validation-messages>
                <app-help tag="ip-group_ip-network"></app-help>
            </div>
        </div>
        <div class="form-group">
            <!--suppress XmlInvalidId -->
            <label for="mask">{{ 'mask' | myLbTranslate }}*</label>
            <div class="form-content">
                <app-dropdown
                    dropDownId="mask"
                    formControlName="networkMask"
                    [formGroup]="formGroup"
                    [content]="dropdown.mask">
                </app-dropdown>
                <app-help tag="ip-group_ip-mask"></app-help>
            </div>
        </div>
        <div class="form-group">
            <!--suppress XmlInvalidId -->
            <label for="ip-groups-interface">{{ 'interface' | myLbTranslate }}*</label>
            <div class="form-content">
                <app-dropdown
                    dropDownId="ip-groups-interface"
                    formControlName="interfaces"
                    [formGroup]="formGroup"
                    [content]="dropdown.interfaces">
                </app-dropdown>
                <app-help tag="ip-group_interface"></app-help>
            </div>
        </div>
        <div class="form-group">
            <label for="gateway">{{ 'gateway' | myLbTranslate }}</label>
            <div class="form-content">
                <input
                    title="{{'gateway' | myLbTranslate }}"
                    type="text"
                    name="gateway"
                    id="gateway"
                    formControlName="gateway"/>
                <app-validation-messages [control]="formGroup.get('gateway')"></app-validation-messages>
                <app-help tag="ip-group_gateway"></app-help>
            </div>
        </div>
        <div class="form-group">
            <div class="form-content">
                <input id="defaultGateway" formControlName="defaultGateway" class="lb-checkbox" type="checkbox">
                <label for="defaultGateway">{{ 'routing_default_gateway' | myLbTranslate }}</label>
            </div>
        </div>
    </form>
</div>
<div class="component-footer">
    <button
        id="new-ip-groups-cancel"
        class="outline"
        (click)="navigateToURL('/network/ip-groups')">
        {{ 'cancel' | myLbTranslate }}
    </button>
    <app-loading-button
        buttonId="new-ip-groups-add"
        buttonClass="primary"
        [buttonText]="editId ? 'update': 'add'"
        [isDisabled]="formGroup.invalid || formGroup.pristine"
        [callBack]="submitGroupCheck.bind(this)">
    </app-loading-button>
</div>
