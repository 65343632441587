<div [hidden]="isCipherPageVisible || isCertPageVisible || isEditCertPageVisible || isAddEditCertsPageVisible">
    <div class="content-space-between embedded">
        <h2 class="embedded">{{ 'certificates' | myLbTranslate }}</h2>
        <div>
            <button
                class="primary"
                [disabled]="!permissionsLookup['ssl-offload-manage-ciphers']"
                (click)="openManageCipherListsPage()">
                {{ 'ssl_offload_manage_cipher_list' | myLbTranslate }}
            </button>
            <button
                class="primary"
                [disabled]="!permissionsLookup['ssl-offload-select-cert']
                || (usableCerts && certsInUse && usableCerts.length === certsInUse.length)"
                (click)="toggleCertPage(true)">
                {{ 'certificate_select_cert' | myLbTranslate }}
            </button>
            <button
                class="primary"
                [disabled]="!permissionsLookup['ssl-offload-create-cert']"
                (click)="createCertificate()">
                {{ 'certificate_create_cert' | myLbTranslate }}
            </button>
        </div>
    </div>
    <app-table id="ssl-offload" [data]="sslTableRows" [selectedRows]="selectedRow">
        <app-column [flexGrow]="1.45" prop="name" label="name" [cellTemplate]="rowLabel">
            <ng-template let-row #rowLabel>
                {{ row.name }}
                <ng-container *ngIf="checkCertValidation(row.id)">
                    <app-validation-messages [control]="certsForm.controls['certs']" [forceShow]="true">
                    </app-validation-messages>
                </ng-container>
            </ng-template>
        </app-column>
        <app-column [flexGrow]="1.25" prop="cipher" label="cipher"></app-column>
        <app-column [flexGrow]="0.65" prop="validTo" label="valid_to"></app-column>
        <app-column
            [flexGrow]="0.65"
            prop="actions"
            label=""
            cellAlignment="right"
            [cellTemplate]="offloadActions">
            <ng-template let-row #offloadActions>
                <app-action-dots>
                    <button
                        [disabled]="!permissionsLookup['ssl-offload-edit-cert']"
                        (click)="toggleEditCertPage(true, row)">{{ 'edit' | myLbTranslate }}
                    </button>
                    <button
                        (click)="deleteAnSSLTermination(row)">{{ 'delete' | myLbTranslate }}
                    </button>
                </app-action-dots>
            </ng-template>
        </app-column>
    </app-table>
</div>

<ng-container #cipherListContainer></ng-container>
<ng-container #addEditCertsContainer></ng-container>

<ng-container *ngIf="isCertPageVisible">
    <app-ssl-offload-add-cert
        [componentEmbedded]="true"
        [SSLCerts]="SSLCerts"
        [minCipherSecVersion]="minCipherSecVersion"
        [maxCipherSecVersion]="maxCipherSecVersion"
        [certsInUse]="certsInUse"
        (submittedCerts)="addCertsToTable($event)"
        (closeCertPage)="toggleCertPage($event)">
    </app-ssl-offload-add-cert>
</ng-container>

<ng-container *ngIf="isEditCertPageVisible">
    <app-ssl-offload-edit-cert
        [SSLCerts]="SSLCerts"
        [certData]="editData"
        (certs)="getUpdatedCerts($event)"
        (closeEditCertPage)="toggleEditCertPage($event)">
    </app-ssl-offload-edit-cert>
</ng-container>
