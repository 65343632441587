<div class="component-main equal-padding">
    <div class="content-space-between">
        <h1 id="component-title">{{ 'subscriptions' | myLbTranslate | titlecase }}</h1>
    </div>
    <form [formGroup]="formGroup">
        <div class="content toggle-wrapper">
            <span>{{ 'subscriptions_annual_plan' | myLbTranslate }}
                <span class="discount">(20% {{ 'off' |myLbTranslate }})</span>
            </span>
            <input type="checkbox" id="switch" class="toggle-switch" formControlName="toggle">
            <label for="switch"></label>
            <span>{{ 'subscriptions_monthly_plan' | myLbTranslate }}</span>
        </div>
    </form>

    <div class="grid">
        <ng-container *ngFor="let tier of tiers">
            <div class="grid__item width-3/12" [class.selected]="selectedPlan === tier.plan">
                <div *ngIf="selectedPlan === tier.plan" class="selected-text">
                    {{ 'selected' | myLbTranslate | titlecase }}
                </div>
                <div class="grid-content-column-space-between">
                    <div class="grid-content-column">
                        <span class="title">{{ tier.title | myLbTranslate }}</span>
                        <span class="sub-title primary-text">{{ tier.subTitle | myLbTranslate }}</span>
                        <div class="divider-line"></div>
                        <div class="price-wrapper">
                            <span class="price">{{ toggle ? tier.price.monthly : tier.price.annual }}</span>
                            <span *ngIf="tier.plan !== 'tailored'" class="term">/mo</span>
                        </div>
                        <div class="price-wrapper annual">
                            <ng-container *ngIf="!toggle && tier.priceDetails">
                                <span class="annual-price">
                                    {{ tier.priceDetails.title | myLbTranslate }}
                                    <strong>{{ tier.priceDetails.price }} </strong>
                                </span>
                                <span class="term">{{ tier.priceDetails.term }} </span>
                            </ng-container>
                        </div>
                        <button
                            class="primary"
                            [disabled]="selectedPlan === tier.plan"
                            (click)="contactSales(tier.intercomMessage)">
                            {{ (selectedPlan === tier.plan ? 'subscriptions_current_plan' : 'contact_sales') | myLbTranslate | titlecase }}
                        </button>

                        <div class="divider-line"></div>
                        <span class="sub-title">{{ 'subscriptions_adc_connections' | myLbTranslate }}</span>
                        <span class="title light primary-text">{{ getConnectionsMessage(tier.connections) }}</span>
                        <div class="divider-line"></div>
                        <span class="sub-title">{{ 'adc_backups' | myLbTranslate }}</span>
                        <span class="title light primary-text">{{ getBackupCounterMessage(tier.backups) }}</span>
                        <span class="history">{{ tier.history | myLbTranslate }}</span>
                        <div class="divider-line"></div>
                        <span
                            class="sub-title">{{ 'subscriptions_support_level' | myLbTranslate }}</span>
                        <span class="title light primary-text">
                            {{ tier.supportLevel.includes('_') ? (tier.supportLevel | myLbTranslate) : tier.supportLevel }}
                        </span>
                        <span class="history">{{ tier.supportMessage | myLbTranslate }}</span>
                        <div class="divider-line"></div>

                        <span class="list-title">{{ 'portal' | myLbTranslate }}</span>
                        <ul>
                            <li *ngFor="let item of tier.portalList">{{ item | myLbTranslate }}</li>
                        </ul>
                        <span class="list-title">{{ 'adc_access' |myLbTranslate }}</span>
                        <ul>
                            <li *ngFor="let item of tier.accessList">{{ item | myLbTranslate }}</li>
                        </ul>
                        <span class="list-title">{{ 'security' | myLbTranslate }}</span>
                        <ul>
                            <li *ngFor="let item of tier.security">{{ item | myLbTranslate }}</li>
                        </ul>
                        <span class="list-title">{{ 'coming_soon' | myLbTranslate }}</span>
                        <ul>
                            <li *ngFor="let item of tier.comingSoon">{{ item | myLbTranslate }}</li>
                        </ul>
                    </div>
                    <div class="grid-content-column-bottom">

                    </div>
                </div>
            </div>
        </ng-container>
    </div>

    <div class="grid footer">
        <div class="grid__item width-12/12 footer-gradient-background">
            <div class="grid-content-middle-space-between">
                <div class="grid-content-middle">
                    <img src="/assets/shared/icons/add-adc.svg" class="adc-icon" alt="adc icon"/>
                    <div class="grid-content-column-middle">
                        <span class="footer-title">{{ 'subscriptions_need_more_connections' | myLbTranslate }}</span>
                        <span class="footer-message">
                            {{ 'subscriptions_add_more_connections' | myLbTranslate }}
                            <strong>{{ toggle ? '$149' : '$119' }}
                                /{{ 'month' | myLbTranslate | lowercase }}</strong>.
                        </span>
                    </div>
                </div>
                <button class="outline" (click)="contactSales(intercomMessage.general)">
                    {{ 'contact_sales' | myLbTranslate | titlecase }}
                </button>
            </div>
        </div>
        <div class="grid__item width-12/12">
            <div class="grid-content-middle-space-between">
                <div class="grid-content-middle">
                    <img src="/assets/shared/icons/user-headset.svg" class="support-icon" alt="Support icon"/>
                    <div class="grid-content-column-middle">
                        <span class="footer-title">{{ 'subscriptions_enterprise_demo_message' | myLbTranslate }}</span>
                        <span
                            class="footer-message">{{ 'subscriptions_tailored_subscription_message' | myLbTranslate }}</span>
                    </div>
                </div>
                <button class="primary" (click)="contactSales(intercomMessage.tailored)">
                    {{ 'subscriptions_book_a_demo' | myLbTranslate | titlecase }}
                </button>
            </div>
        </div>
    </div>
</div>
