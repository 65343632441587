import { TemplateModel } from '../../models';
import { WizardTitleEnum } from '../../enums';

export const Layer4Template: TemplateModel = {
    title: 'Layer 4',
    version: 1,
    type: 'layer4',
    modes: ['tcp', 'udp', 'tcp udp', 'one packet scheduling'],
    disabled: false,
    description: {
        [WizardTitleEnum.TEMPLATE]: `This is a default Layer 4 template, that runs you through setting up a frontend,
        backend and your chosen number of real servers.`
    },
    sections: {
        [WizardTitleEnum.FRONTENDS]: [
            {
                title: 'Frontend',
                subSteps: 2,
                // General
                name: {
                    value: '',
                    hidden: false,
                },
                forwardingMethod: {
                    value: null,
                    hidden: false,
                },
                mode: {
                    value: 'tcp+udp',
                    hidden: false
                },
                ports: {
                    value: null,
                    hidden: false,
                },
                fipId: {
                    value: null,
                    hidden: false
                },
                fipEnabled: {
                    value: true,
                    hidden: false
                }
            }
        ],
        [WizardTitleEnum.BACKENDS]: [
            {
                title: 'Backend',
                subSteps: 4,
                // General Options
                name: {
                    value: '',
                    hidden: false
                },
                balanceMode: {
                    value: 'leastconn',
                    hidden: false
                },
                //  Persistence options
                persistenceEnabled: {
                    value: false,
                    hidden: false
                },
                persistenceTimeout: {
                    value: '300',
                    hidden: true
                },
                granularity: {
                    value: '32',
                    hidden: true
                },
                // Health check options
                healthCheckType: {
                    value: 'connectToPort',
                    hidden: false
                },
                // Feedback
                feedbackMethod: {
                    value: 'none',
                    hidden: false
                },
                feedbackAgentPort: {
                    value: '',
                    hidden: true
                },
                // Fallback server
                fallbackServerId: {
                    value: 'none',
                    hidden: false
                },
                fallbackPort: {
                    value: 80,
                    hidden: false
                },
                enabledMASQFallback: {
                    value: false,
                    hidden: false
                },
            }
        ],
        [WizardTitleEnum.REALSERVERS]: [
            {
                title: 'Real Server',
                backendId: {
                    value: '',
                    hidden: false
                },
                name: {
                    value: '',
                    hidden: false
                },
                ipAddress: {
                    value: '',
                    hidden: false
                },
                count: {
                    value: 1,
                    hidden: false
                },
                port: {
                    value: 80,
                    hidden: false
                },
                weight: {
                    value: 100,
                    hidden: false
                },
                minimumConnections: {
                    value: 0,
                    hidden: false
                },
                maximumConnections: {
                    value: 0,
                    hidden: false
                }
            }
        ],
        [WizardTitleEnum.ACLS]: [
            {
                title: 'Acls Step',
                conditionType: {
                    value: 'default',
                    hidden: true,
                },
                redirectLocation: {
                    value: '$1',
                    hidden: true
                },
                redirectType: {
                    value: 'backend',
                    hidden: true
                }
            }
        ]
    }
};
