<ng-container *ngIf="!hasTier else page">
    <app-alternative-state-page
        headerIcon="assets/centralisedManagement/icons/subscription_required.svg"
        header="saml_upgrade_title"
        messageString="saml_upgrade_message"
        imagePath="assets/centralisedManagement/empty-state/samlNoTier.svg"
        [redirectToButton]="true">
    </app-alternative-state-page>
</ng-container>
<ng-template #page>
    <ng-container *ngIf="isLoading else pageContent">
        <div class="page-loader"></div>
    </ng-container>
    <ng-template #pageContent>
        <div class="component-main">
            <div class="content-space-between">
                <h1 id="component-title">{{ 'saml_title' | myLbTranslate }}</h1>
            </div>
            <div *ngIf="activeStep === 0" class="step">
                <div class="auth">
                    <div class="content-space-between">
                        <img
                            ngSrc="assets/centralisedManagement/icons/sso-saml.svg"
                            alt="SAML single sign on SSO icon"
                            height="20"
                            width="21">
                        <span class="sso-name">SAML</span>
                        <button
                            id="saml-delete-config"
                            class="primary"
                            [disabled]="!permissionsLookup['saml-delete-config']"
                            (click)="confirmDeleteSamlConfig()">
                            {{ 'disconnect' | myLbTranslate }}
                        </button>
                    </div>
                </div>
                <br>
                <div class="auth">
                    <form [formGroup]="enforceFormGroup">
                        <div class="content-space-between">
                            <div class="content-left">
                                <h4>{{ 'saml_setup_form_enforce'| myLbTranslate }}</h4>
                            </div>
                            <div class="content-right">
                                <input
                                    id="samlEnforced"
                                    class="lb-checkbox"
                                    type="checkbox"
                                    formControlName="samlEnforced"/>
                                <label for="samlEnforced">
                                    {{ 'enforce'| myLbTranslate }}
                                </label>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div *ngIf="activeStep === 1" class="step">
                <h2>{{ 'saml_title_what_is' | myLbTranslate }}</h2>
                <p>{{ 'saml_title_message' | myLbTranslate }}</p>
                <p>{{ 'saml_title_message_help' | myLbTranslate }}
                    <a class="default" (click)="getHelp()">{{ 'saml_title_message_help_link' | myLbTranslate }}</a>.
                </p>

                <div class="copy-content">
                    <div class="form-group">
                        <label>{{ 'saml_title_entityId' | myLbTranslate }}</label>
                        <div class="form-content">
                            <p>{{ entityId }}</p>
                            <button appCopyClipboard class="copy-icon" [updateButtonText]=false [textToCopy]="entityId">
                            </button>
                        </div>
                    </div>
                    <div class="form-group">
                        <label>{{ 'saml_title_issuer' | myLbTranslate }}</label>
                        <div class="form-content">
                            <p>{{ issuerId }}</p>
                            <button appCopyClipboard class="copy-icon" [updateButtonText]=false [textToCopy]="issuerId">
                            </button>
                        </div>
                    </div>
                    <div class="form-group">
                        <label>{{ 'saml_title_sso_url' | myLbTranslate }}</label>
                        <div class="form-content">
                            <p>{{ ssoUrl }}</p>
                            <button appCopyClipboard class="copy-icon" [updateButtonText]=false [textToCopy]="ssoUrl">
                            </button>
                        </div>
                    </div>
                    <div class="form-group">
                        <label>{{ 'saml_title_name_id' | myLbTranslate }}</label>
                        <div class="form-content">
                            <p>{{ nameId }}</p>
                        </div>
                    </div>
                </div>
                <div class="copy-content">
                    <p>{{ 'saml_title_xml_message' | myLbTranslate }}</p>
                    <button class="download-button" (click)="downloadXmlFile()">
                        <img ngSrc="assets/shared/icons/download.svg" alt="download icon" height="17" width="15">
                        {{ 'saml_download_xml' | myLbTranslate }}
                    </button>
                </div>
            </div>
            <div *ngIf="activeStep === 2" class="step">
                <h2>{{ 'saml_setup_need_help' | myLbTranslate }}</h2>
                <p>{{ 'saml_setup_message' | myLbTranslate }}
                    <a class="default" (click)="getHelp()">{{ 'saml_title_message_help_link' | myLbTranslate }}</a>.
                </p>
                <form [formGroup]="formGroup">
                    <div class="form-group">
                        <label for="metaData">
                            {{ 'saml_setup_form_label' | myLbTranslate }}*
                        </label>
                        <div class="form-content">
                            <input
                                type="text"
                                name="metaData"
                                id="metaData"
                                formControlName="metaDataLink">
                            <app-validation-messages
                                [control]="formGroup.get('metaDataLink')"></app-validation-messages>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <div *ngIf="activeStep === 0" class="component-footer">
            <app-loading-button
                buttonId="saml-submit-enforce"
                buttonClass="primary"
                buttonText="submit"
                [isDisabled]="!permissionsLookup['saml-submit-config'] || enforceFormGroup.pristine"
                [callBack]="submitEnforce.bind(this)">
            </app-loading-button>
        </div>
        <div *ngIf="activeStep > 0" class="component-footer">
            <ng-container *ngIf="activeStep === 1">
                <button class="primary" (click)="next()">{{ 'next' | myLbTranslate }}</button>
            </ng-container>
            <ng-container *ngIf="activeStep === 2">
                <button class="outline" (click)="back()">{{ 'cancel' | myLbTranslate }}</button>
                <app-loading-button
                    buttonId="saml-submit-config"
                    buttonClass="primary"
                    buttonText="submit"
                    [isDisabled]="!permissionsLookup['saml-submit-config'] || formGroup.invalid"
                    [callBack]="submit.bind(this)">
                </app-loading-button>
            </ng-container>
        </div>
    </ng-template>
</ng-template>
