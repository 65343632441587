import { TemplateModel } from '../../models';
import { WizardTitleEnum } from '../../enums';

export const Layer7Template: TemplateModel = {
    title: 'Layer 7',
    version: 1,
    type: 'layer7',
    modes: ['tcp', 'http'],
    wizardDefaultMode: 'default',
    wizardModes: ['Default', 'Advanced'],
    description: {
        [WizardTitleEnum.TEMPLATE]: `This is a default Layer 7 template, that runs you through setting up a frontend,
        backend and your chosen number of real servers.`
    },
    sections: {
        [WizardTitleEnum.FRONTENDS]: [
            {
                title: 'Frontend',
                subSteps: 3,
                // General
                name: {
                    value: '',
                    hidden: false,
                },
                ports: {
                    value: null,
                    hidden: false,
                },
                mode: {
                    value: 'tcp',
                    hidden: false
                },
                fipId: {
                    value: null,
                    hidden: false
                },
                fipEnabled: {
                    value: true,
                    hidden: false
                },
                SSLEnable: {
                    value: false,
                    hidden: false
                },
                SSLTLSv130rttEnable: {
                    value: false,
                    hidden: false
                }
            }
        ],
        [WizardTitleEnum.BACKENDS]: [
            {
                title: 'Backend',
                subSteps: 3,
                // General Options
                name: {
                    value: '',
                    hidden: false
                },
                mode: {
                    value: 'http',
                    hidden: false
                },
                balanceMode: {
                    value: 'leastconn',
                    hidden: false
                },
                //  Persistence options
                persistenceMode: {
                    value: 'sourceIP',
                    hidden: false
                },
                // Health check options
                healthCheck: {
                    value: 'connectToPort',
                    hidden: false
                },
                healthCheckPort: {
                    value: 80,
                    hidden: false
                },
                healthCheckRequestToSend: {
                    value: '/',
                    hidden: true
                },
                healthCheckResponseExpected: {
                    value: '',
                    hidden: true
                }
            }
        ],
        [WizardTitleEnum.REALSERVERS]: [
            {
                title: 'Real Server',
                subSteps: 0,
                backendId: {
                    value: '',
                    hidden: false
                },
                name: {
                    value: '',
                    hidden: false
                },
                ip: {
                    value: '',
                    hidden: false
                },
                count: {
                    value: 1,
                    hidden: false
                },
                port: {
                    value: 80,
                    hidden: false
                },
                weight: {
                    value: 100,
                    hidden: false
                },
                minconns: {
                    value: 0,
                    hidden: false
                },
                maxconns: {
                    value: 0,
                    hidden: false
                }
            }
        ],
        [WizardTitleEnum.ACLS]: [
            {
                title: 'Acls Step',
                subSteps: 1,
                conditionType: {
                    value: 'default',
                    hidden: true,
                },
                redirectLocation: {
                    value: '$1',
                    hidden: true
                },
                redirectType: {
                    value: 'backend',
                    hidden: true
                }
            }
        ]
    }
};
