<div class="component-main">
    <div class="content-space-between">
        <div class="content-left">
            <h1>{{ 'net_int_config' | myLbTranslate }}</h1>
        </div>
        <div class="content-right">
            <button
                class="primary"
                [disabled]="!permissionsLookup['network-interfaces-add-bond']"
                routerLink="add-bond">
                {{ 'net_int_config_create_bond' | myLbTranslate }}
            </button>
            <button
                class="primary"
                [disabled]="!permissionsLookup['network-interfaces-interface-add']"
                routerLink="add-vlan">
                {{ 'net_int_config_vlan_add' | myLbTranslate }}
            </button>
        </div>
    </div>
    <app-table
        id="interface-config"
        [data]="tableRows"
        [allowMultiSelect]="false"
        [showPaginator]="false"
        [stickyHeader]="true">
        <app-column [flexGrow]="1" prop="name" label="name" [cellTemplate]="nameTemplate">
            <ng-template let-row #nameTemplate>
                <ng-container *ngIf="(row.type === 'bond' && row.bondChildren?.length) else text">
                    <div class="bond-wrapper">
                        {{ row.name }}<br>
                        <ng-container *ngFor="let child of row.bondChildren">
                            <ng-container *ngIf="child.type !== 'vlan'">
                                <div class="group">
                                    {{ child.name }}
                                    <button
                                        class="button-as-link"
                                        [disabled]="!permissionsLookup['network-interfaces-delete']"
                                        (click)="confirmRemoveInterface(child.id)">
                                        {{ 'remove' | myLbTranslate }}
                                    </button>
                                </div>
                            </ng-container>
                        </ng-container>
                    </div>
                </ng-container>
                <ng-template #text>
                    {{ row.name }}
                </ng-template>
            </ng-template>
        </app-column>
        <app-column
            [flexGrow]="1" prop="interfaceName" label="net_int_config_interface_name" cellAlignment="top">
        </app-column>
        <app-column [flexGrow]="1" prop="type" label="type" cellAlignment="top" [cellTemplate]="interfaceType">
            <ng-template let-row #interfaceType>
                {{ row.type }} {{ row.type === 'vlan' ? row.vlanId : '' }}
            </ng-template>
        </app-column>
        <app-column [flexGrow]="1" prop="actions" label="" cellAlignment="right top"
                    [cellTemplate]="interfaceActions">
            <ng-template let-row #interfaceActions>
                <app-action-dots>
                    <button
                        *ngIf="row.type === 'bond'"
                        name="addInterface"
                        id="add-interface"
                        [disabled]="!permissionsLookup['network-interfaces-edit']"
                        (click)="addInterfaceToBond(row)">
                        {{ 'net_int_config_add_interface' | myLbTranslate }}
                    </button>
                    <button
                        *ngIf="row.type === 'ethernet' && bondInList"
                        name="addToBond"
                        id="add-to-bond"
                        [disabled]="!permissionsLookup['network-interfaces-edit']"
                        (click)="addInterfaceToBond(row)">
                        {{ 'net_int_config_add_to_bond' | myLbTranslate }}
                    </button>
                    <button
                        *ngIf="row.type === 'ethernet' || row.type === 'bond'"
                        name="edit"
                        id="edit-interface"
                        [disabled]="!permissionsLookup['network-interfaces-edit']"
                        [routerLink]="['edit-interface', row.id]">
                        {{ (row.type === 'bond' ? 'net_int_config_bond_edit' : 'net_int_config_interface_edit') | myLbTranslate }}
                    </button>
                    <button
                        *ngIf="row.type === 'vlan'"
                        name="edit"
                        id="edit-vlan"
                        [disabled]="!permissionsLookup['network-interfaces-edit']"
                        [routerLink]="['edit-vlan', row.id]">
                        {{ 'net_int_config_edit_vlan' | myLbTranslate }}
                    </button>
                    <button
                        *ngIf="row.type === 'vlan'"
                        name="delete"
                        id="delete-vlan"
                        [disabled]="!permissionsLookup['network-interfaces-delete']"
                        (click)="confirmDeleteVLan(row.id)">
                        {{ 'net_int_config_delete_vlan' | myLbTranslate }}
                    </button>
                    <button
                        *ngIf="row.type === 'bond'"
                        name="breakBond"
                        id="break-bond"
                        [disabled]="!permissionsLookup['network-interfaces-delete']"
                        (click)="breakBondHandler(row)">
                        {{ 'net_int_config_break_bond' | myLbTranslate }}
                    </button>
                </app-action-dots>
            </ng-template>
        </app-column>
    </app-table>
</div>

<app-modal-wrapper
    [modalTitle]="modalTitle"
    modalId="addInterface"
    footerButtonAlignment="content-center"
    [isOverlayClickable]="false">
    <app-modal-body>
        <ng-container *ngIf="interfaceDropdown?.length">
            <form [formGroup]="interfacesForm">
                <div class="form-group">
                    <!--suppress XmlInvalidId -->
                    <label for="addInterface">{{ dropdownLabel | myLbTranslate }}</label>
                    <div class="form-content">
                        <app-dropdown
                            dropDownId="addInterface"
                            [formGroup]="interfacesForm"
                            [formControlName]="formControlName"
                            [content]="interfaceDropdown">
                        </app-dropdown>
                    </div>
                </div>
            </form>
        </ng-container>
    </app-modal-body>
    <app-modal-footer>
        <app-loading-button
            buttonId="interface-add"
            buttonClass="primary"
            [isDisabled]="interfacesForm?.invalid || !permissionsLookup['network-interfaces-edit']"
            [callBack]="joinInterfaceToSelectedBond.bind(this)"
            buttonText="add">
        </app-loading-button>
    </app-modal-footer>
</app-modal-wrapper>
