<div class="component-main">
    <div class="content-space-between">
        <div class="content-left">
            <h1>{{ 'ha_conf' | myLbTranslate }}</h1>
        </div>
        <div class="content-right buttons-wrapper">
            <app-loading-button
                *ngIf="restartRequired"
                id="ha-configuration-reload-restart"
                buttonClass="primary"
                [buttonText]="'ha_conf_reload_restart_services'"
                [isDisabled]="!permissionsLookup['ha-configuration-reload-restart'] || actionInProgress"
                [callBack]="clusterRestartReload.bind(this)">
            </app-loading-button>
            <app-tooltip *ngIf="restartRequired" [triggerTemplate]="triggerTemplate">
                <span class="tooltip-text">{{ 'ha_conf_services_message' | myLbTranslate }}</span>
            </app-tooltip>
            <ng-template *ngIf="!restartRequired" [ngTemplateOutlet]="triggerTemplate"></ng-template>
        </div>
    </div>
    <app-table id="ha-config" [data]="tableRows" class="service-table" [searchTerm]="searchTerm">
        <app-column [flexGrow]="1" prop="hostname" label="hostname"></app-column>
        <app-column [flexGrow]="1" prop="ip" label="ha_conf_bind_address"></app-column>
        <app-column [flexGrow]="0.5" prop="latency" label="ha_conf_latency"></app-column>
        <app-column [flexGrow]="1" prop="services" label="services" [cellTemplate]="services">
            <ng-template let-row #services>
                <div class="status-container">
                    <span class='node-status {{overallServicesState(row)}}'></span>
                    <app-tooltip
                        trigger="Services"
                        class="services-tooltip"
                        [width]="200">
                        <div *ngFor="let item of row?.services | keyvalue" class="content-space-between services-list">
                            <ng-container *ngIf="currentIP === row.ip; else externalLink">
                                <span
                                    class="titleLink"
                                    [class.disabled]="!permissionsLookup['ha-configuration-services-link']"
                                    (click)="linkToServices(item)">
                                        {{ item.key | titlecase }}
                                </span>
                            </ng-container>
                            <ng-template #externalLink>
                                <span
                                    id="ha-configuration-restart-other"
                                    class="titleLink"
                                    [class.no-permission]="!permissionsLookup['ha-configuration-restart-other']"
                                    (click)="confirmRestartOnOtherAppliance(row, item)">
                                    {{ item.key | titlecase }}
                                </span>
                            </ng-template>
                            <span class="status-block {{item.value}}"></span>
                        </div>
                    </app-tooltip>
                </div>
            </ng-template>
        </app-column>
        <app-column
            *ngIf="clusterStatus?.nodes?.length > 1"
            [flexGrow]="1.5"
            prop="status"
            label="status"
            [cellTemplate]="nodeStatus">
            <ng-template let-row #nodeStatus>
                <div class="content-space-between">
                    <div class="status-container">
                        <span class='node-status {{row.status}}'></span>
                        <span class="node-status-label">{{ row.status | titlecase }}</span>
                    </div>
                    <ng-container *ngIf="showHeartRate(row.status); else warning">
                        <div
                            *ngIf="showHeartRate(row.status) !== 'passive'"
                            [class]="'heart-rate ' + showHeartRate(row.status)">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                x="0px"
                                y="0px"
                                viewBox="0 0 150 70">
                                <polyline
                                    class="animation"
                                    points="0,35 38.514,35 44.595,28 50.676,35 57.771,35 62.838,45 70,0 80.067,70 84.122,35 97.297,35 103.379,30 110.473,35 150,35"/>
                            </svg>
                        </div>
                    </ng-container>
                    <ng-template #warning *ngIf="showHeartRate(row.status) !== 'passive'">
                        <div class="warning"><i class="fa fa-exclamation-triangle" aria-hidden="true"></i></div>
                    </ng-template>
                </div>
            </ng-template>
        </app-column>
        <app-column [flexGrow]="1" prop="state" label="state">
            <ng-template let-row="row" appLbTableColumnCell>
                <div class="status-container">
                    <span class='node-status {{row.state | lowercase}}'></span>
                    <span>{{ setNodeState(row.status) }}</span>
                </div>
            </ng-template>
        </app-column>
        <app-column [flexGrow]="1.5" prop="lastReceived" label="ha_conf_last_health_check"></app-column>
        <app-column
            [flexGrow]="0.5"
            prop="actions"
            label=""
            [cellAlignment]="'right'"
            [cellTemplate]="nodeActions">
            <ng-template let-row #nodeActions>
                <app-action-dots>
                    <button
                        *ngIf="row.status === 'active'"
                        id="ha-configuration-resync"
                        [disabled]="!permissionsLookup['ha-configuration-resync']"
                        (click)="reSyncCluster()">
                        {{ 'ha_conf_re_sync_config' | myLbTranslate }}
                    </button>
                    <button
                        *ngIf="row.status === 'passive'"
                        id="ha-configuration-promote-node"
                        [disabled]="!permissionsLookup['ha-configuration-promote-node']"
                        (click)="promoteNode(row)">
                        {{ 'ha_conf_promote' | myLbTranslate }}
                    </button>
                    <button
                        *ngIf="row.status !== 'active' && applianceId !== row.id"
                        id="ha-configuration-remove-node"
                        [disabled]="!permissionsLookup['ha-configuration-remove-node']"
                        (click)="confirmRemoveNode(row)">
                        {{ 'remove' | myLbTranslate }}
                    </button>
                    <button
                        *ngIf="row.status === 'unavailable' || row.status === 'unknown'"
                        id="ha-configuration-restore-node"
                        [disabled]="!permissionsLookup['ha-configuration-restore-node']"
                        (click)="openAddNode(row)">
                        {{ 'restore' | myLbTranslate }}
                    </button>
                </app-action-dots>
            </ng-template>
        </app-column>
    </app-table>
</div>

<ng-template #triggerTemplate>
    <button
        id="ha-configuration-add-node"
        [disabled]="restartRequired || !permissionsLookup['ha-configuration-add-node']"
        class="primary"
        (click)="openAddNode()">
        {{ 'ha_conf_add_node' | myLbTranslate }}
    </button>
</ng-template>

