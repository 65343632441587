<div class="menu" [class.submenu-overlays]="secondMenuFloats">
    <div class="level-1 dark-dark" [class.open]="isOpen">
        <ng-container *ngIf="secondMenuFloats else toggleHeader">
            <div class="menu-header">
                <div class="menu-toggle">
                    <img
                        ngSrc="./assets/shared/logos/logo.svg"
                        alt="Open close menu toggle"
                        height="23"
                        width="26">
                </div>
            </div>
        </ng-container>
        <ng-template #toggleHeader>
            <div class="menu-header">
                <div class="menu-toggle" (click)="toggleMenu()">
                    <ng-container *ngIf="isOpen else closed">
                        <img
                            ngSrc="./assets/shared/icons/menu-close.svg"
                            alt="Open close menu toggle"
                            height="20"
                            width="20">
                    </ng-container>
                    <ng-template #closed>
                        <img
                            ngSrc="./assets/shared/icons/menu-open.svg"
                            alt="Open close menu toggle"
                            height="20"
                            width="20">

                    </ng-template>
                </div>
                <div *ngIf="menuConfig?.menuTitleImage" class="menu-image-wrapper">
                    <img
                        class="menu-title-image"
                        [ngSrc]="menuConfig?.menuTitleImage"
                        [alt]="menuConfig?.menuTitleImage"
                        height="25"
                        width="25"/>
                </div>
                <div class="menu-titles">
                    <h1 class="menu-title">{{ menuConfig?.menuTitle | myLbTranslate }}</h1>
                    <span *ngIf="menuConfig?.menuSubTitle" class="sub-title">
                        {{ menuConfig?.menuSubTitle | uppercase }}
                    </span>
                </div>
            </div>
        </ng-template>
        <div class="menu-items">
            <ng-container *ngFor="let primaryItem of menuConfig.menuItems">
                <div
                    [id]="primaryItem.id"
                    class="menu-item-wrapper {{primaryItem.class}}"
                    appHint
                    hintText="{{ primaryItem.heading | myLbTranslate }}"
                    hintPosition="right"
                    [class.active]="primaryItem.active"
                    [class.disabled]="primaryItem.disabled"
                    [class.coming-soon]="primaryItem.comingSoon"
                    [hintShow]="!isOpen && !secondMenuFloats"
                    (mouseenter)="mouseEnter(primaryItem)"
                    (click)="triggerNavigation(primaryItem)">
                    <div *ngIf="primaryItem.iconLocation" class="menu-image-wrapper">
                        <img
                            ngSrc="assets/{{primaryItem.iconLocation}}.svg"
                            alt="{{primaryItem.iconLocation}} menu icon"
                            height="20"
                            width="20">
                    </div>
                    <span>{{ primaryItem.heading | myLbTranslate }}</span>
                </div>
            </ng-container>
        </div>
        <div class="menu-footer">
            <ng-content select="[menu-footer]"></ng-content>
        </div>
    </div>
    <ng-container *ngFor="let level2MenuItem of level2MenuItems">
        <div [hidden]="!level2MenuItem.visible" class="level-2 dark-light">
            <div class="menu-header">
                <div *ngIf="level2MenuItem?.iconLocation" class="menu-image-wrapper">
                    <img
                        class="menu-title-image"
                        ngSrc="assets/{{level2MenuItem.iconLocation}}.svg"
                        [alt]="menuConfig?.menuTitleImage"
                        height="25"
                        width="25"/>
                </div>
                <div class="menu-titles">
                    <h1 class="menu-title">{{ level2MenuItem?.heading | myLbTranslate }}</h1>
                    <span *ngIf="menuConfig?.menuSubTitle" class="sub-title">
                        {{ level2MenuItem?.subHeading | uppercase }}
                    </span>
                </div>
            </div>
            <div class="menu-items">
                <div *ngFor="let secondaryItem of level2MenuItem.children">
                    <div
                        [id]="secondaryItem.id"
                        class="menu-item-wrapper {{secondaryItem.class}}"
                        appHint
                        hintText="{{ secondaryItem.heading | myLbTranslate }}"
                        hintPosition="right"
                        [class.active]="secondaryItem.active && !secondaryItem.children"
                        [class.disabled]="secondaryItem.disabled"
                        [class.coming-soon]="secondaryItem.comingSoon"
                        [ngClass]="toggleChevron(secondaryItem)"
                        [hintShow]="!isOpen"
                        (click)="triggerNavigation(secondaryItem)">
                        <div *ngIf="secondaryItem.iconLocation" class="menu-image-wrapper">
                            <img
                                ngSrc="assets/{{secondaryItem.iconLocation}}.svg"
                                alt="{{secondaryItem.iconLocation}} menu icon"
                                height="20"
                                width="20">
                        </div>
                        <span>{{ secondaryItem.heading | myLbTranslate }}</span>
                    </div>
                    <div [hidden]="!(secondaryItem.children && secondaryItem.active)" class="level-3">
                        <ng-container *ngFor="let tertiaryItem of secondaryItem.children">
                            <div
                                [id]="tertiaryItem.id"
                                class="menu-item-wrapper {{tertiaryItem.class}}"
                                appHint
                                hintText="{{ tertiaryItem.heading | myLbTranslate }}"
                                hintPosition="right"
                                [class.active]="tertiaryItem.active"
                                [class.disabled]="tertiaryItem.disabled"
                                [class.coming-soon]="tertiaryItem.comingSoon"
                                [hintShow]="!isOpen"
                                (click)="triggerNavigation(tertiaryItem)">
                                <div *ngIf="tertiaryItem.iconLocation" class="menu-image-wrapper">
                                    <img
                                        ngSrc="assets/{{tertiaryItem.iconLocation}}.svg"
                                        alt="{{tertiaryItem.iconLocation}} menu icon"
                                        height="20"
                                        width="20">
                                </div>
                                <span>{{ tertiaryItem.heading | myLbTranslate }}</span>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>
</div>
