<ng-container *ngIf="!hasTier else page">
    <app-alternative-state-page
        headerIcon="assets/centralisedManagement/icons/subscription_required.svg"
        header="security_insights_upgrade_title"
        messageString="security_insights_upgrade_message"
        imagePath="assets/centralisedManagement/empty-state/samlNoTier.svg"
        [redirectToButton]="true">
    </app-alternative-state-page>
</ng-container>
<ng-template #page>
    <div class="component-main">
        <div class="content-space-between">
            <div class="content-left">
                <h1 id="component-title">{{ 'security_insights' | myLbTranslate }}</h1>
            </div>
        </div>

        <app-table
            name="security-insights"
            [data]="tableRows"
            [allowMultiSelect]="false"
            [searchTerm]="searchTerm"
            [showPaginator]="false"
            (keyMatches)="matchedSearchKeys($event)">
            <app-column [flexGrow]="1" prop="label" label="adc_name" [customCellTemplate]="adcName">
                <ng-template let-row #adcName>
                    <img
                        class="company-logo"
                        src="assets/centralisedManagement/icons/{{adcUtilsService.getVendorIconName(row.vendor)}}-colour.svg"
                        alt="{{adcUtilsService.getVendorTitle(row.vendor)}} Logo"
                        title="{{adcUtilsService.getVendorTitle(row.vendor)}}"
                    />
                    <span class="routerLink" title="{{row.label}}"
                          [routerLink]="'/cm/security/insights/details/' + row.id">
                        {{ row.label }}
                    </span>
                </ng-template>
            </app-column>
            <app-column [flexGrow]="1" prop="securityRisk" label="security_risk" [customCellTemplate]="securityRisk">
                <ng-template let-row #securityRisk>
                    <div class="security-risk{{row.securityRisk?.low === 0 ? '-disabled' : '-low'}}">
                        {{ row.securityRisk?.low }}
                    </div>
                    <div class="security-risk{{row.securityRisk?.medium === 0 ? '-disabled' : '-medium'}}">
                        {{ row.securityRisk?.medium }}
                    </div>
                    <div class="security-risk{{row.securityRisk?.high === 0 ? '-disabled' : '-high'}}">
                        {{ row.securityRisk?.high }}
                    </div>
                    <div class="security-risk{{row.securityRisk?.critical === 0 ? '-disabled' : '-critical'}}">
                        {{ row.securityRisk?.critical }}
                    </div>
                </ng-template>
            </app-column>
            <app-column
                [flexGrow]="1"
                prop="operationalRisk"
                label="security_operational_risk"
                [customCellTemplate]="opRisk">
                <ng-template let-row #opRisk>
                    <div class="operational-risk-{{row.operationalRisk}}" title="{{row.operationalRisk}}">
                        {{ row.operationalRisk | titlecase }}
                    </div>
                </ng-template>
            </app-column>
            <app-column [flexGrow]="2" prop="recommendation" label="security_our_recommendation"></app-column>
            <app-column [flexGrow]="1" prop="actions" cellAlignment="right" [customCellTemplate]="actions">
                <ng-template let-row #actions>
                    <app-action-dots>
                        <button
                            id="security-more-details"
                            class="security-more-details"
                            [disabled]="!permissionsLookup['security-more-details'] || false"
                            [routerLink]="'/cm/security/insights/details/' + row.id">
                            {{ 'security_more_details' | myLbTranslate }}
                        </button>
                        <button
                            id="security-update-adc"
                            [disabled]="!permissionsLookup['security-update-adc'] || row?.vendor !== 5"
                            (click)="systemUpdateAdc(row.id)">
                            {{ 'security_update_adc' | myLbTranslate }}
                        </button>
                    </app-action-dots>
                </ng-template>
            </app-column>
        </app-table>
    </div>
</ng-template>
