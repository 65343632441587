<div class="component-main">
    <div class="content-space-between">
        <div class="content-left">
            <img ngSrc="assets/centralisedManagement/icons/globe.svg" alt="Globe icon" height="20" width="20">
            <h1 id="component-title">{{ 'namespaces' | myLbTranslate }}</h1>
        </div>
        <div class="content-right">
            <button
                id="namespace-list-add-namespace"
                [disabled]="!userCanEdit"
                (click)=addEditNamespace()
                class="primary">
                {{ 'namespace_add' | myLbTranslate }}
            </button>
        </div>
    </div>
    <app-table
        name="namespace-list"
        [data]="tableRows"
        [allowMultiSelect]="false"
        [showPaginator]="false">
        <app-column [flexGrow]="1" prop="name" label="name"></app-column>
        <app-column [flexGrow]="0.5" prop="actions" label="" cellAlignment="right" [customCellTemplate]="actions">
            <ng-template let-row #actions>
                <ng-container *ngIf="row.name !== 'All' && row.name !== 'default'">
                    <app-action-dots>
                        <button
                            id="namespace-list-edit"
                            [disabled]="!permissionsLookup['namespace-list-edit']"
                            (click)="addEditNamespace(row.id)">
                            {{ 'edit' | myLbTranslate }}
                        </button>
                        <button
                            id="namespace-list-delete"
                            [disabled]="!permissionsLookup['namespace-list-delete']"
                            (click)="confirmDeleteNamespace(row.id)">
                            {{ 'delete' | myLbTranslate }}
                        </button>
                    </app-action-dots>
                </ng-container>
            </ng-template>
        </app-column>
    </app-table>
</div>
