<h1>{{ 'services_monitor_configuration' | myLbTranslate }}</h1>
<form [formGroup]="serviceForm" [class.full-width]="isFormFullWidth">
    <div [formGroupName]="subStepEnum.GENERAL" [hidden]="getWizardSubStepState(subStepEnum.GENERAL)">
        <h2>{{ subStepEnum.GENERAL | myLbTranslate }}</h2>
        <section data-control-name="name">
            <div *ngIf="!isInputHidden('name')" class="form-group">
                <label for="frontendName{{templateIndex}}">{{ 'name' | myLbTranslate }}*</label>
                <div class="form-content">
                    <input
                        formControlName="name"
                        id="frontendName{{templateIndex}}"
                        name="name"
                        title="{{ 'name' | myLbTranslate }}"
                        type="text">
                    <app-validation-messages [control]="serviceForm.get(subStepEnum.GENERAL + '.name')">
                    </app-validation-messages>
                    <app-help class="help-button" tag="l7-frontend_name"></app-help>
                </div>
            </div>
        </section>
        <section data-control-name="ports">
            <div *ngIf="!isInputHidden('ports')" class="form-group">
                <label for="ports{{templateIndex}}">{{ 'port' | myLbTranslate }}*</label>
                <div class="form-content">
                    <input
                        formControlName="ports"
                        id="ports{{templateIndex}}"
                        name="ports"
                        size="5"
                        title="{{ 'ports' | myLbTranslate }}"
                        type="number">
                    <app-validation-messages [control]="serviceForm.get(subStepEnum.GENERAL + '.ports')">
                    </app-validation-messages>
                    <app-help class="help-button" tag="l7-frontend_ports"></app-help>
                </div>
            </div>
        </section>
        <br>
        <section data-control-name="rules">
            <label>{{ 'rules' | myLbTranslate | titlecase }}</label>
            <div class="form-group rules" formArrayName="rules" *ngFor="let rule of rules.controls; index as i">
                <div class="form-content">
                    <div class="form-row">
                        <div class="form-group" [formGroupName]="i.toString()">
                            <label for="monitor-backends-dropdown-{{i}}" *ngIf="i === 0">
                                {{ 'backend' | myLbTranslate }}
                            </label>
                            <div class="form-content">
                                <app-dropdown
                                    dropDownId="monitor-backends-dropdown-{{i}}"
                                    [dropdownWidth]="150"
                                    [dropdownContentWidth]="250"
                                    [formGroup]="serviceForm.get(subStepEnum.GENERAL + '.rules')['controls'][i]"
                                    formControlName="backend"
                                    [content]="dropdown.backends"
                                    [hiddenContentKeys]="backendDropdownHiddenContentKeys"
                                    (keyOut)="updateView($event, i)">
                                </app-dropdown>
                                <app-validation-messages
                                    [control]="serviceForm.get(subStepEnum.GENERAL + '.rules')
                                        ['controls'][i]['controls']['backend']">
                                </app-validation-messages>
                                <div *ngIf="warnings[i]" class="warnings">
                                    <div class="warning" [style.z-index]="40 - i">
                                        {{ warnings[i] }}
                                        <span class="close" (click)="clearWarning(i)">
                                                <img
                                                    ngSrc="../../../../../../../../assets/shared/icons/close-cross.svg"
                                                    alt="close notification icon"
                                                    height="20"
                                                    width="20">
                                            </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group" [formGroupName]="i.toString()">
                            <!--suppress XmlInvalidId -->
                            <label for="monitor-condition" *ngIf="i === 0">{{ 'has' | myLbTranslate }}</label>
                            <div class="form-content">
                                <app-dropdown
                                    dropDownId="monitor-condition"
                                    [formGroup]="serviceForm.get(subStepEnum.GENERAL + '.rules')['controls'][i]"
                                    formControlName="condition"
                                    [dropdownWidth]="150"
                                    [content]="dropdown.condition">
                                </app-dropdown>
                            </div>
                        </div>
                        <div class="form-group" [formGroupName]="i.toString()">
                            <label *ngIf="i === 0">{{ 'services_min_real_servers' | myLbTranslate }}</label>
                            <div class="form-content">
                                <input
                                    class="form-number"
                                    min="1"
                                    [max]="backRealsCount[i]"
                                    name="numberOfReals"
                                    type="number"
                                    [formControl]="rule['controls']['numberOfReals']"
                                    placeholder="{{backRealsCount[i] ? ('max' | myLbTranslate) + backRealsCount[i] : ''}}">
                                <app-validation-messages
                                    [control]="this.rules['controls'][i]['controls']['numberOfReals']">
                                </app-validation-messages>
                            </div>
                        </div>
                        <div class="form-group" [formGroupName]="i.toString()">
                            <label *ngIf="i === 0" class="empty-label"></label>
                            <div class="form-content">
                                <button
                                    class="button-as-link"
                                    [disabled]="deleteRuleDisabled()"
                                    (click)="deleteRule(i)">{{ 'delete' | myLbTranslate }}
                                </button>
                                <button
                                    *ngIf="i === rules.controls.length - 1"
                                    class="button-as-link"
                                    [disabled]="addRuleDisabled()"
                                    (click)="addRule(rule)">{{ 'add_another' | myLbTranslate }}
                                </button>
                            </div>
                        </div>
                    </div>
                    <app-help class="help-button" tag="l7-frontend_rules"></app-help>
                </div>
            </div>
        </section>
        <section data-control-name="monitorUri">
            <div *ngIf="!isInputHidden('monitorUri')" class="form-group">
                <label for="monitorUri{{templateIndex}}">{{ 'services_monitor_uri' | myLbTranslate }}*</label>
                <div class="form-content">
                    <input
                        formControlName="monitorUri" id="monitorUri{{templateIndex}}"
                        name="monitorUri"
                        title="{{ 'services_monitor_uri' | myLbTranslate}}"
                        type="text">
                    <app-validation-messages [control]="serviceForm.get(subStepEnum.GENERAL + '.monitorUri')">
                    </app-validation-messages>
                    <app-help class="help-button" tag="l7-frontend_monitor-uri"></app-help>
                </div>
            </div>
        </section>
    </div>
    <!-- Floating IPs -->
    <div [formGroupName]="subStepEnum.FLOATING_IPS" [hidden]="getWizardSubStepState(subStepEnum.FLOATING_IPS)">
        <div *ngIf="!isInputHidden('fipId')">
            <app-lb-floating-ip
                [fips]="fipInterfaces"
                [groups]="ipGroups"
                [componentEmbedded]="true"
                [selectedKey]="serviceForm.get(subStepEnum.FLOATING_IPS + '.fipId').value"
                [validationError]="fipValidation"
                (fipId)="setFipId($event)">
            </app-lb-floating-ip>
        </div>
    </div>
</form>
