<ng-container *ngIf="isLoading else pageContent">
    <div class="page-loader"></div>
</ng-container>
<ng-template #pageContent>
    <ng-container *ngIf="!tableRows.length else table">
        <ng-template #supportMessageTemplate>
            <p>{{ 'support_tickets_empty_message' | myLbTranslate }}</p>
        </ng-template>
        <app-alternative-state-page
            header="support"
            titleText="support_tickets_empty_title"
            [messageTemplate]="supportMessageTemplate"
            [mockTableHeaders]="['ticket', 'subject','product', 'status', 'created_by', 'date']"
            imagePath="assets/centralisedManagement/empty-state/noSupportTickets.svg"
            buttonOneText="support_create_ticket"
            buttonOneLink="/cm/support/create-ticket">
        </app-alternative-state-page>
    </ng-container>
    <ng-template #table>
        <div class="component-main">
            <div class="content-space-between">
                <h1 id="component-title">{{ 'tickets_all' | myLbTranslate }}</h1>
                <button
                    id="ticket-list-create-ticket"
                    class="primary"
                    [disabled]="!permissionsLookup['ticket-list-create-ticket']"
                    routerLink="/cm/support/create-ticket">
                    {{ 'support_create_ticket' | myLbTranslate }}
                </button>
            </div>
            <app-table
                id="support-ticket-list"
                [data]="tableRows"
                [showPaginator]="false">
                <app-column [flexGrow]="0.5" prop="id" label="ticket" [cellTemplate]="ticketId">
                    <ng-template let-row #ticketId>
                        <span
                            class="table-link"
                            [class.disabled]="!permissionsLookup['ticket-list-update-ticket']"
                            (click)="updateTicket(row.id)">
                            #{{ row.id }}
                        </span>
                    </ng-template>
                </app-column>
                <app-column [flexGrow]="1" prop="subject" label="subject" [cellTemplate]="subject">
                    <ng-template let-row #subject>
                        <span
                            class="table-link"
                            [class.disabled]="!permissionsLookup['ticket-list-update-ticket']"
                            (click)="updateTicket(row.id)">
                            {{ row.subject }}
                        </span>
                    </ng-template>
                </app-column>
                <app-column [flexGrow]="0.5" prop="product" label="product" [cellTemplate]="product">
                    <ng-template let-row #product>
                        <span>{{ row.product | titlecase }}</span>
                    </ng-template>
                </app-column>
                <app-column [flexGrow]="0.5" prop="status" label="status" [cellTemplate]="status">
                    <ng-template let-row #status>
                        <span>{{ row.status | titlecase }}</span>
                    </ng-template>
                </app-column>
                <app-column [flexGrow]="0.5" prop="requester" label="created_by"></app-column>
                <app-column [flexGrow]="1" prop="date" label="date"></app-column>
                <app-column
                    [flexGrow]="0.5"
                    prop="actions"
                    label=""
                    cellAlignment="right"
                    [cellTemplate]="actions">
                    <ng-template let-row #actions>
                        <app-action-dots>
                            <button
                                id="ticket-list-update-ticket"
                                [disabled]="!permissionsLookup['ticket-list-update-ticket'] || row.status ==='closed'"
                                (click)="updateTicket(row.id)">
                                {{ (row.status === 'New Reply' && row.state !== 'Open' ? 'reply' : 'ticket_update') | myLbTranslate }}
                            </button>
                            <button
                                *ngIf="!environment.production"
                                id="ticket-list-delete-ticket"
                                [disabled]="!permissionsLookup['ticket-list-delete-ticket']"
                                (click)="deleteTicket(row.id)">
                                {{ 'support_delete_ticket' | myLbTranslate }}
                            </button>
                        </app-action-dots>
                    </ng-template>
                </app-column>
            </app-table>
        </div>
    </ng-template>
</ng-template>
