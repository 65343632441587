<div class="content-space-between">
    <h2>{{ 'ssl_offload_manage_certificate' | myLbTranslate }}</h2>
</div>
<section [hidden]="addEditMode">
    <form [formGroup]="pageForm">
        <section id="cert-info">
            <div class="content-space-between">
                <h3>{{ 'certificate' | myLbTranslate }}: {{ certData.name }}</h3>
                <button class="button primary medium" (click)="deleteAnSSLTermination(certData)">
                    {{ 'ssl_offload_delete_certificate' | myLbTranslate }}
                </button>
            </div>
            <div class="form-group">
                <!--suppress XmlInvalidId -->
                <label for="certCipher">{{ 'ssl_offload_cipher_list' | myLbTranslate }}:</label>
                <div class="form-content">
                    <app-dropdown
                        dropDownId="certCipher"
                        [formGroup]="pageForm"
                        formControlName="cipherListId"
                        [dropdownWidth]="150"
                        [content]="dropdown.ciphers">
                    </app-dropdown>
                </div>
            </div>
        </section>
    </form>

    <section>
        <div class="content-space-between">
            <h3>{{ 'ssl_offload_hostnames_allowed' | myLbTranslate }}</h3>
            <button class="primary" (click)="editHostName(certData.editId, 'allow')">
                {{ 'ssl_offload_hostnames_add_allowed' | myLbTranslate }}
            </button>
        </div>
        <app-table
            id="allow-list"
            [data]="allowTableRows">
            <app-column [flexGrow]="1" prop="hostNames" label="ssl_offload_hostnames_allowed"></app-column>
            <app-column
                [flexGrow]="0.35"
                prop="actions"
                label=""
                cellAlignment="right"
                [cellTemplate]="allowListActions">
                <ng-template let-row #allowListActions>
                    <div>
                        <app-action-dots>
                            <button (click)="editHostName(certData.editId, 'allow')">
                                {{ 'edit' | myLbTranslate }}
                            </button>
                            <button (click)="deleteHostname(row, 'certMatchHostnames')">
                                {{ 'delete' | myLbTranslate }}
                            </button>
                        </app-action-dots>
                    </div>
                </ng-template>
            </app-column>
        </app-table>
    </section>
    <section class="denied-list">
        <div class="content-space-between">
            <h3>{{ 'ssl_offload_hostnames_denied' | myLbTranslate }}</h3>
            <button class="primary" (click)="editHostName(certData.editId, 'deny')">
                {{ 'ssl_offload_hostnames_add_denied' | myLbTranslate }}
            </button>
        </div>
        <app-table
            id="hostnames-list"
            [data]="denyTableRows">
            <app-column [flexGrow]="1" prop="hostNames" label="ssl_offload_hostnames_denied">
            </app-column>
            <app-column
                [flexGrow]="0.35"
                prop="actions" label=""
                cellAlignment="right"
                [cellTemplate]="hostnameListActions">
                <ng-template let-row #hostnameListActions>
                    <div>
                        <app-action-dots>
                            <button (click)="editHostName(certData.editId, 'deny')">
                                {{ 'edit' | myLbTranslate }}
                            </button>
                            <button (click)="deleteHostname(row, 'certDenyHostnames')">
                                {{ 'delete' | myLbTranslate }}
                            </button>
                        </app-action-dots>
                    </div>
                </ng-template>
            </app-column>
        </app-table>
    </section>
</section>

<section *ngIf="addEditMode">
    <h2>
        {{ (hostnameType === 'allow' ? 'ssl_offload_allowed_hostnames' : 'ssl_offload_denied_hostnames') | myLbTranslate }}
    </h2>
    <form [formGroup]="editHostNamesForm">
        <div class="form-group" *ngFor="let control of hostnameFields.controls; index as i">
            <label *ngIf="i === 0">{{ 'hostname' | myLbTranslate }}</label>
            <div class="form-content">
                <input
                    id="hostname-{{i}}"
                    [formControl]="editHostNamesForm.get('hostnames')['controls'][i]"
                    name="hostnames"
                    class="hostname"
                    placeholder="{{ 'ssl_offload_hostnames_placeholder' | myLbTranslate }}"
                    title="hostname"
                    type="text">
                <app-validation-messages
                    [control]="editHostNamesForm.get('hostnames')['controls'][i]">
                </app-validation-messages>
                <button [disabled]="hostnameFields.length === 1" class="button-as-link" (click)="removeHostname(i)">
                    {{ 'delete' | myLbTranslate }}
                </button>
                <button [disabled]="!hostnameFields.valid" class="button-as-link" (click)="addHostname()">
                    {{ 'add_another' | myLbTranslate }}
                </button>
            </div>
        </div>
    </form>
</section>
<div class="component-footer embedded">
    <button class="outline" id="cancel" (click)="addEditMode ? cancelAddEdit() : goBack()">
        {{ 'cancel' | myLbTranslate }}
    </button>
    <ng-container *ngIf="addEditMode else defaultView">
        <button
            [disabled]="hostnameFields.pristine || hostnameFields.invalid"
            class="primary"
            id="add-hostname" (click)="submitHostNames()">
            {{ 'submit' | myLbTranslate }}
        </button>
    </ng-container>
    <ng-template #defaultView>
        <button
            class="primary"
            id="update-cert"
            [disabled]="pageForm.pristine || !pageForm.valid"
            (click)="updateCert()">
            {{ 'update' | myLbTranslate }}
        </button>
    </ng-template>
</div>
