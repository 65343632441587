<div [class.component-main]="!componentEmbedded">
    <div class="content" [class.embedded]="componentEmbedded">
        <ng-container *ngIf="!componentEmbedded; else embeddedTitle">
            <h1>{{ pageTitle | myLbTranslate }}</h1>
        </ng-container>
        <ng-template #embeddedTitle>
            <h2>{{ pageTitle | myLbTranslate }}</h2>
        </ng-template>
    </div>
    <form [formGroup]="createCSRForm">
        <div class="form-group">
            <label for="create-csr-name">{{ 'name' | myLbTranslate }}*</label>
            <div class="form-content">
                <input
                    id="create-csr-name"
                    title="{{'name' | myLbTranslate }}"
                    type="text"
                    size="40"
                    name="name"
                    formControlName="name"/>
                <app-validation-messages [control]="createCSRForm.get('name')"></app-validation-messages>
                <app-help class="help-button" tag="ssl-certificate-add-edit-csr_label"></app-help>
            </div>
        </div>
        <div class="form-group">
            <label for="create-csr-domain">{{ 'certificate_domain_cn' | myLbTranslate }}*</label>
            <div class="form-content">
                <input
                    id="create-csr-domain"
                    title="{{'domain' | myLbTranslate }}"
                    type="text"
                    size="40"
                    name="domain"
                    formControlName="commonName"/>
                <app-validation-messages [control]="createCSRForm.get('commonName')"></app-validation-messages>
                <app-help class="help-button" tag="ssl-certificate-add-edit-csr_domain"></app-help>
            </div>
        </div>
        <div class="form-group">
            <label for="create-csr-organisation">{{ 'certificate_organization' | myLbTranslate }}*</label>
            <div class="form-content">
                <input
                    id="create-csr-organisation"
                    title="{{'certificate_organization' | myLbTranslate }}"
                    type="text"
                    size="40"
                    name="organisation"
                    formControlName="organisation"/>
                <app-validation-messages [control]="createCSRForm.get('organisation')"></app-validation-messages>
                <app-help class="help-button" tag="ssl-certificate-add-edit-csr_organisation"></app-help>
            </div>
        </div>
        <div class="form-group">
            <label for="create-csr-organisationalUnit">{{ 'certificate_organizational_unit' | myLbTranslate }}*</label>
            <div class="form-content">
                <input
                    id="create-csr-organisationalUnit"
                    title="{{'certificate_organizational_unit' | myLbTranslate }}"
                    type="text"
                    size="40"
                    name="organisationalUnit"
                    formControlName="organisationalUnit"/>
                <app-validation-messages [control]="createCSRForm.get('organisationalUnit')"></app-validation-messages>
                <app-help class="help-button" tag="ssl-certificate-add-edit-csr_organisational-unit"></app-help>
            </div>
        </div>
        <div class="form-group">
            <label for="create-csr-locality">{{ 'certificate_locality' | myLbTranslate }}*</label>
            <div class="form-content">
                <input
                    id="create-csr-locality"
                    title="{{'certificate_locality' | myLbTranslate }}"
                    type="text"
                    size="40"
                    name="locality"
                    formControlName="locality"/>
                <app-validation-messages [control]="createCSRForm.get('locality')"></app-validation-messages>
                <app-help class="help-button" tag="ssl-certificate-add-edit-csr_locality"></app-help>
            </div>
        </div>
        <div class="form-group">
            <label for="create-csr-state">{{ 'certificate_state_st' | myLbTranslate }}*</label>
            <div class="form-content">
                <input
                    id="create-csr-state"
                    title="{{'certificate_state_st' | myLbTranslate }}"
                    type="text"
                    size="40"
                    name="state"
                    formControlName="state"/>
                <app-validation-messages [control]="createCSRForm.get('state')"></app-validation-messages>
                <app-help class="help-button" tag="ssl-certificate-add-edit-csr_state"></app-help>
            </div>
        </div>
        <div class="form-group">
            <label>{{ 'certificate_country' | myLbTranslate }}*</label>
            <div class="form-content">
                <app-country-dropdown
                    [formGroup]="createCSRForm"
                    [dropdownWidth]="240"
                    formControlName="country"></app-country-dropdown>
                <app-help class="help-button" tag="ssl-certificate-add-edit-csr_country-code"></app-help>
            </div>
        </div>
        <div class="form-group content-top">
            <label>{{ 'certificate_subject_alt' | myLbTranslate }}*</label>
            <app-subject-alt
                [formGroup]="createCSRForm"
                formControlName="subjectAlt"
                helpTag="ssl-certificate-add-edit-csr_subject-alt">
            </app-subject-alt>
        </div>
        <div class="form-group">
            <label for="create-csr-email">{{ 'email' | myLbTranslate }}*</label>
            <div class="form-content">
                <input
                    id="create-csr-email"
                    title="{{'email' | myLbTranslate }}"
                    type="text"
                    size="40"
                    name="email"
                    formControlName="email"/>
                <app-validation-messages [control]="createCSRForm.get('email')">
                    {{ 'certificate_invalid_email' | myLbTranslate }}
                </app-validation-messages>
                <app-help class="help-button" tag="ssl-certificate-add-edit-csr_email"></app-help>
            </div>
        </div>
        <app-lb-checkbox
            [formGroup]="createCSRForm"
            formControlName="selfSigned"
            label="{{ 'certificate_self_signed' | myLbTranslate }}">
        </app-lb-checkbox>
        <div class="form-group">
            <!--suppress XmlInvalidId -->
            <label for="key-length">{{ 'certificate_key_length' | myLbTranslate }}*</label>
            <div class="form-content">
                <app-dropdown
                    dropDownId="key-length"
                    formControlName="keyLength"
                    [formGroup]="createCSRForm"
                    [content]="CSRKeyLengthArray">
                </app-dropdown>
                <app-help class="help-button" tag="ssl-certificate-add-edit-csr_key-length"></app-help>
            </div>
        </div>
        <div class="form-group">
            <!--suppress XmlInvalidId -->
            <label for="signature-algorithm">{{ 'certificate_signature_algorithm' | myLbTranslate }}*</label>
            <div class="form-content">
                <app-dropdown
                    dropDownId="signature-algorithm"
                    formControlName="signatureAlgorithm"
                    [formGroup]="createCSRForm"
                    [content]="signatureAlgorithms">
                </app-dropdown>
                <app-help class="help-button" tag="ssl-certificate-add-edit-csr_signature-algorithm"></app-help>
            </div>
        </div>
        <div class="form-group" [hidden]="createCSRForm.get('days').disabled">
            <label for="csr-days">{{ 'days_upper' | myLbTranslate }}*</label>
            <div class="form-content">
                <input
                    id="csr-days"
                    title="{{'days_upper' | myLbTranslate }}"
                    type="number"
                    name="days"
                    formControlName="days"/>
                <app-help class="help-button" tag="ssl-certificate-add-edit-csr_days"></app-help>
            </div>
        </div>
    </form>
</div>
<div class="component-footer" [class.embedded]="componentEmbedded">
    <button
        id="certs-cancel"
        class="outline"
        (click)="returnToParent()">
        {{ 'cancel' | myLbTranslate }}
    </button>
    <app-loading-button
        buttonClass="primary"
        buttonText="submit"
        [isDisabled]="createCSRForm.pristine || createCSRForm.invalid"
        [callBack]="submitCSR.bind(this)">
    </app-loading-button>
</div>
