<h1>{{ 'services_configuration' | myLbTranslate }}</h1>
<form [formGroup]="serviceForm" [class.full-width]="isFormFullWidth">
    <!-- General Options -->
    <div [formGroupName]="subStepEnum.GENERAL" [hidden]="getWizardSubStepState(subStepEnum.GENERAL)">
        <h2>{{ subStepEnum.GENERAL | myLbTranslate }}</h2>
        <section data-control-name="name">
            <div *ngIf="!isInputHidden('name')" class="form-group">
                <label for="backendLabel{{templateIndex}}">{{ 'name' | myLbTranslate }}</label>
                <div class="form-content">
                    <ng-container *ngIf="namePrefix">
                        <span class="name">{{ namePrefix }}</span>
                    </ng-container>
                    <input
                        id="backendLabel{{templateIndex}}"
                        name="name"
                        title="{{ 'name' | myLbTranslate }}"
                        formControlName="name"
                        type="text">
                    <app-validation-messages [control]="serviceForm.get(subStepEnum.GENERAL + '.name')">
                    </app-validation-messages>
                    <app-help class="help-button" tag="l7-backend_name"></app-help>
                </div>
            </div>
        </section>
        <section data-control-name="mode">
            <div *ngIf="!isInputHidden('mode')" class="form-group">
                <label for="mode{{templateIndex}}">{{ 'services_layer_7_mode' | myLbTranslate }}</label>
                <div class="form-content">
                    <app-dropdown
                        dropDownId="mode{{templateIndex}}"
                        [formGroup]="getFormGroup(subStepEnum.GENERAL)"
                        formControlName="mode"
                        [content]="dropdown.modes">
                    </app-dropdown>
                    <app-help class="help-button" tag="l7-backend_mode"></app-help>
                </div>
                <span *ngIf="serviceForm.get(subStepEnum.GENERAL + '.mode').disabled" class="note">
                    {{ 'services_backend_mode_disabled_message' | myLbTranslate }}
                </span>
            </div>
        </section>
        <section data-control-name="balanceMode">
            <div *ngIf="!isInputHidden('balanceMode')">
                <h2>{{ 'services_conn_distribution_method' | myLbTranslate }}</h2>
                <div class="form-group">
                    <label for="balanceMode{{templateIndex}}">{{ 'services_balance_mode' | myLbTranslate }}</label>
                    <div class="form-content">
                        <app-dropdown
                            dropDownId="balanceMode{{templateIndex}}"
                            [formGroup]="getFormGroup(subStepEnum.GENERAL)"
                            formControlName="balanceMode"
                            [content]="dropdown.balanceModes">
                        </app-dropdown>
                        <app-help class="help-button" tag="l7-backend_balance-mode"></app-help>
                    </div>
                </div>
            </div>
        </section>
        <section data-control-name="encryptToBackend">
            <div *ngIf="!isInputHidden('encryptToBackend')">
                <h2>{{ 'ssl' | myLbTranslate }}</h2>
                <div class="form-group">
                    <div class="form-content">
                        <input
                            id="backendEncryptToBackend{{templateIndex}}"
                            type="checkbox"
                            name="encryptToBackend"
                            formControlName="encryptToBackend"
                            class="lb-checkbox">
                        <label for="backendEncryptToBackend{{templateIndex}}" class="lb-checkbox">
                            {{ 'services_encrypt_to_real_server' | myLbTranslate }}
                        </label>
                        <app-help class="help-button" tag="l7-backend_encrypt-to-real-server"></app-help>
                    </div>
                </div>
            </div>
        </section>
        <section data-control-name="enableGzipCompression">
            <div *ngIf="!isInputHidden('enableGzipCompression')">
                <h2>{{ 'services_compression' | myLbTranslate }}</h2>
                <div class="form-group">
                    <div class="form-content">
                        <input
                            id="enableGzipCompression{{templateIndex}}"
                            type="checkbox"
                            name="enableGzipCompression"
                            formControlName="enableGzipCompression"
                            class="lb-checkbox"/>
                        <label for="enableGzipCompression{{templateIndex}}" class="lb-checkbox">
                            {{ 'services_enable_gzip_compression' | myLbTranslate }}
                        </label>
                        <app-help class="help-button" tag="l7-backend_compression"></app-help>
                    </div>
                </div>
            </div>
        </section>
    </div>

    <!-- Header options start -->
    <div [formGroupName]="subStepEnum.HEADERS" [hidden]="getWizardSubStepState(subStepEnum.HEADERS)">
        <section data-control-name="headers">
            <div *ngIf="!isInputHidden('headers')">
                <app-lb-l7headers
                    [serviceId]="template?.id?.value"
                    [templateIndex]="templateIndex"
                    [templateName]="componentTitle"
                    (headerIdsOut)="headerIds($event)">
                </app-lb-l7headers>
            </div>
        </section>
    </div>

    <!-- Persistence options -->
    <div [formGroupName]="subStepEnum.PERSISTENCE" [hidden]="getWizardSubStepState(subStepEnum.PERSISTENCE)">
        <h2>{{ subStepEnum.PERSISTENCE | myLbTranslate }}</h2>
        <section data-control-name="persistenceMode">
            <div *ngIf="!isInputHidden('persistenceMode')" class="form-group">
                <label for="persistenceMode{{templateIndex}}">{{ 'mode' | myLbTranslate }}</label>
                <div class="form-content">
                    <app-dropdown
                        dropDownId="persistenceMode{{templateIndex}}"
                        [formGroup]="getFormGroup(subStepEnum.PERSISTENCE)"
                        formControlName="persistenceMode"
                        [dropdownWidth]="230"
                        [search]="true"
                        [content]="dropdown.persistenceModes">
                    </app-dropdown>
                    <app-help class="help-button" tag="l7-backend_persistence-mode"></app-help>
                </div>
            </div>
        </section>
        <section data-control-name="httpCookieName">
            <div *ngIf="!isInputHidden('httpCookieName')" class="form-group">
                <label for="httpCookieName{{templateIndex}}">
                    {{ 'services_http_cookie_name' | myLbTranslate }}
                </label>
                <div class="form-content">
                    <input
                        id="httpCookieName{{templateIndex}}"
                        type="text"
                        name="httpCookieName"
                        formControlName="httpCookieName"/>
                    <app-validation-messages
                        [control]="serviceForm.get(subStepEnum.PERSISTENCE + '.httpCookieName')">
                    </app-validation-messages>
                    <app-help class="help-button" tag="l7-backend_http-cookie-name"></app-help>
                </div>
            </div>
        </section>
        <section data-control-name="applicationCookieName">
            <div *ngIf="!isInputHidden('applicationCookieName')" class="form-group">
                <label for="applicationCookieName{{templateIndex}}">
                    {{ 'services_application_cookie_name' | myLbTranslate }}
                </label>
                <div class="form-content">
                    <input
                        id="applicationCookieName{{templateIndex}}"
                        type="text"
                        name="applicationCookieName"
                        formControlName="applicationCookieName"/>
                    <app-validation-messages
                        [control]="serviceForm.get(subStepEnum.PERSISTENCE + '.applicationCookieName')">
                    </app-validation-messages>
                    <app-help class="help-button" tag="l7-backend_application-cookie-name"></app-help>
                </div>
            </div>
        </section>
        <section data-control-name="XFFIPPosition">
            <div *ngIf="!isInputHidden('XFFIPPosition')" class="form-group">
                <label for="XFFIPPosition{{templateIndex}}">
                    {{ 'services_xff_ip_position' | myLbTranslate }}
                </label>
                <div class="form-content">
                    <input
                        id="XFFIPPosition{{templateIndex}}"
                        type="number"
                        name="XFFIPPosition"
                        formControlName="XFFIPPosition"/>
                    <app-validation-messages
                        [control]="serviceForm.get(subStepEnum.PERSISTENCE + '.XFFIPPosition')">
                    </app-validation-messages>
                    <app-help class="help-button" tag="l7-backend_xff-ip-position"></app-help>
                </div>
            </div>
        </section>
        <section data-control-name="persistenceTimeout">
            <div *ngIf="!isInputHidden('persistenceTimeout')">
                <div class="form-group">
                    <label for="persistenceTimeout{{templateIndex}}">
                        {{ 'services_timeout' | myLbTranslate }}
                    </label>
                    <div class="form-content">
                        <app-time-selector
                            id="persistenceTimeout{{templateIndex}}"
                            [formGroup]="getFormGroup(subStepEnum.PERSISTENCE)"
                            formControlName="persistenceTimeout">
                        </app-time-selector>
                        <app-help class="help-button" tag="l7-backend_timeout"></app-help>
                    </div>
                </div>
            </div>
        </section>
        <section data-control-name="httpCookieMaxIdle">
            <div *ngIf="!isInputHidden('httpCookieMaxIdle')" class="form-group">
                <label for="httpCookieMaxIdle{{templateIndex}}">
                    {{ 'services_http_cookie_max_idle' | myLbTranslate }}
                </label>
                <div class="form-content">
                    <input
                        id="httpCookieMaxIdle{{templateIndex}}"
                        type="text"
                        name="httpCookieMaxIdle"
                        formControlName="httpCookieMaxIdle"/>
                    <app-validation-messages
                        [control]="serviceForm.get(subStepEnum.PERSISTENCE + '.httpCookieMaxIdle')">
                    </app-validation-messages>
                    <app-help class="help-button" tag="l7-backend_http-cookie-max-idle"></app-help>
                </div>
            </div>
        </section>
        <section data-control-name="httpCookieMaxLife">
            <div *ngIf="!isInputHidden('httpCookieMaxLife')" class="form-group">
                <label for="httpCookieMaxLife{{templateIndex}}">
                    {{ 'services_http_cookie_max_life' | myLbTranslate }}
                </label>
                <div class="form-content">
                    <input
                        id="httpCookieMaxLife{{templateIndex}}"
                        type="text"
                        name="httpCookieMaxLife"
                        formControlName="httpCookieMaxLife"/>
                    <app-validation-messages
                        [control]="serviceForm.get(subStepEnum.PERSISTENCE + '.httpCookieMaxLife')">
                    </app-validation-messages>
                    <app-help class="help-button" tag="l7-backend_http-cookie-max-life"></app-help>
                </div>
            </div>
        </section>
        <section data-control-name="persistenceTableSize">
            <div *ngIf="!isInputHidden('persistenceTableSize')" class="form-group">
                <label for="persistenceTableSize{{templateIndex}}">
                    {{ 'services_table_size' | myLbTranslate }}
                </label>
                <div class="form-content">
                    <input
                        id="persistenceTableSize{{templateIndex}}"
                        type="text"
                        name="persistenceTableSize"
                        formControlName="persistenceTableSize"/><span>{{ 'kb' | myLbTranslate }}</span>
                    <app-validation-messages
                        [control]="serviceForm.get(subStepEnum.PERSISTENCE + '.persistenceTableSize')">
                    </app-validation-messages>
                    <app-help class="help-button" tag="l7-backend_table-size"></app-help>
                </div>
            </div>
        </section>
        <section data-control-name="persistenceClearStickOnDrain">
            <div *ngIf="!isInputHidden('persistenceClearStickOnDrain')" class="form-group">
                <div class="form-content">
                    <input
                        id="persistenceClearStickOnDrain{{templateIndex}}"
                        type="checkbox"
                        name="persistenceClearStickOnDrain"
                        formControlName="persistenceClearStickOnDrain"
                        class="lb-checkbox"/>
                    <label for="persistenceClearStickOnDrain{{templateIndex}}" class="lb-checkbox">
                        {{ 'services_clear_stick_table_on_drain' | myLbTranslate }}
                    </label>
                    <app-help class="help-button" tag="l7-backend_clear-stick-table-on-drain"></app-help>
                </div>
            </div>
        </section>
    </div>
    <!-- Health check options -->
    <div [formGroupName]="subStepEnum.HEALTH" [hidden]="getWizardSubStepState(subStepEnum.HEALTH)">
        <h2>{{ subStepEnum.HEALTH | myLbTranslate }}</h2>
        <section data-control-name="healthCheck">
            <div *ngIf="!isInputHidden('healthCheck')" class="form-group">
                <label for="healthCheck{{templateIndex}}">{{ 'services_health_check_type' | myLbTranslate }}</label>
                <div class="form-content">
                    <app-dropdown
                        dropDownId="healthCheck{{templateIndex}}"
                        [formGroup]="getFormGroup(subStepEnum.HEALTH)"
                        formControlName="healthCheck"
                        [dropdownWidth]="220"
                        [search]="true"
                        [content]="dropdown.healthCheckTypes">
                    </app-dropdown>
                    <app-help class="help-button" tag="l7-backend_health-check-type"></app-help>
                </div>
            </div>
        </section>
        <section data-control-name="healthCheckPort">
            <div *ngIf="!isInputHidden('healthCheckPort')" class="form-group">
                <label for="healthCheckPort{{templateIndex}}">
                    {{ 'services_check_port' | myLbTranslate }}
                </label>
                <div class="form-content">
                    <input
                        id="healthCheckPort{{templateIndex}}"
                        type="number"
                        name="healthCheckPort"
                        formControlName="healthCheckPort"
                        appNumbersOnly/>
                    <app-validation-messages [control]="serviceForm.get(subStepEnum.HEALTH + '.healthCheckPort')">
                    </app-validation-messages>
                    <app-help class="help-button" tag="l7-backend_check-port"></app-help>
                </div>
            </div>
        </section>
        <section data-control-name="healthCheckRequestToSend">
            <div *ngIf="!isInputHidden('healthCheckRequestToSend')" class="form-group">
                <label for="healthCheckRequestToSend{{templateIndex}}">
                    {{ 'services_request_to_send' | myLbTranslate }}
                </label>
                <div class="form-content">
                    <input
                        id="healthCheckRequestToSend{{templateIndex}}"
                        type="text"
                        name="healthCheckRequestToSend"
                        formControlName="healthCheckRequestToSend"/>
                    <app-help class="help-button" tag="l7-backend_request-to-send"></app-help>
                </div>
            </div>
        </section>
        <section data-control-name="healthCheckResponseExpected">
            <div *ngIf="!isInputHidden('healthCheckResponseExpected')" class="form-group">
                <label for="healthCheckResponseExpected{{templateIndex}}">
                    {{ 'services_response_expected' | myLbTranslate }}
                </label>
                <div class="form-content">
                    <input
                        id="healthCheckResponseExpected{{templateIndex}}"
                        type="text"
                        name="healthCheckResponseExpected"
                        formControlName="healthCheckResponseExpected"/>
                    <app-help class="help-button" tag="l7-backend_response-expected"></app-help>
                </div>
            </div>
        </section>
        <section data-control-name="authentication">
            <div *ngIf="!isInputHidden('authentication')">
                <app-lb-checkbox
                    [showHiddenContent]="!isInputHidden('authentication', {force: true})"
                    [formGroup]="getFormGroup(subStepEnum.HEALTH)"
                    formControlName="authentication"
                    label="{{ 'authentication' | myLbTranslate }}">
                    <div class="form-group">
                        <label for="healthCheckUsername{{templateIndex}}">
                            {{ 'username' | myLbTranslate }}
                        </label>
                        <div class="form-content">
                            <input
                                id="healthCheckUsername{{templateIndex}}"
                                type="text"
                                formControlName="healthCheckUsername"
                                name="healthCheckUsername"
                                autocomplete="off"/>
                            <app-help class="help-button" tag="l7-backend_username"></app-help>
                        </div>
                    </div>
                    <div class="form-group">
                        <label
                            for="healthCheckPassword{{templateIndex}}">{{ 'password' | myLbTranslate }}</label>
                        <div class="form-content">
                            <app-lb-reveal-password
                                id="healthCheckPassword{{templateIndex}}"
                                title="{{'password' | myLbTranslate }}"
                                formControlName="healthCheckPassword"
                                [formGroup]="getFormGroup(subStepEnum.HEALTH)">
                            </app-lb-reveal-password>
                        </div>
                    </div>
                </app-lb-checkbox>
            </div>
        </section>
        <section data-control-name="healthCheckScript">
            <div *ngIf="!isInputHidden('healthCheckScriptID')" class="form-group">
                <label>{{ 'services_external_check_script' | myLbTranslate }}</label>
                <div class="form-content">
                    <app-dropdown
                        dropDownId="healthCheckScriptID{{templateIndex}}-dropdown"
                        [formGroup]="getFormGroup(subStepEnum.HEALTH)"
                        formControlName="healthCheckScriptID"
                        [dropdownWidth]="220"
                        [content]="dropdown.checkScripts">
                    </app-dropdown>
                    <app-help class="help-button" tag="l7-backend_external-check-script"></app-help>
                </div>
            </div>
        </section>
        <section data-control-name="healthCheckHostHeader">
            <div *ngIf="!isInputHidden('healthCheckHostHeader')" class="form-group">
                <label for="healthCheckHostHeader{{templateIndex}}">
                    {{ 'services_host_header' | myLbTranslate }}
                </label>
                <div class="form-content">
                    <input
                        id="healthCheckHostHeader{{templateIndex}}"
                        type="text"
                        name="healthCheckHostHeader"
                        formControlName="healthCheckHostHeader"/>
                    <app-help class="help-button" tag="l7-backend_host-header"></app-help>
                </div>
            </div>
        </section>
        <section data-control-name="healthCheckInterval">
            <div *ngIf="!isInputHidden('healthCheckInterval')">
                <div class="form-group">
                    <label for="healthCheckInterval{{templateIndex}}">{{ 'interval' | myLbTranslate }}</label>
                    <div class="form-content">
                        <app-time-selector
                            id="healthCheckInterval{{templateIndex}}"
                            [formGroup]="getFormGroup(subStepEnum.HEALTH)"
                            formControlName="healthCheckInterval">
                        </app-time-selector>
                        <app-help class="help-button" tag="l7-backend_interval"></app-help>
                    </div>
                </div>
            </div>
        </section>
        <section data-control-name="healthCheckRise">
            <div *ngIf="!isInputHidden('healthCheckRise')" class="form-group">
                <label for="healthCheckRise{{templateIndex}}">{{ 'rise' | myLbTranslate }}</label>
                <div class="form-content">
                    <input
                        id="healthCheckRise{{templateIndex}}"
                        type="text"
                        name="healthCheckRise"
                        formControlName="healthCheckRise"/>
                    <app-validation-messages
                        [control]="serviceForm.get(subStepEnum.HEALTH+ '.healthCheckRise')">
                    </app-validation-messages>
                    <app-help class="help-button" tag="l7-backend_rise"></app-help>
                </div>
            </div>
        </section>
        <section data-control-name="healthCheckFall">
            <div *ngIf="!isInputHidden('healthCheckFall')" class="form-group">
                <label for="healthCheckFall{{templateIndex}}">{{ 'fall' | myLbTranslate }}</label>
                <div class="form-content">
                    <input
                        id="healthCheckFall{{templateIndex}}"
                        type="text"
                        name="healthCheckFall"
                        formControlName="healthCheckFall"/>
                    <app-validation-messages
                        [control]="serviceForm.get(subStepEnum.HEALTH + '.healthCheckFall')">
                    </app-validation-messages>
                    <app-help class="help-button" tag="l7-backend_fall"></app-help>
                </div>
            </div>
        </section>
    </div>
    <!-- Connection options   -->
    <div
        [formGroupName]="subStepEnum.CONNECTION_OPTIONS"
        [hidden]="getWizardSubStepState(subStepEnum.CONNECTION_OPTIONS)">
        <h2>{{ subStepEnum.CONNECTION_OPTIONS | myLbTranslate }}</h2>
        <section data-control-name="httpPipeline">
            <div *ngIf="advancedMode && httpMode" class="form-group">
                <label>{{ 'services_http_pipeline_mode' | myLbTranslate }}</label>
                <div class="form-content">
                    <app-dropdown
                        dropDownId="httpPipeline{{templateIndex}}-dropdown"
                        [formGroup]="getFormGroup(subStepEnum.CONNECTION_OPTIONS)"
                        formControlName="httpPipeline"
                        [content]="dropdown.httpPipelineModes">
                    </app-dropdown>
                    <app-help class="help-button" tag="l7-backend_http-pipeline-mode"></app-help>
                </div>
            </div>
        </section>
        <section data-control-name="httpPretendKeepalive">
            <div *ngIf="advancedMode" class="form-group">
                <div class="form-content">
                    <input
                        id="backendHttpPretendKeepalive{{templateIndex}}"
                        type="checkbox"
                        name="httpPretendKeepalive"
                        formControlName="httpPretendKeepalive"
                        class="lb-checkbox"/>
                    <label for="backendHttpPretendKeepalive{{templateIndex}}" class="lb-checkbox">
                        {{ 'services_http_pretend_keepalive' | myLbTranslate }}
                    </label>
                    <app-help class="help-button" tag="l7-backend_http-pretend-keepalive"></app-help>
                </div>
            </div>
        </section>
        <section data-control-name="httpAcceptInvalidResponse">
            <div *ngIf="advancedMode && httpMode" class="form-group">
                <div class="form-content">
                    <input
                        id="httpAcceptInvalidResponse{{templateIndex}}"
                        type="checkbox"
                        name="httpAcceptInvalidResponse"
                        formControlName="httpAcceptInvalidResponse"
                        class="lb-checkbox"/>
                    <label for="httpAcceptInvalidResponse{{templateIndex}}" class="lb-checkbox">
                        {{ 'services_accept_invalid_http_response' | myLbTranslate }}
                    </label>
                    <app-help class="help-button" tag="l7-backend_accept-invalid-http-response"></app-help>
                </div>
            </div>
        </section>
        <section data-control-name="httpIdleConnectionReuse">
            <div *ngIf="advancedMode" class="form-group">
                <div class="form-content">
                    <input
                        id="httpIdleConnectionReuse{{templateIndex}}"
                        type="checkbox"
                        name="httpIdleConnectionReuse"
                        formControlName="httpIdleConnectionReuse"
                        class="lb-checkbox"/>
                    <label for="httpIdleConnectionReuse{{templateIndex}}" class="lb-checkbox">
                        {{ 'services_http_idle_connection_reuse' | myLbTranslate }}
                    </label>
                    <app-help class="help-button" tag="l7-backend_http-idle-connection-reuse"></app-help>
                </div>
            </div>
        </section>
        <section data-control-name="httpRequestTimeout">
            <div *ngIf="advancedMode && httpMode" class="form-group">
                <div class="form-content">
                    <input
                        id="backendHttpRequestTimeout{{templateIndex}}"
                        type="checkbox"
                        name="httpRequestTimeout"
                        formControlName="httpRequestTimeout"
                        class="lb-checkbox"/>
                    <label for="backendHttpRequestTimeout{{templateIndex}}" class="lb-checkbox">
                        {{ 'services_http_request_timeout' | myLbTranslate }}
                    </label>
                    <app-help class="help-button" tag="l7-backend_http-request-timeout"></app-help>
                </div>
            </div>
        </section>
        <section data-control-name="tunnelTimeout">
            <div *ngIf="advancedMode" class="form-group">
                <label for="tunnelTimeout{{templateIndex}}">{{ 'services_tunnel_timeout' | myLbTranslate }}</label>
                <div class="form-content">
                    <app-time-selector
                        id="tunnelTimeout{{templateIndex}}"
                        [formGroup]="getFormGroup(subStepEnum.CONNECTION_OPTIONS)"
                        formControlName="tunnelTimeout">
                    </app-time-selector>
                    <app-help class="help-button" tag="l7-backend_tunnel-timeout"></app-help>
                </div>
            </div>
        </section>
        <section data-control-name="TCPKeepalive">
            <div *ngIf="advancedMode && !httpMode" class="form-group">
                <div class="form-content">
                    <input
                        id="TCPKeepalive{{templateIndex}}"
                        type="checkbox"
                        name="TCPKeepalive"
                        formControlName="TCPKeepalive"
                        class="lb-checkbox"/>
                    <label for="TCPKeepalive{{templateIndex}}" class="lb-checkbox">
                        {{ 'services_tcp_keepalive' | myLbTranslate }}
                    </label>
                    <app-help class="help-button" tag="l7-backend_tcp-keepalive"></app-help>
                </div>
            </div>
        </section>
        <section data-control-name="connectionRedispatch">
            <div *ngIf="advancedMode" class="form-group">
                <div class="form-content">
                    <input
                        id="connectionRedispatch{{templateIndex}}"
                        type="checkbox"
                        name="connectionRedispatch"
                        formControlName="connectionRedispatch"
                        class="lb-checkbox"/>
                    <label for="connectionRedispatch{{templateIndex}}" class="lb-checkbox">
                        {{ 'services_connection_redispatch' | myLbTranslate }}
                    </label>
                    <app-help class="help-button" tag="l7-backend_connection-redispatch"></app-help>
                </div>
            </div>
        </section>
        <section data-control-name="abortOnClose">
            <div *ngIf="advancedMode" class="form-group">
                <div class="form-content">
                    <input
                        id="abortOnClose{{templateIndex}}"
                        type="checkbox"
                        name="abortOnClose"
                        formControlName="abortOnClose"
                        class="lb-checkbox"/>
                    <label for="abortOnClose{{templateIndex}}" class="lb-checkbox">
                        {{ 'services_abort_on_close' | myLbTranslate }}
                    </label>
                    <app-help class="help-button" tag="l7-backend_abort-on-close"></app-help>
                </div>
            </div>
        </section>
        <section data-control-name="slowStartTime">
            <div *ngIf="advancedMode" class="form-group">
                <h2>{{ 'services_backend_connections' | myLbTranslate }}</h2>
                <label for="slowStartTime{{templateIndex}}">
                    {{ 'services_slow_start_time' | myLbTranslate }}
                </label>
                <div class="form-content">
                    <app-time-selector
                        id="slowStartTime{{templateIndex}}"
                        [formGroup]="getFormGroup(subStepEnum.CONNECTION_OPTIONS)"
                        formControlName="slowStartTime">
                    </app-time-selector>
                    <app-help class="help-button" tag="l7-backend_slow-start-time"></app-help>
                </div>
            </div>
        </section>
        <section data-control-name="backendSourceAddress">
            <div *ngIf="advancedMode" class="form-group">
                <label for="backendSourceAddress{{templateIndex}}">
                    {{ 'services_backend_source_address' | myLbTranslate }}
                </label>
                <div class="form-content">
                    <input
                        id="backendSourceAddress{{templateIndex}}"
                        type="text"
                        name="backendSourceAddress"
                        formControlName="backendSourceAddress"/>
                    <app-validation-messages
                        [control]="serviceForm.get(subStepEnum.CONNECTION_OPTIONS + '.backendSourceAddress')">
                    </app-validation-messages>
                    <app-help class="help-button" tag="l7-backend_backend-source-address"></app-help>
                </div>
            </div>
        </section>
        <section data-control-name="enableServerConnTimeout">
            <div *ngIf="advancedMode" class="form-group">
                <div class="form-content">
                    <input
                        id="enableServerConnTimeout{{templateIndex}}"
                        type="checkbox"
                        name="enableServerConnTimeout"
                        formControlName="enableServerConnTimeout"
                        class="lb-checkbox"/>
                    <label for="enableServerConnTimeout{{templateIndex}}" class="lb-checkbox">
                        {{ 'services_enable_server_conn_timeout' | myLbTranslate }}
                    </label>
                    <app-help class="help-button" tag="l7-backend_server-connection-timeout"></app-help>
                </div>
            </div>
        </section>
        <section data-control-name="serverTimeout">
            <div *ngIf="advancedMode" class="form-group">
                <label for="serverTimeout{{templateIndex}}">
                    {{ 'services_server_timeout' | myLbTranslate }}
                </label>
                <div class="form-content">
                    <app-time-selector
                        id="serverTimeout{{templateIndex}}"
                        [formGroup]="getFormGroup(subStepEnum.CONNECTION_OPTIONS)"
                        formControlName="serverTimeout">
                    </app-time-selector>
                    <app-help class="help-button" tag="l7-backend_server-timeout"></app-help>
                </div>
            </div>
        </section>
    </div>
    <!-- Advanced options -->
    <div
        [formGroupName]="subStepEnum.LAYER_7_ADVANCED"
        [hidden]="getWizardSubStepState(subStepEnum.LAYER_7_ADVANCED)">
        <h2>{{ subStepEnum.LAYER_7_ADVANCED | myLbTranslate }}</h2>
        <section data-control-name="enableFeedbackAgent">
            <div *ngIf="true" class="form-group">
                <div class="form-content">
                    <input
                        id="enableFeedbackAgent{{templateIndex}}"
                        type="checkbox"
                        name="enableFeedbackAgent"
                        formControlName="enableFeedbackAgent"
                        class="lb-checkbox">
                    <label for="enableFeedbackAgent{{templateIndex}}" class="lb-checkbox">
                        {{ 'enable' | myLbTranslate }}
                    </label>
                    <app-help class="help-button" tag="l7-backend_feedback-agent"></app-help>
                </div>
            </div>
        </section>
        <section data-control-name="feedbackAgentPort">
            <div *ngIf="advancedMode" class="form-group">
                <label for="feedbackAgentPort{{templateIndex}}">
                    {{ 'services_agent_port' | myLbTranslate }}
                </label>
                <div class="form-content">
                    <input
                        id="feedbackAgentPort{{templateIndex}}"
                        type="number"
                        name="feedbackAgentPort"
                        formControlName="feedbackAgentPort"
                        appNumbersOnly/>
                    <app-validation-messages
                        [control]="serviceForm.get(subStepEnum.LAYER_7_ADVANCED + '.feedbackAgentPort')">
                    </app-validation-messages>
                    <app-help class="help-button" tag="l7-backend_feedback-agent-port"></app-help>
                </div>
            </div>
        </section>
        <section data-control-name="feedbackAgentInterval">
            <div *ngIf="advancedMode" class="form-group">
                <label for="feedbackAgentInterval{{templateIndex}}">
                    {{ 'services_feedback_agent_interval' | myLbTranslate }}
                </label>
                <div class="form-content">
                    <app-time-selector
                        id="feedbackAgentInterval{{templateIndex}}"
                        [formGroup]="getFormGroup(subStepEnum.LAYER_7_ADVANCED)"
                        formControlName="feedbackAgentInterval">
                    </app-time-selector>
                    <app-help class="help-button" tag="l7-backend_feedback-agent-interval"></app-help>
                </div>
            </div>
        </section>
        <section data-control-name="fallbackServerId">
            <div *ngIf="advancedMode">
                <h2>{{ 'fallback' | myLbTranslate }}</h2>
                <div class="form-group">
                    <label>{{ 'server' | myLbTranslate }}</label>
                    <div class="form-content">
                        <app-dropdown
                            dropDownId="fallbackServer{{templateIndex}}-dropdown"
                            [formGroup]="getFormGroup(subStepEnum.LAYER_7_ADVANCED)"
                            formControlName="fallbackServerId"
                            [content]="dropdown.fallbackConfig">
                        </app-dropdown>
                        <app-help class="help-button" tag="l7-backend_fallback-server"></app-help>
                    </div>
                </div>
            </div>
        </section>
        <section data-control-name="fallbackPersistence">
            <div *ngIf="advancedMode" class="form-group">
                <div class="form-content">
                    <input
                        id="fallbackPersistence{{templateIndex}}"
                        type="checkbox"
                        name="fallbackPersistence"
                        formControlName="fallbackPersistence"
                        class="lb-checkbox"/>
                    <label for="fallbackPersistence{{templateIndex}}" class="lb-checkbox">
                        {{ 'persistence' | myLbTranslate }}
                    </label>
                    <app-help class="help-button" tag="l7-backend_fallback-persistence"></app-help>
                </div>
            </div>
        </section>
        <section data-control-name="enableTProxy">
            <div *ngIf="advancedMode">
                <h2>{{ 'proxy' | myLbTranslate }}</h2>
                <div class="form-group">
                    <div class="form-content">
                        <input
                            id="enableTProxy{{templateIndex}}"
                            type="checkbox"
                            name="enableTProxy"
                            formControlName="enableTProxy"
                            class="lb-checkbox"/>
                        <label for="enableTProxy{{templateIndex}}" class="lb-checkbox">
                            {{ 'services_enable_tproxy' | myLbTranslate }}
                        </label>
                        <app-help class="help-button" tag="l7-backend_enable-tproxy"></app-help>
                    </div>
                </div>
            </div>
        </section>
        <section data-control-name="sendProxyProtocol">
            <div *ngIf="advancedMode" class="form-group">
                <label>{{ 'services_send_proxy_protocol' | myLbTranslate }}</label>
                <div class="form-content">
                    <app-dropdown
                        dropDownId="sendProxyProtocol{{templateIndex}}-dropdown"
                        [formGroup]="getFormGroup(subStepEnum.LAYER_7_ADVANCED)"
                        formControlName="sendProxyProtocol"
                        [content]="dropdown.proxyProtocols">
                    </app-dropdown>
                    <app-help class="help-button" tag="l7-backend_send-proxy-protocol"></app-help>
                </div>
            </div>
        </section>
    </div>
</form>
