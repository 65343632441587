<div class="component-main">
    <div class="content-space-between">
        <h1>{{ 'fallback_pages' | myLbTranslate }}</h1>
        <button
            id="fallback-add-new"
            class="primary"
            [disabled]="isButtonDisabled('fallback-pages-add-page')"
            (click)="addEditFallbackPage()">
            {{ 'fallback_pages_add_page' | myLbTranslate }}
        </button>
    </div>
    <app-table
        [name]="'fallback-pages'"
        [data]="tableRows">
        <app-column [flexGrow]="1" prop="label" label="label"></app-column>
        <app-column [flexGrow]="1" prop="type" label="type" [customCellTemplate]="pageType">
            <ng-template let-row #pageType>
                {{ row.type | titlecase }}
            </ng-template>
        </app-column>
        <app-column [flexGrow]="1" prop="ip" label="ip"></app-column>
        <app-column [flexGrow]="1" prop="port" label="port"></app-column>
        <app-column [flexGrow]="0.75" prop="usage" label="usage" [customCellTemplate]="pageUsage">
            <ng-template let-row #pageUsage>
                <app-usage [usage]="row.usage" modalTitle="fallback_pages_usage"></app-usage>
            </ng-template>
        </app-column>
        <app-column [flexGrow]="0.5" prop="actions" label="" cellAlignment="right" [customCellTemplate]="fbPageActions">
            <ng-template let-row #fbPageActions>
                <app-action-dots>
                    <button
                        [disabled]="isButtonDisabled('fallback-pages-edit-page')"
                        (click)="addEditFallbackPage(row)">
                        {{ 'edit' | myLbTranslate }}
                    </button>
                    <button
                        [disabled]="isButtonDisabled('fallback-pages-delete-page') || row.usage?.length"
                        (click)="confirmDelete(row)">
                        {{ 'delete' | myLbTranslate }}
                    </button>
                </app-action-dots>
            </ng-template>
        </app-column>
    </app-table>
</div>
