<app-wizard
    [hidden]="!parentDataAvailable"
    [showProgressBar]="showProgressBar"
    [showSaveButton]="navigationState?.options?.showSaveButton"
    [showBackButton]="navigationState?.options?.showBackButton"
    [vertical]="true"
    [availableTemplates]="navigationState?.templates"
    [wizardModeState]="navigationState?.options?.showWizardMode"
    [cancelButtonText]="navigationState?.cancelText"
    [wizardTitle]="navigationState?.wizardTitle"
    (saveTemplate)="openSaveAsTemplateModal()"
    (cancelWizard)="cancelSubmission(navigationState?.parentUrl)"
    (wizardComplete)="submitService()">
    <ng-container *ngIf="description && isFirstVisibleStep()">
        <div class="description">
            <h2>{{ 'notes' | myLbTranslate }}:</h2>
            <p [innerHTML]="description"></p>
        </div>
    </ng-container>
    <ng-container #componentContainer></ng-container>
    <ng-container #saveTemplateContainer></ng-container>
</app-wizard>

<ng-container #modalContainer></ng-container>
