<app-modal-wrapper
    modalHeight="400"
    modalWidth="800"
    modalTitle="hanging_backends"
    modalId="hangingBackendsModal">
    <app-modal-body>
        <div class="content-space-between">
            <p>{{ 'hanging_backends_backends_list' | myLbTranslate }}<br>
                {{ 'hanging_backends_backends_info' | myLbTranslate }}</p>
            <div>
                <button
                    *ngIf="selectedRows.length > 1"
                    id="hanging-delete-all-rows"
                    class="button primary medium"
                    [disabled]="!permissionsLookup['hanging-backends-delete-backend']"
                    (click)="confirmDeleteBackend(selectedRows)">
                    {{ 'delete' | myLbTranslate }} ({{ selectedRows.length }}) {{ 'rows' | myLbTranslate }}
                </button>
                <button
                    *ngIf="selectedRows.length !== hangingBackends.length
                        && selectedRows.length <= 1
                        && hangingBackends.length !== 1"
                    id="hanging-select-all-rows"
                    class="button primary medium grey-button"
                    (click)="selectAllRows()">
                    {{ 'select_all' | myLbTranslate }}
                </button>
            </div>
        </div>
        <app-table
            id="hangingBackends-table"
            [data]="hangingBackendRows"
            [selectedRows]="selectedRows"
            (selectedRowsOut)="onSelect($event)">
            <app-column [flexGrow]="0.5" prop="label" label="services_name"></app-column>
            <app-column [flexGrow]="0.5" prop="mode" label="services_mode"></app-column>
            <app-column
                [flexGrow]="0.5"
                prop="realServers"
                label="services_real_servers"
                [cellTemplate]="realServers">
                <ng-template let-row #realServers>
                    <ng-container *ngIf="row.realServers.length; else zeroMessage">
                        <app-tooltip trigger="{{row.realServers.length}}" [width]="350">
                            <table class="lb-small-table">
                                <thead>
                                <tr>
                                    <th>{{ 'label' | myLbTranslate }}</th>
                                    <th>{{ 'address' | myLbTranslate }}</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr *ngFor="let realServer of realServersByBackend[row.id] | slice:0:2;">
                                    <td>{{ realServer.label }}</td>
                                    <td>{{ realServer.ip }}/{{ realServer.port }}</td>
                                </tr>
                                </tbody>
                            </table>
                            <div class="content-center hanging-backends-button">
                                <button
                                    *ngIf="row.realServers.length && row.realServers.length > 2"
                                    class="primary"
                                    (click)="showUsageModal(row)">
                                    {{ 'more' | myLbTranslate }}
                                </button>
                            </div>
                        </app-tooltip>
                    </ng-container>
                    <ng-template #zeroMessage>
                        <span>{{ row.realServers.length }}</span>
                    </ng-template>
                </ng-template>
            </app-column>
            <app-column
                [flexGrow]="0.5"
                prop="actions"
                label=""
                [cellAlignment]="'right'"
                [cellTemplate]="hangingBackendActions">
                <ng-template let-row #hangingBackendActions>
                    <app-action-dots *ngIf="selectedRows.length < 2">
                        <button
                            *ngIf="frontends.length"
                            id="hanging-backends-create-acl"
                            [disabled]="!permissionsLookup['hanging-backends-create-acl']"
                            (click)="createACL(row.id)">
                            {{ 'hanging_backends_create_acl' | myLbTranslate }}
                        </button>
                        <button
                            id="hanging-backends-delete-backend"
                            [disabled]="!permissionsLookup['hanging-backends-delete-backend']"
                            (click)="confirmDeleteBackend([row])">
                            {{ 'delete' | myLbTranslate }}
                        </button>
                    </app-action-dots>
                </ng-template>
            </app-column>
        </app-table>
    </app-modal-body>
</app-modal-wrapper>

<app-modal-wrapper
    modalHeight="300"
    modalWidth="500"
    modalTitle="hanging_backend_real_servers_usage"
    modalId="BackendRealServersUsageModal">
    <app-modal-body>
        <p>{{ 'hanging_backends_usage_for_backend' | myLbTranslate }}: {{ modalLabel }}</p>
        <app-table id="hanging-backebnd-usage-table" [data]="realServersInfo">
            <app-column
                [flexGrow]="1"
                prop="label"
                label="hanging_backends_usage_real_server_label"
                [cellTemplate]="usageLabel">
                <ng-template let-row="row" #usageLabel>
                    <span class="real-server-label">
                        {{ row.label }}
                    </span>
                </ng-template>
            </app-column>
            <app-column
                [flexGrow]="1"
                prop="ip"
                label="hanging_backends_usage_real_server_address"
                [cellTemplate]="usageIp">
                <ng-template let-row #usageIp>
                    <span class="real-server-address">
                        {{ row.ip }}
                    </span>
                </ng-template>
            </app-column>
        </app-table>
    </app-modal-body>
</app-modal-wrapper>

<app-modal-wrapper modalTitle="hanging_backends_deleting" modalId="progressModal" modalWidth="400">
    <app-modal-body>
        <div class="dot-wrapper">
            <div class="dot-spin"></div>
        </div>
        <div class="progress-box" [innerHTML]="progressMessage"></div>
    </app-modal-body>
</app-modal-wrapper>
