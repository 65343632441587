<div #dropContainer class="drop-container">
    <div [formGroup]="formGroup">
        <input
            #hiddenInput
            class="hidden-input"
            [class.open]="open"
            [class.disabled]="fakeDisabled || hiddenContentMatchesContent()"
            type="text"
            [tabindex]="content?.length < 2 ? -1 : 0"
            [formControlName]="formControlName"
            [value]=""
            (focus)="onFocus()"
            (blur)="closeOnBlur($event)"
            autocomplete="off"/>
        <app-validation-messages [control]="formGroup.controls[formControlName]">
        </app-validation-messages>
        <div
            [ngClass]="[open ? 'drop open' : 'drop']"
            [style.width]="dropdownWidth">
            <!-- Search value required otherwise the element can be disabled if search returns nothing -->
            <!-- tabIndex -1 so we can't tab to this element -->
            <button
                #dropToggle
                [id]="dropDownId?.length ? dropDownId : formControlName"
                [disabled]="buttonIsDisabled()"
                tabindex="-1"
                class="toggle-button"
                [class.force-focus]="open"
                appHint
                [hintText]="hintText"
                hintPosition="bottom"
                [hintShow]="!!hintText"
                type="button"
                [title]="hintText ? '' : title ? title : selectedText"
                [class.display-as-input]="formControlName !== 'default' || displayAsInput"
                [class.external]="applyExternalStyling"
                [style.width]="dropdownWidth"
                (click)="toggleDrop($event)">
                <ng-container *ngIf="template else otherContent">
                    <ng-container *ngIf="template" [ngTemplateOutlet]="template"></ng-container>
                </ng-container>
                <ng-template #otherContent>
                    <div class="button-text-wrapper {{titleAlign}}">
                        <ng-container *ngIf="image">
                            <img src="{{image}}" alt="Dynamic image without description">
                        </ng-container>
                        <ng-container *ngIf="title; else showSelectedText">
                            <span class="title">{{ title }}</span>
                        </ng-container>
                        <ng-template #showSelectedText>
                            <span [hidden]="!selectedText" [class]="open ? 'title open' : 'title'">
                                {{ selectedText }}
                            </span>
                        </ng-template>
                    </div>
                </ng-template>
                <img
                    [hidden]="hideArrow"
                    class="arrow"
                    ngSrc="/assets/shared/icons/chevron-down.svg"
                    alt="dropdown arrow"
                    height="12"
                    width="12">
            </button>
            <ng-template #dropTemplate>
                <app-dropdown-wrapper class="dropdown-wrapper" [class.openUpwards]="openUp" [id]="dropDownId">
                    <div id="drop-content-container" class="drop-content" [style.background-color]="contentBackground">
                        <ng-container *ngIf="!openUp">
                            <ng-container *ngTemplateOutlet="searchTemplate"></ng-container>
                        </ng-container>
                        <ng-container *ngIf="content; else html">
                            <div
                                class="scroll-content"
                                [class.withSearch]="search"
                                [class.openUpWithSearch]="openUp && search">
                                <ul class="list-content-wrapper">
                                    <ng-container *ngFor="let item of content">
                                        <li
                                            #listItem
                                            [hidden]="item.value === selectedText ||
                                            item.value === '' ||
                                            hiddenContentKeys.includes(item.key)"
                                            [class.highlight]="highLightedItem?.value === item.value"
                                            [class.fake-disabled]="item.options?.disabled"
                                            (click)="setSelectedItem(item)">
                                            {{ item.value }}
                                        </li>
                                    </ng-container>
                                </ul>
                                <p class="no-match" *ngIf="content && !content.length && search">
                                    {{ 'no_matches' | myLbTranslate }}
                                </p>
                            </div>
                        </ng-container>
                        <ng-template #html>
                            <div
                                [hidden]="!(open || forceOpen)"
                                [class]="titleAlign + ' external-drop-container'"
                                [class.rounded-top]="!search">
                                <ng-container *ngIf="!search || (search && matches) else noMatches">
                                    <ng-content></ng-content>
                                </ng-container>
                                <ng-template #noMatches>
                                    <div class="html-no-match">
                                        <span>{{ 'no_matches' | myLbTranslate }}</span>
                                    </div>
                                </ng-template>
                            </div>
                        </ng-template>
                        <ng-container *ngIf="openUp">
                            <ng-container *ngTemplateOutlet="searchTemplate"></ng-container>
                        </ng-container>
                    </div>
                </app-dropdown-wrapper>
            </ng-template>
        </div>
    </div>
</div>

<ng-template #searchTemplate>
    <div
        [hidden]="!search || !open"
        class="search-wrapper"
        [class.openUpwards]="openUp"
        [style.background-color]="contentBackground"
        [class.input-search-wrapper]="search && (displayAsInput || formControlName !== 'default')">
        <!-- name="any-name" is to stop chrome adding password manager to the input -->
        <input
            #searchInput
            id="dropdown-search"
            class="dropdown-search"
            name="any-name"
            tabindex="-1"
            (keyup)="searchTempRows($event)"
            placeholder="{{ 'search_placeholder' | myLbTranslate }}"
            type="search"
            autocomplete="off"/>
        <button
            *ngIf="searchValue.length"
            [class.openUpwards]="openUp"
            class="clear-search"
            type="reset"
            (click)="clearSearch()">
            <img
                ngSrc="assets/shared/icons/close-cross.svg"
                class="clear-search-img"
                alt="Clear search"
                height="20"
                width="20"/>
        </button>
    </div>
</ng-template>
