<ng-template #errorMessageTemplate>
    <div class="error-image-wrapper">
        <img
            ngSrc="../../../../assets/shared/icons/notification-solid-exclamation-triangle.svg"
            height="20"
            width="20"
            alt="Exclamation icon">
        <h5 class="error-message">{{ 'service_outage' | myLbTranslate }}</h5>
    </div>
    <p>{{ 'error_page_message' | myLbTranslate }}</p>
    <a class="default" href="https://status.loadbalancer.org/" target="_blank">
        {{ 'view_service_status' | myLbTranslate }}
    </a>
    <img
        ngSrc="../../../../assets/shared/icons/chevron-right.svg"
        height="12"
        width="12"
        alt="chevron right icon">
</ng-template>

<app-alternative-state-page
    header="error_page_title"
    titleText=""
    [messageTemplate]="errorMessageTemplate"
    imagePath="assets/centralisedManagement/empty-state/outage-offline.svg"
    [isError]="true">
</app-alternative-state-page>
