<div class="component-main">
    <div class="content-space-between">
        <h1>{{ 'view_config_snmp_conf' | myLbTranslate }}</h1>
    </div>
    <form [formGroup]="formGroup" [class.disabled]="formGroup.disabled">
        <div class="snmp_conf_select_version">
            <label>{{ 'snmp_conf_select_version' | myLbTranslate }}</label>
        </div>
        <app-lb-checkbox
            id="snmp_enable_v2"
            [formGroup]="formGroup"
            formControlName="enabledV2"
            label="{{ 'snmp_conf_snmp_enable_v2' | myLbTranslate }}">
        </app-lb-checkbox>
        <app-lb-checkbox
            id="snmp_enable_v3"
            [formGroup]="formGroup"
            formControlName="enabledV3"
            label="{{ 'snmp_conf_snmp_enable_v3' | myLbTranslate }}">
        </app-lb-checkbox>
        <div class="form-group" [hidden]="formGroup.get('enabledV3').value && !formGroup.get('enabledV2').value">
            <label [class.disabled]="formGroup.get('community').disabled">
                {{ 'snmp_conf_snmp_community' | myLbTranslate }}
            </label>
            <div class="form-content">
                <input
                    title="{{'snmp_conf_snmp_community' | myLbTranslate }}"
                    type="text"
                    size="30"
                    name="community"
                    formControlName="community"/>
                <app-validation-messages [control]="formGroup.controls.community"></app-validation-messages>
                <app-help tag="snmp_community"></app-help>
            </div>
        </div>
        <div class="form-group">
            <label [class.disabled]="formGroup.get('location').disabled">
                {{ 'snmp_conf_snmp_location' | myLbTranslate }}
            </label>
            <div class="form-content">
                <input
                    title="{{'snmp_conf_snmp_location' | myLbTranslate }}"
                    type="text"
                    size="30"
                    name="location"
                    formControlName="location"/>
                <app-validation-messages [control]="formGroup.controls.location"></app-validation-messages>
                <app-help tag="snmp_location"></app-help>
            </div>
        </div>
        <div class="form-group">
            <label
                [class.disabled]="formGroup.get('contact').disabled">{{ 'snmp_conf_snmp_contact' | myLbTranslate }}</label>
            <div class="form-content">
                <input
                    title="{{'snmp_conf_snmp_contact' | myLbTranslate }}"
                    type="text"
                    size="30"
                    name="contact"
                    formControlName="contact"/>
                <app-validation-messages [control]="formGroup.controls.contact"></app-validation-messages>
                <app-help tag="snmp_contact"></app-help>
            </div>
        </div>
    </form>
</div>
<div class="component-footer">
    <button class="outline" routerLink="../">{{ 'cancel' | myLbTranslate }}</button>
    <app-loading-button
        buttonId="snmp-config-update"
        buttonText="submit"
        buttonClass="primary"
        [isDisabled]="formGroup.invalid || formGroup.pristine || !permissionsLookup['snmp-config-update']"
        [callBack]="submit.bind(this)">
    </app-loading-button>
</div>

