<div class="component-main">
    <div class="content-space-between">
        <h1>{{ 'routing' | myLbTranslate }}</h1>
    </div>
    <h2>{{ 'routing_default_gateway' | myLbTranslate }}</h2>
    <form class="routing-form" [formGroup]="defaultGWForm">
        <div class="form-row">
            <div class="form-group">
                <label>IPv4</label>
                <div class="form-content">
                    <input
                        id="routing-default-ipv4"
                        title="IPv4 Default Gateway"
                        type="text"
                        size="20"
                        name="ipv4gw"
                        formControlName="ipv4gateway">
                    <app-validation-messages [control]="defaultGWForm.get('ipv4gateway')"></app-validation-messages>
                </div>
            </div>
            <div class="form-group">
                <!--suppress XmlInvalidId -->
                <label for="ipv4-interface">{{ 'routing_via_interface' | myLbTranslate }}</label>
                <div class="form-content">
                    <app-dropdown
                        dropDownId="ipv4-interface"
                        formControlName="ipv4interface"
                        [formGroup]="defaultGWForm"
                        [content]="dropdownInterfaces"
                        [dropdownWidth]="200">
                    </app-dropdown>
                    <app-help class="help-button" tag="routing_ipv4"></app-help>
                </div>
            </div>
        </div>
        <div class="form-row">
            <div class="form-group">
                <label>IPv6</label>
                <div class="form-content">
                    <input
                        id="routing-default-ipv6"
                        title="IPv6 Default Gateway"
                        type="text"
                        size="20"
                        name="ipv6gw"
                        formControlName="ipv6gateway">
                    <app-validation-messages [control]="defaultGWForm.get('ipv6gateway')"></app-validation-messages>
                </div>
            </div>
            <div class="form-group">
                <!--suppress XmlInvalidId -->
                <label for="ipv6-interface">
                    {{ 'routing_via_interface' | myLbTranslate }}
                </label>
                <div class="form-content">
                    <app-dropdown
                        dropDownId="ipv6-interface"
                        formControlName="ipv6interface"
                        [formGroup]="defaultGWForm"
                        [content]="dropdownInterfaces"
                        [search]="true"
                        [dropdownWidth]="200">
                    </app-dropdown>
                    <app-help class="help-button" tag="routing_ipv6"></app-help>
                </div>
            </div>
        </div>
        <app-loading-button
            buttonId="routing-update-gateway"
            buttonClass="primary"
            buttonText="update"
            [callBack]="submitGatewayHelper.bind(this)"
            [isDisabled]="defaultGWForm.invalid || defaultGWForm.pristine">
        </app-loading-button>
    </form>
    <div class="content-space-between">
        <h2 class="form-title">{{ 'routing_static_routes' | myLbTranslate }}</h2>
        <button
            id="routing-add-new-route"
            class="primary"
            [disabled]="isButtonDisabled('routing-add-new-route')">
            {{ 'routing_add_route' | myLbTranslate }}
        </button>
    </div>
    <form class="form-table" [formGroup]="staticRoutesForm">
        <app-table id="routing-list" [data]="tableRows" [selectedRows]="selectedRow">
            <app-column [flexGrow]="1" prop="subnet" label="subnet" [cellTemplate]="subnet">
                <ng-template let-row #subnet>
                    <span *ngIf="!row.addMode">
                      {{ row.subnet }}
                    </span>
                </ng-template>
            </app-column>
            <app-column [flexGrow]="1" prop="Gateway" label="gateway">
                <ng-template let-row="row" appLbTableColumnCell>
                    <span *ngIf="!row.addMode">
                      {{ row.gatewayIp }}
                    </span>
                </ng-template>
            </app-column>
            <app-column
                [flexGrow]="1"
                prop="actions"
                label=""
                cellAlignment="right"
                [cellTemplate]="routingActions">
                <ng-template let-row #routingActions>
                    <div *ngIf="!row.addMode">
                        <app-action-dots>
                            <button title="Remove" name="remove" (click)="deleteRoute(row)">
                                {{ 'routing_delete_route' | myLbTranslate }}
                            </button>
                        </app-action-dots>
                    </div>
                    <div *ngIf="row.addMode">
                        <button (click)="cancelAdd()" class="button-as-link">Cancel</button>
                        <app-loading-button
                            buttonClass="primary"
                            buttonText="add"
                            [isDisabled]="staticRoutesForm.invalid || staticRoutesForm.pristine"
                            [callBack]="submitRoute.bind(this)">
                        </app-loading-button>
                    </div>
                </ng-template>
            </app-column>
        </app-table>
    </form>
</div>
