<div id="header-wrapper">
    <div class="header">
        <div class="header-block logo-component">
            <app-logo></app-logo>
        </div>
        <div class="header-block search">
            <app-algolia-search></app-algolia-search>
        </div>
        <div class="header-block icons">
            <app-notifications-bell-icon></app-notifications-bell-icon>
            <div class="header-item header-account">
                <app-lb-logged-in-user titleAlignment="left"></app-lb-logged-in-user>
            </div>
        </div>
        <div class="header-block burger-menu">
            <app-burger-menu-3 [menuConfig]="menuConfig">
                <div menu-footer>
                    <div class="burger-menu-client-details">
                        <ng-container [ngTemplateOutlet]="menuDetails"></ng-container>
                    </div>
                </div>
            </app-burger-menu-3>
        </div>
    </div>
</div>

<div id="main-wrapper" [class.notification]="licenceExpired">
    <app-notification-header-wrapper></app-notification-header-wrapper>
    <div #mainContent id="main-content">
        <app-menu-3 [menuConfig]="menuConfig">
            <div menu-footer>
                <ng-container [ngTemplateOutlet]="menuDetails"></ng-container>
            </div>
        </app-menu-3>
        <div> <!-- Required as unable to apply 100% to the router-outlet -->
            <div id="content-wrapper">
                <router-outlet></router-outlet>
            </div>
        </div>
    </div>
</div>

<ng-template #menuDetails>
    <app-menu-footer></app-menu-footer>
</ng-template>

<!-- Drawer holder -->
<app-drawer></app-drawer>

<app-relogin></app-relogin>
<app-modal-wrapper
    [isOverlayBlurred]="true"
    [isOverlayClickable]="false"
    modalTitle="logout_confirmation"
    modalId="logoutConfirm"
    style="user-select: none;"
    footerButtonAlignment="content-center">
    <app-modal-body>
        <p>{{ 'confirm_logout' | myLbTranslate }}</p>
    </app-modal-body>
    <app-modal-footer>
        <button class="primary" (click)="logout()">{{ 'logout' | myLbTranslate }}</button>
    </app-modal-footer>
</app-modal-wrapper>
