<app-widget
    #widget
    class="widget-item col-span-1"
    title="{{ 'system_updates' | myLbTranslate }}"
    id="widget-sys-updates"
    [loading]="loading">
    <div class="content-vertical-middle" *ngIf="updates">
        <h1
            [class.default]="count < 1"
            [class.success]="count >= 1 && count < 5"
            [class.critical]="count >= 5">
            {{ loading ? 0 : count }}
        </h1>
        <h2 *ngIf="count > 0">{{ 'available' | myLbTranslate }}</h2>
        <button
            *ngIf="count > 0"
            id="sys-updates-widget-link"
            class="primary"
            [disabled]="!permissionsLookup['sys-updates-widget-link']"
            (click)="internalLink('../maintenance/software-update')">
            {{ 'update' | myLbTranslate }}
        </button>
    </div>
</app-widget>
