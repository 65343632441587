<div class="component-main">
    <div class="content-space-between">
        <h1>{{ 'backup_restore_upload_backup' | myLbTranslate }}</h1>
    </div>
    <form [formGroup]="uploadForm" novalidate [class.disabled]="uploadForm.disabled">
        <div class="form-group">
            <div class="form-content">
                <app-upload
                    id="upload-file"
                    acceptedTypes="application/json"
                    [resetFileInput]="inputReset"
                    (fileSelected)="onFileChange($event)">
                </app-upload>
                <app-validation-messages [control]="uploadForm.get('file')"></app-validation-messages>
            </div>
        </div>
    </form>
</div>
<div class="component-footer">
    <button id="backupRestore-upload-cancel" class="outline" [routerLink]="['..']">
        {{ 'cancel' | myLbTranslate }}
    </button>
    <app-loading-button
        buttonId="backupRestore-upload-modal-upload"
        buttonClass="primary"
        buttonText="upload"
        [isDisabled]="uploadForm.invalid || !permissionsLookup['backupRestore-upload-modal-upload']"
        [callBack]="submitUpload.bind(this)">
    </app-loading-button>
</div>
