<div class="component-main">
    <div class="content-space-between">
        <h1>{{ 'ip_groups' | myLbTranslate }}</h1>
        <button
            id="ip-groups-add-group"
            class="primary"
            [disabled]="!permissionsLookup['ip-groups-add-group']"
            [routerLink]="'add'">
            {{ 'ip_add_group' | myLbTranslate }}
        </button>
    </div>
    <app-table
        id="ip-groups"
        [data]="tableRows"
        [showPaginator]="false"
        [stickyHeader]="true">
        <app-column label="name" prop="name" [flexGrow]="0.75"></app-column>
        <app-column label="ip_address" prop="networkIp" [flexGrow]="1" [cellTemplate]="networkIp">
            <ng-template let-row #networkIp>
                <div *ngIf="row.networkIp">
                    <app-tooltip
                        [width]="432"
                        trigger="{{row.networkIp}}">
                        Range: {{ row.ipRange?.first }}/{{ row.ipRange?.last }}
                    </app-tooltip>
                </div>
                <span *ngIf="!row.networkIp">
                    {{ 'none_set' | myLbTranslate }}
                </span>
            </ng-template>
        </app-column>
        <app-column [flexGrow]="1" prop="assignedInterfaces" label="interface"></app-column>
        <app-column [flexGrow]="1" prop="gateway" label="gateway"></app-column>
        <app-column
            [flexGrow]="1" prop="defaultGateway" label="routing_default_gateway" [cellTemplate]="checkbox">
            <ng-template let-row #checkbox>
                <img
                    *ngIf="row.defaultGateway"
                    ngSrc="./assets/shared/icons/checkbox-checked.svg"
                    height="20"
                    width="20"
                    alt="Checkbox checked"/>
            </ng-template>
        </app-column>
        <app-column [flexGrow]="0.75" prop="usage" label="usage" [cellTemplate]="usage">
            <ng-template let-row #usage>
                <app-usage [usage]="row.usage" modalTitle="ip_groups_usage"></app-usage>
            </ng-template>
        </app-column>
        <app-column
            [flexGrow]="0.4" prop="actions" label="" cellAlignment="right" [cellTemplate]="interfaceActions">
            <ng-template let-row #interfaceActions>
                <app-action-dots>
                    <button
                        id="ip-groups-edit-group"
                        [disabled]="!permissionsLookup['ip-groups-edit-group']"
                        (click)="openEditGroup(row)">
                        {{ 'edit' | myLbTranslate }}
                    </button>
                    <button
                        id="ip-groups-delete-group"
                        [disabled]="!permissionsLookup['ip-groups-delete-group'] || row.usage?.length"
                        (click)="confirmDeleteGroup(row)">
                        {{ 'delete' | myLbTranslate }}
                    </button>
                </app-action-dots>
            </ng-template>
        </app-column>
    </app-table>
</div>
