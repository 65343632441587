<div [class.component-main]="!componentEmbedded">
    <div class="content" [class.embedded]="componentEmbedded">
        <ng-container *ngIf="!componentEmbedded; else embeddedTitle">
            <h1>{{ pageTitle | myLbTranslate }}</h1>
        </ng-container>
        <ng-template #embeddedTitle>
            <h2>{{ pageTitle | myLbTranslate }}</h2>
        </ng-template>
    </div>
    <ng-container *ngIf="showCRLForm else certForm">
        <form [formGroup]="form">
            <div class="row">
                <div class="col">
                    <div class="form-group">
                        <label for="add-edit-cert-name">{{ 'name' | myLbTranslate }}*</label>
                        <div class="form-content">
                            <input
                                id="add-edit-crl-name"
                                title="{{ 'name' | myLbTranslate }}"
                                type="text"
                                size="30"
                                name="name"
                                formControlName="name"/>
                            <app-validation-messages
                                [control]="form.controls['name']"></app-validation-messages>
                            <app-help class="help-button" tag="ssl-certificate-add-edit-cert_name"></app-help>
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="add-edit-cert-cert">{{ 'certificate' | myLbTranslate }}*</label>
                        <div class="form-content">
                        <textarea
                            id="add-edit-crl-cert"
                            title="{{ 'certificate' | myLbTranslate }}"
                            rows="6"
                            name="cert"
                            formControlName="crl"
                            class="code"></textarea>
                            <app-validation-messages
                                [control]="form.controls['crl']"></app-validation-messages>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </ng-container>
    <ng-template #certForm>
        <form [formGroup]="form">
            <div class="row">
                <div class="col">
                    <div class="form-group">
                        <label for="add-edit-cert-name">{{ 'name' | myLbTranslate }}*</label>
                        <div class="form-content">
                            <input
                                id="add-edit-cert-name"
                                title="{{ 'name' | myLbTranslate }}"
                                type="text"
                                size="30"
                                name="name"
                                formControlName="name"/>
                            <app-validation-messages
                                [control]="form.controls['name']"></app-validation-messages>
                            <app-help class="help-button" tag="ssl-certificate-add-edit-cert_name"></app-help>
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="add-edit-cert-cert">{{ 'certificate' | myLbTranslate }}*</label>
                        <div class="form-content">
                            <textarea
                                id="add-edit-cert-cert"
                                title="{{ 'certificate' | myLbTranslate }}"
                                rows="6"
                                name="cert"
                                formControlName="cert">
                            </textarea>
                            <app-validation-messages [control]="form.controls['cert']"></app-validation-messages>
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="add-edit-cert-privateKey">{{ 'certificate_private_key' | myLbTranslate }}*</label>
                        <div class="form-content">
                            <textarea
                                id="add-edit-cert-privateKey"
                                title="{{ 'certificate_private_key' | myLbTranslate }}"
                                rows="6"
                                name="privateKey"
                                formControlName="privateKey">
                            </textarea>
                            <app-validation-messages [control]="form.controls['privateKey']">
                            </app-validation-messages>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </ng-template>
</div>
<div class="component-footer" [class.embedded]="componentEmbedded">
    <button
        id="certs-cancel"
        class="outline"
        (click)="returnToParent()">
        {{ 'cancel' | myLbTranslate }}
    </button>
    <app-loading-button
        buttonClass="primary"
        [buttonText]="id ? 'update' : 'add'"
        [isDisabled]="form.pristine || form.invalid"
        [callBack]="submitCertificate.bind(this)">
    </app-loading-button>
</div>
