<div [class.component-main]="!componentEmbedded">
    <div class="content-space-between" [class.embedded]="componentEmbedded">
        <div class="content-left">
            <ng-container *ngIf="!componentEmbedded; else embedded">
                <h1>{{ (editId ? 'floating_ip_edit' : 'floating_ip_add') | myLbTranslate }}</h1>
            </ng-container>
            <ng-template #embedded>
                <h2 class="embedded">{{ (editId ? 'floating_ip_edit' : 'floating_ip_add') | myLbTranslate }}</h2>
            </ng-template>
        </div>
    </div>
    <form [formGroup]="ipForm">
        <div class="form-group">
            <!--suppress XmlInvalidId -->
            <label for="floating-ip">{{ 'group' | myLbTranslate }}</label>
            <div class="form-content">
                <app-dropdown
                    dropDownId="floating-ip"
                    [formGroup]="ipForm"
                    formControlName="group"
                    [content]="dropdownGroups">
                </app-dropdown>
            </div>
            <div *ngIf="groupMessage" class="notes">
                <span class="note">{{ 'static_route_range' | myLbTranslate }}: {{ groupMessage.ipRange }}</span>
            </div>
        </div>
        <div class="form-group">
            <label for="ip">{{ 'ip' | myLbTranslate }}</label>
            <div class="form-content">
                <input type="text" size="25" name="ip" id="ip" formControlName="ip"/>
                <app-validation-messages [control]="ipForm.controls['ip']"></app-validation-messages>
            </div>
        </div>
        <div class="form-group">
            <div class="form-content">
                <input id="enabled" formControlName="enabled" class="lb-checkbox" type="checkbox">
                <label for="enabled">{{ 'ip_enable_fip' | myLbTranslate }}</label>
            </div>
        </div>
    </form>
</div>

<div class="component-footer" [class.embedded]="componentEmbedded">
    <button
        id="floating-ip-cancel"
        class="outline"
        (click)="cancelButton()">
        {{ 'cancel' | myLbTranslate }}
    </button>
    <app-loading-button
        buttonId="floating-ip-submit"
        buttonClass="primary"
        [buttonText]="editId ? 'update': 'add'"
        [isDisabled]="ipForm.invalid || ipForm.pristine"
        [callBack]="submitIpCheck.bind(this)">
    </app-loading-button>
</div>

<app-modal-wrapper modalWidth="400" modalId="noIPGroupsModal" modalTitle="attention">
    <app-modal-body>
        <span>{{ 'ip_no_ip_config' | myLbTranslate }}</span>
    </app-modal-body>
    <app-modal-footer>
        <button class="outline" (click)="this.modalService.close('noIPGroupsModal')">
            {{ 'cancel' | myLbTranslate }}
        </button>
        <button
            class="primary"
            (click)="linkToGroups()">
            {{ 'ip_go_to_groups' | myLbTranslate }}
        </button>
    </app-modal-footer>
</app-modal-wrapper>
