<div class="component-main">
    <div class="content-space-between">
        <h1>{{ 'manage_services' | myLbTranslate }}</h1>
        <div>
            <button
                *ngIf="hangingBackends.length"
                id="manage-services-hanging-backends"
                [disabled]="!permissionsLookup['manage-services-hanging-backends']"
                class="primary"
                (click)="openHangingBackendsModal()">
                {{ 'hanging_backends' | myLbTranslate }} ({{ hangingBackends.length }})
            </button>
            <button
                id="open-new-service-modal"
                class="primary"
                [disabled]="!permissionsLookup['manage-services-new-service'] || transactionActive || versionMismatch"
                (click)="createNewService()">
                {{ 'add_service' | myLbTranslate }}
            </button>
        </div>
    </div>

    <app-table
        id="frontend-services"
        [data]="tableRows"
        [allowMultiSelect]="true"
        [showPaginator]="false"
        [stickyHeader]="true"
        [searchTerm]="searchTerm"
        (selectedRowsOut)="onSelect($event)">
        <app-column
            prop="serviceName"
            label="services_name"
            [flexGrow]="0.75"
            [cellTemplate]="nameTemplate">
            <ng-template let-row #nameTemplate>
                <span
                    class="routerLink"
                    id="manage-services-service-details"
                    [class.disabled]="!permissionsLookup['manage-services-service-details']"
                    [routerLink]="['service-details', row.id]">
                    {{ row.serviceName }}
                </span>
            </ng-template>
        </app-column>
        <app-column label="" prop="sslEnable" [cellTemplate]="sslEnableTemplate">
            <ng-template let-row #sslEnableTemplate>
                <img
                    *ngIf="row.sslEnable"
                    ngSrc="./assets/shared/icons/locked.svg"
                    height="16"
                    width="16"
                    alt="SSL enabled lock"/>
            </ng-template>
        </app-column>
        <app-column prop="ip" label="ip" [flexGrow]="1.5"></app-column>
        <app-column prop="ports" label="ports" [flexGrow]="0.75"></app-column>
        <app-column prop="mode" label="mode" [flexGrow]="0.75"></app-column>
        <app-column prop="templateTypeFriendlyName" label="type" [flexGrow]="0.75"></app-column>
        <app-column prop="status" label="status" [flexGrow]="0.75" [cellTemplate]="statusTemplate">
            <ng-template let-row #statusTemplate>
                {{ row.status | titlecase }}
            </ng-template>
        </app-column>
        <app-column
            prop="actions"
            label=""
            [cellTemplate]="actionsTemplate"
            [selectAll]="selectAllActions">
            <ng-template #actionsTemplate let-row>
                <app-action-dots>
                    <ng-container *ngIf="row.status === undefined">
                        <button
                            id="manage-services-restart-haproxy"
                            [disabled]="!permissionsLookup['manage-services-restart-haproxy']"
                            (click)="servicesUtilsService.restartHaProxy()">
                            {{ 'restart_haproxy' | myLbTranslate }}
                        </button>
                    </ng-container>
                    <button
                        id="manage-services-stick-tables"
                        [disabled]="row.status === undefined || !permissionsLookup['manage-services-stick-tables']"
                        (click)="navigateToStickTables(row)">
                        {{ 'manage_services_view_stick_table' | myLbTranslate }}
                    </button>
                    <button
                        *ngIf="row?.serviceType.toLowerCase() !== 'monitor'"
                        id="manage-services-online-service"
                        [disabled]="!permissionsLookup['manage-services-online-service']"
                        [hidden]="servicesUtilsService.showButton(row.status, statusEnum.ONLINE)"
                        (click)="frontendAction('online-Frontend', row)">
                        {{ 'online' | myLbTranslate }}
                    </button>
                    <button
                        *ngIf="row?.serviceType.toLowerCase() !== 'monitor'"
                        id="manage-services-drain-service"
                        [disabled]="!permissionsLookup['manage-services-drain-service']"
                        [hidden]="servicesUtilsService.showButton(row.status, statusEnum.MAINT)"
                        (click)="frontendAction('drain-Frontend', row)">
                        {{ 'drain' | myLbTranslate }}
                    </button>
                    <button
                        *ngIf="row?.serviceType.toLowerCase() !== 'monitor'"
                        id="manage-services-halt-service"
                        [disabled]="!permissionsLookup['manage-services-halt-service']"
                        [hidden]="servicesUtilsService.showButton(row.status, statusEnum.HALT)"
                        (click)="frontendAction('halt-Frontend', row)">
                        {{ 'halt' | myLbTranslate }}
                    </button>
                    <ng-container *ngIf="row?.sslEnable else defaultEdit">
                        <button
                            id="manage-services-edit-ssl-service"
                            [disabled]="!(
                                permissionsLookup['manage-services-edit-service'] &&
                                permissionsLookup['manage-services-edit-ssl-service']
                                )"
                            (click)="editService(row)">
                            {{ 'edit' | myLbTranslate }}
                        </button>
                    </ng-container>
                    <ng-template #defaultEdit>
                        <button
                            id="manage-services-edit-service"
                            [disabled]="!permissionsLookup['manage-services-edit-service']"
                            (click)="editService(row)">
                            {{ 'edit' | myLbTranslate }}
                        </button>
                    </ng-template>
                    <button
                        id="manage-services-duplicate-service"
                        [disabled]="!permissionsLookup['manage-services-duplicate-service']"
                        (click)="editService(row, true)">
                        {{ 'duplicate' | myLbTranslate }}
                    </button>
                    <button
                        id="manage-services-delete-service"
                        [disabled]="!permissionsLookup['manage-services-delete-service']"
                        (click)="frontendAction('delete-Frontend', row)">
                        {{ 'delete' | myLbTranslate }}
                    </button>
                </app-action-dots>
            </ng-template>
        </app-column>
    </app-table>
</div>

<!-- Container used for all modals -->
<ng-container #modalContainer></ng-container>

<app-modal-wrapper
    modalTitle="manage_services_deleting"
    modalId="serviceProgressModal"
    modalWidth="450"
    [isOverlayClickable]="false"
    [showCloseButton]="false">
    <app-modal-body>
        <div class="progress-container">
            <div class="loader"></div>
            <div class="progress-box" [innerHTML]=progressMessage></div>
        </div>
    </app-modal-body>
</app-modal-wrapper>
