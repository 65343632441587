<div class="component-main">
    <div class="content-space-between">
        <h1>{{ (isBond ? 'net_int_config_bond_edit' : 'net_int_config_interface_edit') | myLbTranslate }}</h1>
    </div>
    <form [formGroup]="formGroup">
        <div class="form-group">
            <label for="name">{{ 'name' | myLbTranslate }}</label>
            <div class="form-content">
                <input type="text" name="name" id="name" formControlName="name"/>
                <app-validation-messages [control]="formGroup.controls['name']"></app-validation-messages>
                <app-help class="help-button" tag="edit-interface_name"></app-help>
            </div>
        </div>
        <ng-container *ngIf="isBond">
            <div class="form-group">
                <!--suppress XmlInvalidId -->
                <label for="bonds-modes">{{ 'mode' | myLbTranslate }}</label>
                <div class="form-content">
                    <app-dropdown
                        dropDownId="bonds-modes"
                        formControlName="modes"
                        [formGroup]="formGroup"
                        [content]="dropdown">
                    </app-dropdown>
                    <app-help class="help-button" tag="edit-interface_mode"></app-help>
                </div>
            </div>
        </ng-container>
        <div class="form-group">
            <label for="mtu">{{ 'net_int_config_mtu' | myLbTranslate }}</label>
            <div class="form-content">
                <input
                    id="mtu"
                    class="port"
                    type="number"
                    formControlName="mtu"
                    name="mtu"
                    min="68"
                    max="65535">
                <div><span>{{ 'bytes' | myLbTranslate }}</span></div>
                <app-validation-messages [control]="formGroup.controls['mtu']"></app-validation-messages>
                <app-help class="help-button" tag="edit-interface_mtu"></app-help>
            </div>
        </div>
        <div class="form-group">
            <div class="form-content">
                <input
                    name="onBoot"
                    formControlName="onBoot"
                    class="lb-checkbox"
                    type="checkbox"
                    id="onBoot">
                <label for="onBoot" class="lb-checkbox">
                    {{ 'net_int_config_onBoot' | myLbTranslate }}
                </label>
                <app-help class="help-button" tag="edit-interface_on-boot"></app-help>
            </div>
        </div>
        <div class="form-group">
            <div class="form-content">
                <input
                    name="offload"
                    formControlName="offload"
                    class="lb-checkbox"
                    type="checkbox"
                    id="offload">
                <label for="offload" class="lb-checkbox">
                    {{ 'net_int_config_offload' | myLbTranslate }}
                </label>
                <app-help class="help-button" tag="edit-interface_offload"></app-help>
            </div>
        </div>
        <label>{{ 'details' | myLbTranslate }}</label>
        <div *ngIf="bond.settings" class="details-holder">
            <p><strong>{{ 'interface' | myLbTranslate }}:</strong> {{ bond.settings.interfaceName }}</p>
            <p><strong>{{ 'mac_address' | myLbTranslate }}:</strong> {{ bond.settings.macAddress || 'n/a' }}</p>
            <p><strong>{{ 'speed' | myLbTranslate }}:</strong> {{ bond.settings.speed }}</p>
            <p><strong>{{ 'status' | myLbTranslate }}:</strong> {{ bond.settings.status }}</p>
        </div>
    </form>
</div>
<div class="component-footer">
    <button
        id="vlan-cancel"
        class="outline"
        [routerLink]="'../../'">
        {{ 'cancel' | myLbTranslate }}
    </button>
    <app-loading-button
        buttonId="vlan-submit"
        buttonClass="primary"
        buttonText="submit"
        [isDisabled]="formGroup.invalid || formGroup.pristine"
        [callBack]="submit.bind(this)">
    </app-loading-button>
</div>
