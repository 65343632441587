<app-modal-wrapper
    modalHeight="500"
    modalWidth="950"
    modalTitle="hanging_reals"
    modalId="hangingRealsModal">
    <app-modal-body>
        <div class="content-space-between">
            <p>{{ 'hanging_reals_reals_list' | myLbTranslate }}
        </div>
        <app-table
            id="hangingRealServer-table"
            [data]="tableRows"
            [selectedRows]="selectedRows"
            [multiSelectActions]="multiSelectActions"
            (selectedRowsOut)="onSelect($event)">
            <app-column [flexGrow]="0.75" prop="name" label="services_name"></app-column>
            <app-column [flexGrow]="1" prop="ip" label="ip"></app-column>
            <app-column [flexGrow]="0.25" prop="port" label="port"></app-column>
            <app-column [flexGrow]="0.25" prop="templateTypeFriendlyName" label="type"></app-column>
            <app-column
                [flexGrow]="1.25"
                prop="realServers"
                label="hanging_real_assign_backend"
                [cellTemplate]="selectBackend">
                <ng-template let-row let-index="index" #selectBackend>
                    <app-dropdown
                        dropDownId="two-factor-auth-countryCode"
                        [search]="true"
                        [displayAsInput]="true"
                        [dropdownWidth]="210"
                        [contentWidth]="200"
                        [noContentText]="'hanging_real_assign_no_backend_message' | myLbTranslate"
                        [content]="row.backends"
                        (keyOut)="setBackendId($event, row, index)">
                    </app-dropdown>
                </ng-template>
            </app-column>
            <app-column
                [flexGrow]="0.5"
                prop="actions"
                label=""
                cellAlignment="right"
                [cellTemplate]="hangingRealServerActions">
                <ng-template let-row let-index="index" #hangingRealServerActions>
                    <app-action-dots>
                        <button
                            id="hanging-reals-assign-to-backend"
                            [disabled]="!permissionsLookup['hanging-reals-assign-to-backend'] || !dropdownReferences[index]"
                            (click)="confirmAssignToBackend([row], index)">
                            {{ 'hanging_real_assign_backend' | myLbTranslate }}
                        </button>
                        <button
                            id="hanging-reals-delete-backend"
                            [disabled]="!permissionsLookup['hanging-reals-delete-backend']"
                            (click)="confirmDeleteRealServers([row])">
                            {{ 'delete' | myLbTranslate }}
                        </button>
                    </app-action-dots>
                </ng-template>
            </app-column>
        </app-table>
    </app-modal-body>
</app-modal-wrapper>

<app-modal-wrapper
    modalTitle="hanging_reals_deleting"
    modalId="realServerProgressModal"
    modalWidth="450"
    [isOverlayClickable]="false"
    [showCloseButton]="false">
    <app-modal-body>
        <div class="progress-container">
            <div class="loader"></div>
            <div class="progress-box" [innerHTML]=progressMessage></div>
        </div>
    </app-modal-body>
</app-modal-wrapper>
