<div [class.component-main]="!componentEmbedded" [hidden]="isEmbeddedPageOpen" [class.embedded]="componentEmbedded">
    <div class="content-space-between" [class.embedded]="componentEmbedded">
        <div class="content-left">
            <ng-container *ngIf="!componentEmbedded; else embeddedTitle">
                <h1>{{ 'ssl_certificate' | myLbTranslate }}</h1>
            </ng-container>
            <ng-template #embeddedTitle>
                <h2>{{ 'ssl_certificate' | myLbTranslate }}</h2>
            </ng-template>
        </div>
    </div>
    <app-tab-container
        id="certs-container"
        [vertical]="false"
        [widthPercent]="100">
        <app-tab id="certs" tabTitle="certs">
            <ng-template #certsButtons>
                <button
                    id="ssl-certificates-create-cert"
                    class='primary'
                    [disabled]="isButtonDisabled('ssl-certificates-create-csr')"
                    (click)="openEmbeddedPage('/cert/add', 'addEditCert')">
                    {{ 'certificate_add_cert' | myLbTranslate }}
                </button>
                <button
                    id="ssl-certificates-upload"
                    class='primary'
                    [disabled]="isButtonDisabled('ssl-certificates-upload')"
                    (click)="openEmbeddedPage('/cert/upload', 'uploadCert')">
                    {{ 'upload_pem_pfx' | myLbTranslate }}
                </button>
            </ng-template>
            <app-table
                [name]="'ssl-certs'"
                [data]="certsRows"
                [embeddedButtons]="certsButtons"
                [stickyHeader]="true">
                <app-column [flexGrow]="1.4" prop="label" label="label"></app-column>
                <app-column [flexGrow]="1" prop="commonName" label="certificate_common_name"></app-column>
                <app-column [flexGrow]="1" prop="validTo" label="valid_to"></app-column>
                <app-column [flexGrow]="0.75" prop="usage" label="usage" [customCellTemplate]="certsUsage">
                    <ng-template let-row #certsUsage>
                        <app-usage [usage]="row.usage" modalTitle="ssl_certificates_usage"></app-usage>
                    </ng-template>
                </app-column>
                <app-column [flexGrow]="0.5" prop="actions" label="" cellAlignment="right"
                            [customCellTemplate]="certsActions">
                    <ng-template let-row #certsActions>
                        <app-action-dots>
                            <!-- Use hidden instead of ngIf in action dots -->
                            <button
                                id="certs-edit-cert"
                                [disabled]="isButtonDisabled('ssl-certificates-update-pem-pfx')"
                                (click)="openEmbeddedPage('/cert/edit/' + row.id, 'addEditCert', row.id)">
                                {{ 'certificate_edit_cert' | myLbTranslate }}
                            </button>
                            <button
                                id="certs-delete-cert"
                                *ngIf="row.usage.length === 0"
                                [disabled]="isButtonDisabled('ssl-certificates-delete-cert')"
                                (click)="confirmDelete(row, 'cert')">
                                {{ 'delete' | myLbTranslate }}
                            </button>
                        </app-action-dots>
                    </ng-template>
                </app-column>
            </app-table>
        </app-tab>
        <app-tab id="csr" tabTitle="csr">
            <ng-container *ngIf="csrsRows">
                <ng-template #csrButtons>
                    <button
                        id="ssl-certs-create-csr"
                        class='primary'
                        [disabled]="isButtonDisabled('ssl-certificates-create-csr')"
                        (click)="openEmbeddedPage('/csr/new', 'addEditCSR')">
                        {{ 'certificate_create_csr' | myLbTranslate }}
                    </button>
                </ng-template>
                <!--                TODO: Need to figure out a way to align external buttons with table's search bar. -->
                <app-table
                    [name]="'csr-table'"
                    [data]="csrsRows"
                    [embeddedButtons]="csrButtons"
                    [stickyHeader]="true">
                    <app-column [flexGrow]="1" prop="label" label="label"></app-column>
                    <app-column
                        [flexGrow]="1" prop="commonName" label="certificate_common_name"></app-column>
                    <app-column [flexGrow]="1" prop="organisation" label="organization"></app-column>
                    <app-column
                        [flexGrow]="1" prop="subjectAlt" label="certificate_csrs_subject_alt"></app-column>
                    <app-column [flexGrow]="0.5" prop="actions" label="" cellAlignment="right"
                                [customCellTemplate]="csrActions">
                        <ng-template let-row #csrActions>
                            <app-action-dots>
                                <!-- Use hidden instead of ngIf in action dots -->
                                <button
                                    id="ssl-certificates-apply-csr"
                                    (click)="this.openEmbeddedPage('/cert/apply-csr/' + row.id, 'uploadCert', row.id);">
                                    {{ 'certificate_apply_csr' | myLbTranslate }}
                                </button>
                                <button
                                    id="ssl-certificates-view-csr"
                                    [disabled]="isButtonDisabled('ssl-certificates-edit-csr')"
                                    (click)="viewCSR(row.id)">
                                    {{ 'certificate_view_csr' | myLbTranslate }}
                                </button>
                                <button
                                    id="ssl-certificates-edit-csr"
                                    [disabled]="isButtonDisabled('ssl-certificates-edit-csr')"
                                    (click)="openEmbeddedPage('/csr/edit/' + row.id, 'addEditCSR', row.id)">
                                    {{ 'edit' | myLbTranslate }}
                                </button>
                                <!-- TODO: This will need to change once we can distinguish between CSRs and Self-signed certs -->
                                <button
                                    *ngIf="row.selfSigned"
                                    id="ssl-certificates-renew-self-signed"
                                    [disabled]="isButtonDisabled('ssl-certificates-renew-self-signed')"
                                    (click)="renewSelfSigned(row.id)">
                                    {{ 'renew' | myLbTranslate }}
                                </button>
                                <button
                                    id="csr-delete-cert"
                                    [disabled]="isButtonDisabled('ssl-certificates-delete-cert')"
                                    (click)="confirmDelete(row, 'csr')">
                                    {{ 'delete' | myLbTranslate }}
                                </button>
                            </app-action-dots>
                        </ng-template>
                    </app-column>
                </app-table>
            </ng-container>
        </app-tab>
    </app-tab-container>
</div>

<div *ngIf="componentEmbedded" class="component-footer embedded">
    <button id="ssl-certs-wizard-cancel" class="outline" (click)="callBack()">
        {{ 'cancel' | myLbTranslate }}
    </button>
</div>

<ng-container #addEditContainer></ng-container>

<app-modal-wrapper
    [isOverlayBlurred]="true"
    [isOverlayClickable]="false"
    modalId="view-csr-modal"
    modalTitle="csr"
    modalWidth="500"
    footerButtonAlignment="content-center"
    [showCloseButton]="true">
    <app-modal-body>
        <div class="text-area-wrap">
            <label>Certificate</label>
            <button appCopyClipboard [textToCopy]="csrData?.csr">
                {{ 'copy_output' | myLbTranslate }}
            </button>
            <textarea
                id="edit-csr-cert"
                title="{{ 'certificate' | myLbTranslate }}"
                rows="6"
                name="cert-content"
                class="textarea-disabled">{{ csrData?.csr }}
            </textarea>
        </div>
        <br>
        <div class="text-area-wrap">
            <label>Private Key</label>
            <button appCopyClipboard [textToCopy]="csrData?.privateKey">
                {{ 'copy_output' | myLbTranslate }}
            </button>
            <textarea
                id="edit-csr-privatekey"
                title="{{ 'certificate' | myLbTranslate }}"
                rows="6"
                name="cert-content"
                class="textarea-disabled">{{ csrData?.privateKey }}
            </textarea>
        </div>
    </app-modal-body>
</app-modal-wrapper>
