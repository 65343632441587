<h1>{{ 'gslb_add_global_name' | myLbTranslate }}</h1>
<form [formGroup]="serviceForm" [loaded]="!wizardService.isLoading">
    <div [formGroupName]="subStepEnum.GENERAL" [hidden]="getWizardSubStepState(subStepEnum.GENERAL)">
        <h2>{{ subStepEnum.GENERAL | myLbTranslate }}</h2>
        <section data-control-name="domain">
            <div *ngIf="!isInputHidden('domain')" class="form-group">
                <label for="domain{{templateIndex}}">{{ 'domain' | myLbTranslate }}*</label>
                <div class="form-content">
                    <ng-container *ngIf="namePrefix">
                        <span class="name-prefix">{{ namePrefix }}</span>
                    </ng-container>
                    <input
                        id="domain{{templateIndex}}"
                        formControlName="domain"
                        name="domain"
                        title="domain"
                        type="text">
                    <app-validation-messages [control]="serviceForm.get(subStepEnum.GENERAL + '.domain')">
                    </app-validation-messages>
                    <app-help class="help-button" tag="global-name_domain"></app-help>
                </div>
            </div>
        </section>
        <section data-control-name="timeToLive">
            <div *ngIf="!isInputHidden('timeToLive')" class="form-group">
                <label for="sub_domain_time_to_live{{templateIndex}}">{{ 'gslb_time_to_live' | myLbTranslate }}*</label>
                <div class="form-content">
                    <input
                        formControlName="timeToLive"
                        id="sub_domain_time_to_live"
                        name="time_to_live"
                        type="number"
                        [style.width.px]="inputWidth"
                        appInputValidator/>
                    <span> (seconds)</span>
                    <app-validation-messages [control]="serviceForm.get(subStepEnum.GENERAL + '.timeToLive')">
                    </app-validation-messages>
                    <app-help class="help-button" tag="global-name_time-to-live"></app-help>
                </div>
            </div>
        </section>
    </div>
</form>
