<div class="component-main">
    <div class="content-space-between">
        <h1>{{ 'manage' | myLbTranslate }} {{ frontendLabel }} {{ 'acl' | myLbTranslate }}</h1>
        <button
            id="acl-details-add-acl"
            class="primary"
            [disabled]="!permissionsLookup['manage-acls-add-acl']"
            (click)="addEditAcl()">
            {{ 'manage_acls_add' | myLbTranslate }}
        </button>
    </div>
    <app-search-rows
        [rows]="tableRows"
        [searchKeys]="['condition', 'type', 'redirectType', 'redirectLocation']"
        (searchResults)="updateTableRows($event)">
    </app-search-rows>
    <ng-container *ngIf="!refresh">
        <app-drag-n-drop-table
            [tableRows]="tableRows"
            type="acl"
            [actionDotsTemplate]="actionsTemplate"
            [isDisabled]="!permissionsLookup['manage-acl-update-config']"
            (orderedRows)="aclIdsNewOrder = $event">
        </app-drag-n-drop-table>
    </ng-container>
    <ng-template #actionsTemplate let-row>
        <app-action-dots>
            <button
                id="manage-acl-rules-edit"
                [disabled]="!permissionsLookup['manage-acl-rules-edit']"
                (click)="addEditAcl(row)">
                {{ 'edit' | myLbTranslate }}
            </button>
            <button
                id="manage-acl-rules-delete"
                [disabled]="!permissionsLookup['manage-acl-rules-delete']"
                (click)="confirmDeleteACL(row.id)">
                {{ 'delete' | myLbTranslate }}
            </button>
        </app-action-dots>
    </ng-template>
</div>
<div class="component-footer">
    <button
        id="manage-acl-rules-reset"
        class="outline"
        [disabled]="aclIdsNewOrder === aclIdsOriginalOrder || !permissionsLookup['manage-acl-rules-edit']"
        (click)="resetAclOrder()">
        {{ (aclRules?.length <= 1 ? 'cancel' : 'reset') | myLbTranslate }}
    </button>
    <app-loading-button
        buttonId="manage-acl-update-config"
        buttonClass="primary"
        buttonText="submit"
        [isDisabled]="aclIdsNewOrder === aclIdsOriginalOrder || !permissionsLookup['manage-acl-update-config']"
        [callBack]="submitAclOrder.bind(this)">
    </app-loading-button>
</div>
