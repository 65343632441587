<ng-template #bell>
    <span class="wrapper">
        <span class="lb-badge">
            <span *ngIf="totalNotifications > 0" class="counter">
                {{ totalNotifications > 9 ? '9+' : totalNotifications }}
            </span>
        </span>
    </span>
</ng-template>

<app-dropdown
    dropDownId="notifications-bell"
    selectedTitle="{{ 'notifications' | myLbTranslate }}"
    [template]="bell"
    [hideArrow]="true"
    titleAlign="right"
    [dropdownWidth]="23"
    [contentWidth]="320"
    [forceOpen]="forceOpen"
    (dropdownOpened)="populateDropdownRows()">
    <div class="drop-container-html">
        <div class="notification-wrapper">
            <ng-container *ngFor="let notification of htmlRows">
                <div class="notification" [class.has-link]="notification.url">
                    <img
                        class="level{{notification.level}}"
                        src="../../../../../assets/shared/icons/notification-solid-checked-circle.svg"
                        alt="Exclamation icon"/>
                    <div class="message-wrapper">
                        <span
                            class="message"
                            [title]="notification.formattedMessage"
                            [class.has-link]="notification.url"
                            [innerHTML]="notification.message"
                            (click)="navigate(notification.url)">
                        </span>
                        <p [class.has-link]="notification.url">
                            <ng-container *ngIf="notification.duration === 'date_just_now' else fullMessage">
                                {{ notification.duration | myLbTranslate }}
                            </ng-container>
                            <ng-template #fullMessage>
                                {{ notification.number }} {{ notification.duration | myLbTranslate }} {{ 'ago' | myLbTranslate }}
                            </ng-template>
                        </p>
                    </div>
                    <div class="notification-close" (click)="clearSelectedNotification(notification.id)">
                        <img src="assets/shared/icons/close-cross.svg" alt="close modal icon"/>
                    </div>
                </div>
            </ng-container>
        </div>
        <div [routerLink]="navigationService.getEnduranceUrl('/notifications')" class="notification-footer">
            <button
                type="button"
                class="button-as-link">
                {{ 'notifications_see_all' | myLbTranslate }}
            </button>
        </div>
    </div>
</app-dropdown>
