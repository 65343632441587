<div id="page-wrapper">                 <!-- This is correct but because this component -->
    <div class="component-main">        <!-- doesn't have its own menu/ parent component-->
        <div class="widgetContainer">   <!-- they all need to live here! -->
            <app-widget-sys-metrics
                id="widget-metrics-cpu"
                class="widget-item col-span-1 row-span-2"
                [metric]="MetricsEnum.CPU"
                [responseData]="responseData">
            </app-widget-sys-metrics>
            <app-widget-sys-metrics
                id="widget-metrics-memory"
                class="widget-item col-span-1 row-span-2"
                [metric]="MetricsEnum.MEMORY"
                [responseData]="responseData">
            </app-widget-sys-metrics>
            <app-widget-sys-metrics
                id="widget-metrics-disks"
                class="widget-item col-span-1 row-span-2"
                [metric]="MetricsEnum.DISKS"
                [responseData]="responseData">
            </app-widget-sys-metrics>
            <app-widget-sys-metrics
                id="widget-metrics-connections"
                class="widget-item col-span-1 row-span-2"
                [metric]="MetricsEnum.CONNECTIONS"
                [responseData]="responseData">
            </app-widget-sys-metrics>
            <app-lb-widget-pulse-ha class="widget-item col-span-2 row-span-3"></app-lb-widget-pulse-ha>
            <app-lb-widget-virtual-services class="widget-item col-span-2 row-span-3"></app-lb-widget-virtual-services>
            <app-lb-widget-license-details class="widget-item col-span-2 row-span-2"></app-lb-widget-license-details>
            <app-lb-widget-sysupdates class="widget-item col-span-1 row-span-2"></app-lb-widget-sysupdates>
            <app-lb-widget-alerts class="widget-item col-span-1 row-span-2"></app-lb-widget-alerts>
            <app-widget-sys-metrics
                id="widget-metrics-network-graph"
                class="widget-item col-span-4 row-span-2"
                [metric]="MetricsEnum.NETWORKGRAPH"
                [responseData]="responseData">
            </app-widget-sys-metrics>
            <app-widget-sys-metrics
                id="widget-metrics-la-graph"
                class="widget-item col-span-4 row-span-2"
                [metric]="MetricsEnum.LOADAVERAGEGRAPH"
                [responseData]="responseData">
            </app-widget-sys-metrics>
            <app-widget-sys-metrics
                id="widget-metrics-mem-graph"
                class="widget-item col-span-4 row-span-2"
                [metric]="MetricsEnum.MEMORYGRAPH"
                [responseData]="responseData">
            </app-widget-sys-metrics>
        </div>
    </div>
</div>
