<div class="component-main">
    <div class="content">
        <h1>{{ pageTitle | myLbTranslate }}</h1>
    </div>
    <ng-container *ngIf="isLoading else displayData">
        <div class="page-loader"></div>
    </ng-container>
    <ng-template #displayData>
        <ng-container *ngIf="moduleConfigs.length">
            <app-tab-container
                [id]="'view-config-' + moduleId + '-tab-container'"
                [activeTabId]="activeTab"
                (eventEmitter)="loadConfigById($event.id)">
                <ng-container *ngFor="let config of moduleConfigs">
                    <app-tab [tabTitle]="config.name.toLowerCase()" [id]="config.config">
                        <app-display-code
                            [data]="configData"
                            errorMessage="view_config_{{moduleId}}_message">
                        </app-display-code>
                    </app-tab>
                </ng-container>
            </app-tab-container>
        </ng-container>
    </ng-template>
</div>
