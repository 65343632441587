<app-modal-wrapper
    modalWidth="400"
    modalId="noIPGroupsModal"
    modalTitle="attention">
    <app-modal-body>
        <span>{{ 'ip_no_ip_config' | myLbTranslate }}</span>
    </app-modal-body>
    <app-modal-footer>
        <button class="outline" (click)="modalService.close('noIPGroupsModal')">
            {{ 'cancel' | myLbTranslate }}
        </button>
        <button
            class="primary"
            (click)="linkToGroups()">
            {{ 'ip_go_to_groups' | myLbTranslate }}
        </button>
    </app-modal-footer>
</app-modal-wrapper>

<div class="component-main">
    <div class="content-space-between">
        <h1>{{ (editId ? 'static_ip_edit' : 'static_ip_add') | myLbTranslate }}</h1>
    </div>
    <form [formGroup]="ipForm">
        <div class="form-group">
            <label for="ip">{{ 'ip' | myLbTranslate }}</label>
            <div class="form-content">
                <input type="text" size="25" name="ip" id="ip" formControlName="ip"/>
                <app-validation-messages [control]="ipForm.controls['ip']"></app-validation-messages>
                <app-help class="help-button" tag="add-edit-static-ip_ip"></app-help>
            </div>
        </div>
        <div class="form-group">
            <!--suppress XmlInvalidId -->
            <label for="floating-ip">{{ 'group' | myLbTranslate }}</label>
            <div class="form-content">
                <app-dropdown
                    dropDownId="floating-ip"
                    [formGroup]="ipForm"
                    formControlName="group"
                    [content]="dropdownGroups">
                </app-dropdown>
                <app-help class="help-button" tag="add-edit-static-ip_group"></app-help>
            </div>
        </div>
    </form>
</div>
<div class="component-footer">
    <button
        id="static-ip-cancel"
        class="outline"
        routerLink="../../">
        {{ 'cancel' | myLbTranslate }}
    </button>
    <app-loading-button
        buttonId="static-ip-submit"
        buttonClass="primary"
        [buttonText]="editId ? 'update': 'add'"
        [isDisabled]="ipForm.invalid || ipForm.pristine"
        [callBack]="submitSipCheck.bind(this)">
    </app-loading-button>
</div>
