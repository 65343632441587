<ng-container *ngIf="isFreeTier">
    <app-alternative-state-page
        headerIcon="assets/centralisedManagement/icons/subscription_required.svg"
        header="schedules_upgrade_title"
        messageHtml="{{ 'schedules_upgrade_message' | myLbTranslate }}"
        imagePath="assets/centralisedManagement/empty-state/schedules.svg"
        [redirectToButton]="true">
    </app-alternative-state-page>
</ng-container>
<ng-container *ngIf="!isFreeTier && isLoading else pageContent">
    <div class="loader-wrapper">
        <img
            class="loader"
            ngSrc="assets/shared/icons/loading-spinner-primary.svg"
            height="40"
            width="40"
            alt="loading spinner"/>
    </div>
</ng-container>
<ng-template #pageContent>
    <div class="component-main">
        <div class="content">
            <h1 id="component-title">{{ (storedId && !storedProductType ? 'schedule_edit' : 'schedule_create') | myLbTranslate }}</h1>
        </div>
        <form [formGroup]="schedulerForm" [disableFocus]="true">
            <section *ngIf="step === 1" formGroupName="step1" class="step-wrapper">
                <ng-container *ngIf="!storedId && !storedProductType">
                    <div class="form-group">
                        <!--suppress XmlInvalidId -->
                        <label for="scheduler-product-type">{{ 'product_type' | myLbTranslate }}</label>
                        <div class="form-content">
                            <app-dropdown
                                dropDownId="scheduler-product-type"
                                [formGroup]="getFormGroup('step1')"
                                formControlName="productType"
                                [content]="dropdown.product">
                            </app-dropdown>
                            <app-help class="help-button" tag="add-schedule_product-type"></app-help>
                        </div>
                    </div>
                </ng-container>
                <ng-container *ngIf="storedId">
                    <div class="form-group">
                        <label for="scheduler-product-name">{{ 'product_name' | myLbTranslate }}</label>
                        <div class="form-content">
                            <input
                                id="scheduler-product-name"
                                formControlName="productName"
                                title="product name"
                                type="text"
                                min="00:00"
                                max="23:59">
                        </div>
                    </div>
                </ng-container>
                <ng-container *ngIf="storedId && !storedProductType else scheduleTypeDropdown">
                    <div class="form-group">
                        <label for="schedule-type">{{ 'schedule_type' | myLbTranslate }}</label>
                        <div class="form-content">
                            <input id="schedule-type" formControlName="scheduleType" title="schedule type" type="text">
                        </div>
                    </div>
                </ng-container>
                <ng-template #scheduleTypeDropdown>
                    <div class="form-group">
                        <!--suppress XmlInvalidId -->
                        <label for="scheduler-schedule-type">{{ 'schedule_type' | myLbTranslate }}</label>
                        <div class="form-content">
                            <app-dropdown
                                dropDownId="scheduler-schedule-type"
                                [formGroup]="getFormGroup('step1')"
                                formControlName="scheduleType"
                                [hiddenContentKeys]="dropdownHiddenContentKeys"
                                [content]="dropdown.schedule">
                            </app-dropdown>
                            <app-help class="help-button" tag="add-schedule_schedule-type"></app-help>
                        </div>
                    </div>
                </ng-template>
                <div class="form-group">
                    <div class="form-row">
                        <div class="form-group">
                            <label for="scheduler-date">{{ 'date' | myLbTranslate }}</label>
                            <div class="form-content">
                                <input id="scheduler-date" type="date" formControlName="date"/>
                                <app-validation-messages [control]="schedulerForm.get('step1.date')">
                                </app-validation-messages>
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="scheduler-time">
                                {{ 'time' | myLbTranslate }}
                                <span class="note" title="{{'scheduled_date_time_utc' | myLbTranslate}}">(UTC)</span>
                            </label>
                            <div class="form-content">
                                <input id="scheduler-time" title="Time" type="time" name="time" formControlName="time">
                                <app-validation-messages [control]="schedulerForm.get('step1.time')">
                                </app-validation-messages>
                            </div>
                            <span class="local-time note" title="{{localTime}}">
                            {{ 'scheduler_local_time' |myLbTranslate }} {{ localTime }}
                        </span>
                        </div>
                        <div class="form-group" style="width: 130px">
                            <ng-container *ngIf="schedulerForm.get('step1.scheduleType').value === 'backup'">
                                <!--suppress XmlInvalidId -->
                                <label for="scheduler-occurrence">{{ 'occurrence' | myLbTranslate | titlecase }}</label>
                                <div class="form-content">
                                    <app-dropdown
                                        dropDownId="scheduler-occurrence"
                                        [dropdownWidth]="144"
                                        [formGroup]="getFormGroup('step1')"
                                        formControlName="occurrence"
                                        [content]="dropdown.occurrence">
                                    </app-dropdown>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </section>
        </form>
        <app-table
            name="scheduler-adcs-list"
            *ngIf="step === 2"
            [data]="tableRows"
            [allowMultiSelect]="true"
            [showPaginator]="false"
            [stickyHeader]="true"
            [showFooter]="true"
            [selectedRows]="selectedRowObject"
            (selectedRowsOut)="onSelect($event)">
            <app-column [flexGrow]="1" prop="label" label="name"></app-column>
            <app-column [flexGrow]="1" prop="ipAddress" label="ip_address"></app-column>
            <app-column [flexGrow]="1" prop="port" label="port"></app-column>
            <ng-container *ngIf="storedScheduleType === 'backup'">
                <app-column
                    [flexGrow]="0.5"
                    prop="backupsUsed"
                    label="backups_used"
                    [customCellTemplate]="backupDetails">
                    <ng-template let-row #backupDetails>
                        {{ getBackupDetails(row.id) }}
                    </ng-template>
                </app-column>
            </ng-container>
            <ng-container *ngIf="storedScheduleType === 'update'">
                <app-column [flexGrow]="1" prop="version" label="version" [customCellTemplate]="version">
                    <ng-template let-row #version>
                        {{ row.version }}
                        <span
                            *ngIf="row.updateStatus === 'available'"
                            class="update-available">
                        {{ row.updateVersion }}
                    </span>
                    </ng-template>
                </app-column>
            </ng-container>
        </app-table>
    </div>
</ng-template>
<div class="component-footer">
    <button id="scheduler-cancel" class="outline" (click)="navigate('cm/scheduler/schedules')">
        {{ 'cancel' | myLbTranslate }}
    </button>
    <ng-container *ngIf="step === 1 && !storedId">
        <button
            id="scheduler-next"
            class="primary"
            [disabled]="schedulerForm.get('step1').invalid"
            (click)="nextStep()">
            {{ 'next' | myLbTranslate }}
        </button>
    </ng-container>
    <ng-container *ngIf="step === 2">
        <button id="scheduler-back" class="primary" (click)="backStep()">
            {{ 'back' | myLbTranslate }}
        </button>
    </ng-container>
    <ng-container *ngIf="step === 2 || (storedId && storedProductType)">
        <button
            id="scheduler-save"
            class="primary"
            [disabled]="!permissionsLookup['scheduler-save'] || schedulerForm.invalid"
            (click)="submitSchedule()">
            {{ 'scheduler_save_schedule' | myLbTranslate }}
        </button>
    </ng-container>
    <ng-container *ngIf="storedId && !storedProductType">
        <button
            id="scheduler-delete"
            class="primary"
            [disabled]="!permissionsLookup['scheduler-delete']"
            (click)="confirmDeleteSchedule(schedulerForm.get('step1.id').value)">
            {{ 'schedule_delete' | myLbTranslate }}
        </button>
        <button
            id="scheduler-update"
            class="primary"
            [disabled]="!permissionsLookup['scheduler-update'] || schedulerForm.invalid || schedulerForm.pristine"
            (click)="submitSchedule(schedulerForm.get('step1.id').value)">
            {{ 'schedule_update' | myLbTranslate }}
        </button>
    </ng-container>
</div>
