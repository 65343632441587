<div class="wizard-wrapper">
    <ng-container *ngIf="showTemplateSelector; else wizard">
        <app-lb-template-selector
            [templates]="availableTemplates"
            [cancelButtonText]="cancelButtonText"
            (templateSelected)="selectTemplate()">
        </app-lb-template-selector>
    </ng-container>
    <ng-template #wizard>
        <div class="wizard-left">
            <div class="left-header-block">
                <h1 class="header">{{ wizardTitle }}</h1>
                <div *ngIf="showWizardModeDropdown()" class="sub-header">
                    <app-dropdown
                        dropDownId="wizard-mode"
                        [displayAsInput]="true"
                        [selectedKey]="wizardSelectedMode"
                        [content]="dropdown.wizardMode"
                        [disabled]="!wizardModeState"
                        (keyOut)="setWizardMode($event)">
                    </app-dropdown>
                </div>
            </div>
            <div *ngIf="showProgressBar" class="left-list">
                <div class="step-wrapper">
                    <div class="steps">
                        <app-lb-accordion *ngIf="wizardStepsReference?.length">
                            <div
                                #parentStep
                                *ngFor="let step of wizardStepsReference; let stepIndex = index"
                                [id]="step.htmlId"
                                [hidden]="step.hidden"
                                [class.active]="step.isActive"
                                [class.inactive]="!step.isActive && !step.isComplete"
                                [class.complete]="step.isComplete"
                                class="step-header-wrapper">
                                <ng-container *ngIf="step.isVisible">
                                    <app-lb-accordion-group
                                        [title]="wizardStepsReference.length === 1
                                        ? step.title
                                        : ('step' | myLbTranslate) + ' ' + getVisibleStepIndex(step) + ' ' + step.title"
                                        [showArrow]="false"
                                        [open]="step.isActive"
                                        [showComplete]="step.isComplete"
                                        [showCursor]="wizardStepsReference.length > 1"
                                        [fullWidth]="true"
                                        [enableToggle]="step.isComplete"
                                        (toggle)="step.id === 'templateSelection'
                                            ? wizardService.goToTemplateSelector(step)
                                            : wizardService.goToStep(stepIndex, 'last')">
                                        <div class="sub-step-wrapper">
                                            <ng-container *ngIf="step.subSteps.length > 1">
                                                <ng-container *ngFor="let subStep of step.subSteps;">
                                                    <ng-container *ngIf="subStep.isVisible">
                                                        <div
                                                            *ngIf="subStep.isVisible"
                                                            [id]="subStep.htmlId"
                                                            [class.active]="subStep.isActive"
                                                            [class.inactive]="!subStep.isActive && !subStep.isComplete"
                                                            [class.complete]="subStep.isComplete"
                                                            [class.errored]="subStep.isErrored"
                                                            class="sub-step-header-wrapper"
                                                            (click)="wizardService.goToSubStep(step, subStep)">
                                                            <div class="progress-container">
                                                                <div
                                                                    class="bullet-point"
                                                                    [class.active]="subStep.isActive"
                                                                    [class.errored]="subStep.isErrored"
                                                                    [class.complete]="subStep.isComplete"></div>
                                                                <div class="progress-bar"></div>
                                                            </div>
                                                            <div
                                                                class="header-text"
                                                                [class.active]="subStep.isActive"
                                                                [class.errored]="subStep.isErrored"
                                                                [class.complete]="subStep.isComplete">
                                                                {{ subStep.title | myLbTranslate }}
                                                            </div>
                                                        </div>
                                                    </ng-container>
                                                </ng-container>
                                            </ng-container>
                                        </div>
                                    </app-lb-accordion-group>
                                </ng-container>
                            </div>
                        </app-lb-accordion>
                    </div>
                </div>
            </div>
            <div class="left-footer">
                <i class="fas fa-chevron-left"></i>
                <button class="button-as-link" type="button" (click)="cancelSubmission()">
                    {{ cancelButtonText }}
                </button>
            </div>
        </div>
        <div class="wizard-content">
            <div class="wizard-step">
                <ng-container *ngIf="wizardService.isLoading; else content">
                    <div class="loader-wrapper">
                        <div class="dot-spin"></div>
                        <br>
                        <p>{{ 'wizard_loading' | myLbTranslate }}</p>
                    </div>
                </ng-container>
                <ng-template #content>
                    <div class="wizard-scroll" [class.has-error]="errorNotification">
                        <ng-content></ng-content>
                    </div>
                    <div
                        *ngIf="errorNotification && !wizardService.submissionActive"
                        class="errors-section {{errorNotification.class}}">
                        <div class="left-message">
                            <img
                                ngSrc="../../../../../../assets/shared/icons/notification-solid-exclamation-circle.svg"
                                alt="Exclamation icon"
                                height="26"
                                width="25"/>
                            <span *ngIf="errorNotification.message; else defaultMessage">
                                {{ errorNotification.message }}
                            </span>
                            <ng-template #defaultMessage>
                                <span class="default-message">Error submitting a service</span>
                            </ng-template>
                            <ng-container *ngIf="errorNotification.callBack">
                                <button class="notification" (click)="errorNotification.callBack.function()">
                                    {{ errorNotification.callBack.text | myLbTranslate }}
                                </button>
                            </ng-container>
                        </div>
                        <button class="notification" (click)="closeErrorNotification(errorNotification.id)">
                            {{ (errorNotification.class === 'warning' ? 'notifications_clear_warning' : 'notifications_clear_error') | myLbTranslate }}
                        </button>
                    </div>
                </ng-template>
            </div>
            <div class="wizard-footer" [hidden]="wizardService.disableNavigation">
                <div class="footer-left">
                    <ng-container *ngIf="!wizardService.submissionActive">
                        <button
                            *ngIf="showBackButton"
                            class="outline"
                            id="wizard-back"
                            type="button"
                            [disabled]="wizardService.isFirstStep || wizardService.isLoading || errorNotification || wizardService.disableNavigation"
                            (click)="previous()">
                            {{ 'back' | myLbTranslate }}
                        </button>
                        <button
                            *ngIf="!submissionActive && !wizardService.isLastStep"
                            [class.fixed-width]="errorNotification && errorNotification.message"
                            id="wizard-next"
                            class="primary"
                            [disabled]="buttonIsDisabled || wizardService.isLastStep"
                            (click)="next()">{{ 'next' | myLbTranslate }}
                        </button>
                        <!-- Can't use callback here because the finish function is a callback and isn't observable -->
                        <app-loading-button
                            *ngIf="showFinishButton()"
                            buttonId="wizard-submit"
                            buttonClass="primary"
                            buttonText="submit"
                            [hasMultipleApiCalls]="true"
                            [isDisabled]="buttonIsDisabled || wizardService.isLoading"
                            (click)="finish()">
                        </app-loading-button>
                        <ng-template #forceSubmit>
                            <app-loading-button
                                buttonId="wizard-force-submit"
                                buttonClass="primary"
                                buttonText="submit"
                                buttonIconLocation="../../../../../../assets/shared/icons/notification-outline-exclamation-triangle.svg"
                                buttonIconPosition="left"
                                [hasMultipleApiCalls]="true"
                                [isDisabled]="buttonIsDisabled || wizardService.isLoading"
                                (click)="finish()">
                            </app-loading-button>
                        </ng-template>
                        <app-tooltip
                            *ngIf="showForceFinishButton()"
                            [triggerTemplate]="forceSubmit"
                            forceRight="true"
                            [startDelay]="250">
                            <div
                                class="submit-tooltip-content">{{ 'wizard_submit_button_message' | myLbTranslate }}
                            </div>
                        </app-tooltip>
                        <button
                            *ngIf="wizardService.isLastStep && showSaveButton && !errorNotification"
                            id="wizard-save"
                            class="primary"
                            type="button"
                            [disabled]="buttonIsDisabled || wizardService.isLoading"
                            (click)="saveTemplateToFile()">
                            {{ 'wizard_save_template' | myLbTranslate }}
                        </button>
                    </ng-container>
                    <ng-container *ngIf="wizardService.submissionActive && wizardService.wizardProgressMessage">
                        <div class="progress-indicator">
                            <div class="loading">
                                <div class="dot-spin"></div>
                            </div>
                            <span>{{ wizardService.wizardProgressMessage }}</span>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>
    </ng-template>
</div>
