<div class="component-main">
    <div class="content-space-between">
        <h1>{{ 'health_checks' | myLbTranslate }}</h1>
        <div class="button-container">
            <button
                id="healthchecks-new"
                class="primary"
                [disabled]="!permissionsLookup['healthchecks-new']"
                [routerLink]="['add']">
                {{ 'health_check_add' | myLbTranslate | titlecase }}
            </button>
        </div>
    </div>

    <app-table id="healthChecks" [data]="tableRows">
        <app-column [flexGrow]="1" prop="name" label="name"></app-column>
        <app-column [flexGrow]="1.5" prop="description" label="description"></app-column>
        <app-column [flexGrow]="1" prop="services" label="services"></app-column>
        <app-column [flexGrow]="0.75" prop="builtin" label="health_check_created_by" [cellTemplate]="hcType">
            <ng-template let-row #hcType>
                <ng-container *ngIf="row.builtin else custom;">
                    <span>{{ 'default' | myLbTranslate }}</span>
                </ng-container>
                <ng-template #custom>
                    <span>{{ 'user' | myLbTranslate }}</span>
                </ng-template>
            </ng-template>
        </app-column>
        <app-column [flexGrow]="0.75" prop="usage" label="usage" [cellTemplate]="hcUsage">
            <ng-template let-row #hcUsage>
                <app-usage [usage]="row.usage" modalTitle="health_checks_usage"></app-usage>
            </ng-template>
        </app-column>
        <app-column [flexGrow]="0.5" prop="actions" label="" cellAlignment="right" [cellTemplate]="hcActions">
            <ng-template let-row #hcActions>
                <app-action-dots *ngIf="!row.builtin">
                    <button
                        id="healthchecks-edit"
                        [disabled]="!permissionsLookup['healthchecks-edit']"
                        [routerLink]="['edit/' + row.id]">
                        {{ 'edit' | myLbTranslate }}
                    </button>
                    <button
                        id="healthchecks-delete"
                        [disabled]="!permissionsLookup['healthchecks-delete'] || row.usage?.length"
                        (click)="confirmDelete(row.id)">
                        {{ 'delete' | myLbTranslate }}
                    </button>
                </app-action-dots>
                <app-action-dots *ngIf="row.builtin" class="builtin">
                    <button id="healthchecks-view" [routerLink]="['view/' + row.id]">
                        {{ 'view' | myLbTranslate }}
                    </button>
                    <button
                        id="healthchecks-duplicate"
                        [disabled]="!permissionsLookup['healthchecks-duplicate']"
                        [routerLink]="['duplicate/' + row.id]">
                        {{ 'duplicate' | myLbTranslate }}
                    </button>
                </app-action-dots>
            </ng-template>
        </app-column>
    </app-table>
</div>
