<ng-container *ngIf="!usage?.length; else toolTip">
    <span>{{ 'usage_not_in_use' | myLbTranslate }}</span>
</ng-container>
<ng-template #toolTip>
    <app-tooltip
        [width]="432"
        [trigger]="getUsageMessage(usage)">
        <table class="lb-small-table">
            <thead>
            <tr>
                <th>{{ 'service' | myLbTranslate }}</th>
                <th>{{ 'section' | myLbTranslate }}</th>
                <th>{{ 'name' | myLbTranslate }}</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let item of usage | slice:0:5;">
                <td>
                    <ng-container *ngIf="item.link else text">
                        <button class="button-as-link white" (click)="linkToServiceDetails(item.link)">
                            {{ item.serviceFullName }}
                        </button>
                    </ng-container>
                    <ng-template #text>{{ item.serviceFullName }}</ng-template>
                </td>
                <td>{{ item.sectionFullName }}</td>
                <td>{{ item.serviceLabel }}</td>
            </tr>
            </tbody>
        </table>
        <div *ngIf="usage.length > 4" class="content-center usage">
            <button class="tooltip-button" (click)="showUsageModal()">
                {{ 'more' | myLbTranslate }}
            </button>
        </div>
    </app-tooltip>
</ng-template>

<ng-container *ngIf="usage?.length">
    <app-modal-wrapper modalWidth="600" [modalTitle]="modalTitle" modalId="usageModal">
        <app-modal-body>
            <h2 *ngIf="modalSubTitle">{{ modalSubTitle | myLbTranslate }}: {{ modalIp }}</h2>
            <app-table
                [name]="'usage'"
                [data]="usage">
                <app-column
                    [flexGrow]="0.75"
                    prop="serviceFullName"
                    label="service">
                </app-column>
                <app-column [flexGrow]="1" prop="sectionFullName" label="label"
                            [customCellTemplate]="serviceFullName">
                    <ng-template #serviceFullName let-row>
                        {{ row.sectionFullName }}
                    </ng-template>
                </app-column>
                <app-column [flexGrow]="1" prop="serviceLabel" label="label" [customCellTemplate]="serviceLabel">
                    <ng-template let-row #serviceLabel>
                        <ng-container *ngIf="row.link else text">
                            <button class="button-as-link" (click)="linkToServiceDetails(row.link)">
                                {{ row.serviceLabel }}
                            </button>
                        </ng-container>
                        <ng-template #text>{{ row.serviceLabel }}</ng-template>
                    </ng-template>
                </app-column>
            </app-table>
        </app-modal-body>
    </app-modal-wrapper>
</ng-container>
