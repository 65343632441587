<div [class.component-main]="!embedded">
    <div class="content-space-between">
        <h1>{{ pageTitle | myLbTranslate }}</h1>
    </div>
    <form [formGroup]="pageForm" [class.disabled]="pageForm.disabled">
        <div class="form-group">
            <label for="fallback-name">{{ 'name' | myLbTranslate }}*</label>
            <div class="form-content">
                <input
                    title="{{'name' | myLbTranslate }}"
                    id="fallback-name"
                    type="text"
                    name="name"
                    formControlName="name"/>
                <app-validation-messages [control]="pageForm.get('name')"></app-validation-messages>
                <app-help class="help-button" tag="add-edit-fallback-pages_name"></app-help>
            </div>
        </div>
        <div class="form-group">
            <!--suppress XmlInvalidId -->
            <label for="fallback-cert">{{ 'certificate' | myLbTranslate }}</label>
            <div class="form-content">
                <app-dropdown
                    dropDownId="fallback-cert"
                    [formGroup]="pageForm"
                    formControlName="certId"
                    [content]="dropdown.certificates">
                </app-dropdown>
                <app-help class="help-button" tag="add-edit-fallback-pages_certificate"></app-help>
            </div>
        </div>
        <div class="form-group">
            <!--suppress XmlInvalidId -->
            <label for="fallback-type">{{ 'type' | myLbTranslate }}</label>
            <div class="form-content">
                <app-dropdown
                    dropDownId="fallback-type"
                    [formGroup]="pageForm"
                    formControlName="type"
                    [content]="dropdown.types">
                </app-dropdown>
                <app-help class="help-button" tag="add-edit-fallback-pages_type"></app-help>
            </div>
        </div>
        <div class="form-group" *ngIf="pageForm.get('type').value === 'external'">
            <label for="fallback-ip">{{ 'ip' | myLbTranslate }}*</label>
            <div class="form-content">
                <input
                    id="fallback-ip"
                    title="{{'ip' | myLbTranslate }}*"
                    type="text"
                    name="ip"
                    formControlName="ip"/>
                <app-validation-messages [control]="pageForm.get('ip')"></app-validation-messages>
                <app-help class="help-button" tag="add-edit-fallback-pages_ip"></app-help>
            </div>
        </div>
        <div class="form-group" *ngIf="pageForm.get('type').value === 'external'">
            <label for="fallback-port">{{ 'port' | myLbTranslate }}*</label>
            <div class="form-content">
                <input
                    id="fallback-port"
                    title="{{'port' | myLbTranslate }}"
                    type="number"
                    name="port"
                    formControlName="port"
                    [min]="1"
                    [max]="65535"
                />
                <app-validation-messages [control]="pageForm.get('port')"></app-validation-messages>
                <app-help class="help-button" tag="add-edit-fallback-pages_port"></app-help>
            </div>
        </div>
        <div class="form-group content-top" *ngIf="pageForm.get('type').value === 'internal'">
            <div class="page-content-label">
                <label>{{ 'fallback_pages_page_content' | myLbTranslate }}</label>
                <app-help class="help-button" tag="add-edit-fallback-pages_page-content"></app-help>
            </div>
            <div class="form-content">
                <div class="code-area" [class.error]="pageForm.get('pageContent').invalid">
                    <div class="line-numbers" #lines>
                        <span *ngFor="let number of lineNumbers">{{ number }}</span>
                    </div>
                    <textarea
                        #htmlTextAreaElement
                        id="fallback-page-content"
                        title="{{'fallback_pages_page_content' | myLbTranslate }}*"
                        rows="{{numberOfLines}}"
                        name="pageContent"
                        formControlName="pageContent"
                        wrap="off"
                        (keydown)="changeRowLength($event)">
                    </textarea>
                </div>
                <app-validation-messages [control]="pageForm.get('pageContent')"></app-validation-messages>
            </div>
        </div>
    </form>
</div>
<div class="component-footer" [class.embedded]="embedded">
    <button id="fallback-cancel-submit" class="outline" (click)="cancel()">
        {{ 'cancel' | myLbTranslate }}
    </button>
    <app-loading-button
        buttonId="fallback-submit"
        [isDisabled]="pageForm.invalid || pageForm.pristine || !permissionsLookup['fallback-submit']"
        buttonClass="primary"
        [buttonText]="pageForm.get('id').value ? 'update' : 'add'"
        [callBack]="submit.bind(this)">
    </app-loading-button>
</div>
