<div class="component-main">
    <div class="content-space-between">
        <h1>{{ 'backup_restore' | myLbTranslate }}</h1>
        <div>
            <button
                id="backupRestore-upload-backup"
                class="primary"
                [disabled]="isButtonDisabled('backup-restore-upload-backup')"
                routerLink="upload">
                {{ 'backup_restore_upload_backup' | myLbTranslate }}
            </button>
            <app-loading-button
                buttonId="backupRestore-backup"
                buttonClass="primary"
                buttonText="backup"
                [isDisabled]="isButtonDisabled('backup-restore-download-backup')"
                [callBack]="backup.bind(this)">
            </app-loading-button>
        </div>
    </div>
    <h2>{{ 'backup_restore_uploaded_backups' | myLbTranslate }}</h2>
    <app-table [name]="'backup-uploads-table'" [data]="uploadBackUpTable">
        <app-column [flexGrow]="1" prop="viewDateTime" label="date_time"></app-column>
        <app-column
            [flexGrow]="0.5"
            prop="actions"
            label=""
            [cellAlignment]="'right'"
            [customCellTemplate]="uploadsActions">
            <ng-template let-row #uploadsActions>
                <app-action-dots>
                    <button
                        [disabled]="isButtonDisabled('backup-restore-restore')"
                        routerLink="restore"
                        [state]="row">
                        {{ 'restore' | myLbTranslate }}
                    </button>
                </app-action-dots>
            </ng-template>
        </app-column>
    </app-table>
    <br>
    <h2>{{ 'backup_restore_auto_backup' | myLbTranslate }}</h2>
    <app-table [name]="'backup-autoBackup'" [data]="autoBackUpTable">
        <app-column [flexGrow]="1" prop="description" label="description"></app-column>
        <app-column [flexGrow]="0.5" prop="change" label="change"></app-column>
        <app-column [flexGrow]="0.5" prop="viewDateTime" label="date_time"></app-column>
        <app-column
            [flexGrow]="0.5"
            prop="actions"
            label=""
            [cellAlignment]="'right'"
            [customCellTemplate]="autoBackupActions">
            <ng-template let-row #autoBackupActions>
                <app-action-dots>
                    <button
                        [disabled]="isButtonDisabled('backup-restore-restore')"
                        routerLink="restore"
                        [state]="row">
                        {{ 'restore' | myLbTranslate }}
                    </button>
                </app-action-dots>
            </ng-template>
        </app-column>
    </app-table>
</div>

<app-modal-wrapper modalWidth="500" modalTitle="restore" modalId="restoreModal" style="user-select: none;">
    <app-modal-body>
        <p>{{ 'backup_restore_restore_model_text' | myLbTranslate }}:</p>
    </app-modal-body>
</app-modal-wrapper>
