<app-modal-wrapper
    modalHeight="400"
    modalWidth="800"
    modalTitle="hanging_acls"
    modalId="hangingAclsModal">
    <app-modal-body>
        <div class="content-space-between">
            <p>{{ 'hanging_acls_acls_list' | myLbTranslate }}</p>
            <div>
                <button
                    *ngIf="selectedRows.length > 1"
                    id="hanging-delete-all-rows"
                    class="button primary medium"
                    (click)="confirmDeleteAcl(selectedRows)">
                    {{ 'delete' | myLbTranslate }} ({{ selectedRows.length }}) {{ 'rows' | myLbTranslate }}
                </button>
                <button
                    *ngIf="selectedRows.length !== hangingAcls.length
                        && selectedRows.length <= 1
                        && hangingAcls.length !== 1"
                    id="hanging-select-all-rows"
                    class="button primary medium grey-button"
                    (click)="selectAllRows()">
                    {{ 'select_all' | myLbTranslate }}
                </button>
            </div>
        </div>
        <app-table
            [name]="'hangingAcls'"
            [data]="tableRows"
            [selectedRows]="selectedRows"
            (selectedRowsOut)="onSelect($event)">
            <app-column [flexGrow]="1" prop="condition" label="condition"></app-column>
            <app-column [flexGrow]="1" prop="type" label="type"></app-column>
            <app-column [flexGrow]="1" prop="redirectType" label="manage_acls_redirect_type"></app-column>
            <app-column [flexGrow]="1" prop="redirectLocation" label="manage_acls_redirect_location"></app-column>
            <app-column
                [flexGrow]="0.5"
                prop="actions"
                [cellAlignment]="'right'"
                [customCellTemplate]="hangingAclActions">
                <ng-template let-row #hangingAclActions>
                    <app-action-dots *ngIf="selectedRows.length < 2">
                        <button
                            id="hanging-acl-delete-backend"
                            [disabled]="isButtonDisabled('hanging-acl-delete-backend')"
                            (click)="confirmDeleteAcl([row])">
                            {{ 'delete' | myLbTranslate }}
                        </button>
                    </app-action-dots>
                </ng-template>
            </app-column>
        </app-table>
    </app-modal-body>
</app-modal-wrapper>

<app-modal-wrapper modalTitle="hanging_acls_deleting" modalId="progressModal" modalWidth="400">
    <app-modal-body>
        <div class="dot-wrapper">
            <div class="dot-spin"></div>
        </div>
        <div class="progress-box" [innerHTML]="progressMessage"></div>
    </app-modal-body>
</app-modal-wrapper>
