<app-widget
    #widget
    class="widget-item col-span-2"
    title="{{ 'pulse_ha_cluster_health' | myLbTranslate }}"
    id="widget-pulse-ha"
    [loading]="loading"
    (showContent)="getWidgetDetails($event)">
    <div class="scroll-close-button" *ngIf="scrolled" (click)="scrolled = !scrolled">
        <img src="assets/shared/icons/close-cross.svg" alt="Close icon"/>
    </div>
    <div class="content-vertical-full-width">
        <div *ngIf="!scrolled">
            <div class="status-head">
                <a
                    class="success"
                    title="{{ 'online' | myLbTranslate }}"
                    [class.disabled]="!permissionsLookup['pulse-widget-node-redirect']"
                    (click)="getNodeAndRedirect('online')">
                    {{ online }}
                </a>
            </div>
            <div class="spacer">&middot;</div>
            <div class="status-head">
                <a
                    class="critical"
                    title="{{ 'down' | myLbTranslate }}"
                    [class.disabled]="!permissionsLookup['pulse-widget-node-redirect']"
                    (click)="getNodeAndRedirect('offline')">
                    {{ offline }}
                </a>
            </div>
            <h2 class="widget-description">{{ 'pulse_ha_cluster_status' | myLbTranslate }}</h2>
        </div>
        <div #list id="node-list" class="expandable-list" [class.extended]="scrolled">
            <table class="widget-table">
                <tr>
                    <th></th>
                    <th>{{ 'node' | myLbTranslate }}</th>
                    <th>{{ 'status' | myLbTranslate }}</th>
                    <th>{{ 'ip' | myLbTranslate }}</th>
                </tr>
                <tr
                    *ngFor="let member of members"
                    class="row-content"
                    [class.disabled]="!permissionsLookup['pulse-widget-node-redirect']"
                    (click)="redirectToHAConfig(member.hostname)">
                    <td>
                        <app-tooltip width="90" [trigger]="pulseHaHtml(member)">
                            {{ utils.getStatusDisplayName(member.status) | titlecase }}
                        </app-tooltip>
                    </td>
                    <td>{{ member.hostname }}</td>
                    <td>{{ member.status }}</td>
                    <td>{{ member.ip }}</td>
                </tr>
            </table>
        </div>
    </div>
</app-widget>
