<div class="component-main">
    <div class="content">
        <h1>{{ (editId ? 'gslb_edit_pool' : 'gslb_add_pool') | myLbTranslate }}</h1>
    </div>
    <form *ngIf="dataAvailable" [formGroup]="poolForm" id="backend_form" name="backend_form">
        <div class="form-group">
            <label for="backend_name">{{ 'name' | myLbTranslate }}*</label>
            <div class="form-content">
                <input formControlName="name" id="backend_name" name="backend_name" type="text" value="">
                <app-validation-messages [control]="poolForm.get('name')"></app-validation-messages>
                <app-help class="help-button" tag="pools_name"></app-help>
            </div>
        </div>
        <div class="form-group">
            <!--suppress XmlInvalidId -->
            <label for="gslb-pool-monitor">{{ 'gslb_monitor_mode' | myLbTranslate }}</label>
            <div class="form-content">
                <app-dropdown
                    dropDownId="gslb-pool-monitor"
                    formControlName="monitor"
                    [dropdownWidth]="250"
                    [formGroup]="poolForm"
                    [content]="dropdown.backEndMonitor"
                    (keyOut)="monitorChange()">
                </app-dropdown>
                <app-help class="help-button" tag="pools_monitor-mode"></app-help>
            </div>
        </div>
        <div class="form-group">
            <!--suppress XmlInvalidId -->
            <label for="gslb-pool-hc-script">{{ 'gslb_monitor_script' | myLbTranslate }}</label>
            <div class="form-content">
                <ng-container *ngIf="healthCheckScripts.length; else noTemplatesFound">
                    <app-dropdown
                        dropDownId="gslb-pool-hc-script"
                        formControlName="monitorExternalScriptID"
                        [dropdownWidth]="200"
                        [formGroup]="poolForm"
                        [content]="dropdown.healthCheckScripts">
                    </app-dropdown>
                </ng-container>
                <ng-template #noTemplatesFound>
                    <span class="monitor-script-message">{{ 'gslb_monitor_no_scripts_found' | myLbTranslate }}</span>
                </ng-template>
                <app-help class="help-button" tag="pools_monitor-script"></app-help>
            </div>
        </div>
        <div class="form-group">
            <label for="backend_monitorInterval">{{ 'gslb_monitor_interval' | myLbTranslate }}*</label>
            <div class="form-content">
                <input
                    appInputValidator
                    formControlName="monitorInterval"
                    id="backend_monitorInterval"
                    name="backend_monitorInterval"
                    type="number" [style.width.px]="70">
                <app-validation-messages [control]="poolForm.get('monitorInterval')"></app-validation-messages>
                <app-help class="help-button" tag="pools_monitor-interval"></app-help>
            </div>
        </div>
        <div class="form-group">
            <label for="backend_monitorTimeout">
                {{ 'gslb_monitor_timeout' | myLbTranslate }} {{ 'ms' | myLbTranslate }}*
            </label>
            <div class="form-content">
                <input
                    formControlName="monitorTimeout"
                    id="backend_monitorTimeout"
                    name="backend_monitorTimeout"
                    type="number" [style.width.px]="80">
                <app-validation-messages [control]="poolForm.get('monitorTimeout')"></app-validation-messages>
                <app-help class="help-button" tag="pools_monitor-timeout"></app-help>
            </div>
        </div>
        <div class="form-group">
            <!--suppress XmlInvalidId -->
            <label for="gslb-pool-monitor-retries">{{ 'gslb_monitor_retries' | myLbTranslate }}</label>
            <div class="form-content">
                <app-dropdown
                    dropDownId="gslb-pool-monitor-retries"
                    formControlName="monitorRetries"
                    [dropdownWidth]="70"
                    [formGroup]="poolForm"
                    [content]="dropdown.backEndMonitorRetries">
                </app-dropdown>
                <app-help class="help-button" tag="pools_monitor-retries"></app-help>
            </div>
        </div>
        <div [hidden]="poolForm.get('monitorUseSSL').disabled" class="form-group">
            <div class="form-content">
                <input
                    class="lb-checkbox green"
                    formControlName="monitorUseSSL"
                    id="backend_monitorUseSSL"
                    name="backend_monitorUseSSL"
                    type="checkbox">
                <label for="backend_monitorUseSSL">{{ 'gslb_monitor_use_ssl' | myLbTranslate }}</label>
                <app-help class="help-button" tag="pools_monitor-use-ssl"></app-help>
            </div>
        </div>
        <div [hidden]="poolForm.get('monitorURLPath').disabled" class="form-group">
            <label for="backend_monitorURLPath">{{ 'gslb_monitor_url_path' | myLbTranslate }}*</label>
            <div class="form-content">
                <input
                    formControlName="monitorURLPath"
                    id="backend_monitorURLPath"
                    name="backend_monitorURLPath"
                    type="text">
                <app-validation-messages [control]="poolForm.get('monitorURLPath')"></app-validation-messages>
                <app-help class="help-button" tag="pools_monitor-url-path"></app-help>
            </div>
        </div>
        <div [hidden]="poolForm.get('monitorHostname').disabled" class="form-group">
            <label for="backend_monitorHostname">{{ 'gslb_monitor_hostname' | myLbTranslate }}</label>
            <div class="form-content">
                <input
                    formControlName="monitorHostname"
                    id="backend_monitorHostname"
                    name="backend_monitorHostname"
                    type="text">
                <app-validation-messages [control]="poolForm.get('monitorHostname')"></app-validation-messages>
                <app-help class="help-button" tag="pools_monitor-hostname"></app-help>
            </div>
        </div>
        <div [hidden]="poolForm.get('monitorPort').disabled" class="form-group">
            <label for="backend_monitorPort">{{ 'gslb_monitor_port' | myLbTranslate }}*</label>
            <div class="form-content">
                <input
                    formControlName="monitorPort"
                    id="backend_monitorPort"
                    name="backend_monitorPort"
                    type="number">
                <app-validation-messages [control]="poolForm.get('monitorPort')"></app-validation-messages>
                <app-help class="help-button" tag="pools_monitor-port"></app-help>
            </div>
        </div>
        <div [hidden]="poolForm.get('monitorExpectedCodes').disabled" class="form-group">
            <label>{{ 'gslb_monitor_expected_codes' | myLbTranslate }}*</label>
            <div class="form-group" *ngFor="let control of monitorCodes.controls; index as i">
                <div class="form-content monitorExpectedCodes">
                    <input
                        #codes
                        id="monitor-code-{{i}}"
                        [formControl]="getFormControl(control)"
                        title="{{ 'gslb_pools_input_monitorCode' | myLbTranslate }}"
                        type="number"
                        (change)="validateMonitorCodes()">
                    <ng-container *ngIf="monitorCodes.length > 1">
                        <button type="button" class="button-as-link" (click)="removeMonitorCode(i)">
                            {{ 'delete' | myLbTranslate }}
                        </button>
                    </ng-container>
                    <ng-container *ngIf="i === monitorCodes.controls.length -1">
                        <button
                            #addCodeButton
                            [disabled]="monitorCodeIsInvalid(i)"
                            class="button-as-link"
                            (click)="addMonitorCode()">
                            {{ 'add' | myLbTranslate }}
                        </button>
                    </ng-container>
                    <app-validation-messages [control]="monitorCodes.controls[i]"></app-validation-messages>
                    <app-help *ngIf="i === 0" class="help-button" tag="pools_monitor-expected-codes"></app-help>
                </div>
            </div>
        </div>
        <div [hidden]="poolForm.get('monitorSendString').disabled" class="form-group">
            <label for="backend_monitorSendString">{{ 'gslb_monitor_send_string' | myLbTranslate }}</label>
            <div class="form-content">
                <input
                    formControlName="monitorSendString"
                    id="backend_monitorSendString"
                    name="backend_monitorSendString"
                    type="text">
                <app-validation-messages [control]="poolForm.get('monitorSendString')"></app-validation-messages>
                <app-help class="help-button" tag="pools_monitor-send-string"></app-help>
            </div>
        </div>
        <div [hidden]="poolForm.get('monitorMatchRe').disabled" class="form-group">
            <label for="backend_monitorMatchRe">{{ 'gslb_monitor_match_re' | myLbTranslate }}</label>
            <div class="form-content">
                <input
                    formControlName="monitorMatchRe"
                    id="backend_monitorMatchRe"
                    name="backend_monitorMatchRe"
                    type="text">
                <app-validation-messages [control]="poolForm.get('monitorMatchRe')"></app-validation-messages>
                <app-help class="help-button" tag="pools_monitor-match-return"></app-help>
            </div>
        </div>
        <div [hidden]="poolForm.get('monitorStatus').disabled" class="form-group">
            <!--suppress XmlInvalidId -->
            <label for="gslb-pool-monitor-status">{{ 'gslb_monitor_status' | myLbTranslate }}</label>
            <div class="form-content">
                <app-dropdown
                    dropDownId="gslb-pool-monitor-status"
                    formControlName="monitorStatus"
                    [dropdownWidth]="90"
                    [formGroup]="poolForm"
                    [content]="dropdown.backendMonitorStatus">
                </app-dropdown>
                <app-help class="help-button" tag="pools_monitor-status"></app-help>
            </div>
        </div>
        <div class="form-group">
            <!--suppress XmlInvalidId -->
            <label for="gslb-pool-lb-method">{{ 'gslb_lb_method' | myLbTranslate }}</label>
            <div class="form-content">
                <app-dropdown
                    dropDownId="gslb-pool-lb-method"
                    formControlName="lbMethod"
                    [dropdownWidth]="290"
                    [formGroup]="poolForm"
                    [content]="dropdown.backendLbMethod">
                </app-dropdown>
                <app-help class="help-button" tag="pools_lb-method"></app-help>
            </div>
        </div>
        <div class="form-group">
            <!--suppress XmlInvalidId -->
            <label for="gslb-pool-fallback">{{ 'fallback' | myLbTranslate }}</label>
            <div class="form-content">
                <app-dropdown
                    dropDownId="gslb-pool-fallback"
                    formControlName="fallback"
                    [dropdownWidth]="80"
                    [formGroup]="poolForm"
                    [content]="dropdown.backendFallback">
                </app-dropdown>
                <app-help class="help-button" tag="pools_fallback"></app-help>
            </div>
        </div>
        <div class="form-group">
            <label for="backend_maxAddrsReturned">{{ 'gslb_max_address_returned' | myLbTranslate }}*</label>
            <div class="form-content">
                <input
                    formControlName="maxAddrsReturned"
                    id="backend_maxAddrsReturned"
                    name="backend_maxAddrsReturned"
                    [style.width.px]="75"
                    type="number">
                <app-validation-messages [control]="poolForm.controls.maxAddrsReturned"></app-validation-messages>
                <app-help class="help-button" tag="pools_max-address-returned"></app-help>
            </div>
        </div>
        <ng-container *ngIf="globalNameControls.controls.length">
            <label>{{ 'gslb_global_names' | myLbTranslate }}</label>
            <div *ngFor="let control of globalNameControls.controls; index as i" class="form-group">
                <div class="form-content">
                    <input
                        id="checkboxGlobalName{{[i]}}"
                        class="lb-checkbox green"
                        type="checkbox"
                        [formControl]="control"/>
                    <label for="checkboxGlobalName{{[i]}}" class="lb-checkbox green"></label>
                    <span>{{ globalNames[i].domain }}</span>
                    <app-help class="help-button" tag="pools_global-name"></app-help>
                    <app-validation-messages [control]="poolForm.get('globalNamesIDs')">
                    </app-validation-messages>
                </div>
            </div>
        </ng-container>
        <div *ngIf="globalNameControls.controls.length === 0" class="form-group">
            <label>{{ 'gslb_global_names' | myLbTranslate }}</label>
            <span>{{ 'gslb_no_available_global_names' | myLbTranslate }}</span>
        </div>
        <section class="content-space-between">
            <div class="dropzone-wrapper">
                <div class="drag-n-drop-wrapper">
                    <label>{{ 'gslb_available_members' | myLbTranslate }}</label>
                    <div class="drag-n-drop-list" appDragNDrop>
                        <ng-container *ngFor="let item of availableMembers;">
                            <div class="drag-item" [attr.data-form]="item.id">
                                <div id="{{item.name}}"></div>
                                {{ item.name }}
                            </div>
                        </ng-container>
                    </div>
                </div>
                <div class="drag-n-drop-wrapper">
                    <label class="selected-title">{{ 'gslb_selected_members' | myLbTranslate }}
                        ({{ getSelectedMemberLength() }})
                    </label>
                    <div
                        class="drag-n-drop-list"
                        appDragNDrop
                        [highlightDropArea]="true"
                        (selectedData)="updateSelected($event)">
                        <ng-container *ngFor="let item of this.selectedMembers">
                            <div class="drag-item" [attr.data-form]="item">
                                <div id="selected{{item}}"></div>
                                <ng-container *ngIf="dragActive || dataAvailable">
                                    {{ getMemberName(item) }}
                                </ng-container>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </div>
        </section>
    </form>
</div>
<div class="component-footer">
    <button class="outline" [routerLink]="editId ? '../../..' : '../..'" [queryParams]="{sFilter: 'pools'}">
        {{ 'cancel' | myLbTranslate }}
    </button>
    <app-loading-button
        buttonText="save"
        buttonClass="primary"
        [isDisabled]="poolForm.invalid || poolForm.pristine"
        [callBack]="submitPool.bind(this)">
    </app-loading-button>
</div>
