<app-modal-wrapper
    [isDraggable]="true"
    [isOverlayClickable]="false"
    [modalHeight]="275"
    [modalWidth]="500"
    modalTitle="password_confirm"
    footerButtonAlignment="content-center"
    modalId="confirm-password"
    (closed)="cancelClicked()">
    <app-modal-body>
        <form [formGroup]="passwordForm">
            <div class="form-group">
                <label>{{ message | myLbTranslate }}</label>
                <div class="form-content">
                    <app-lb-reveal-password
                        id="password"
                        title="{{'password' | myLbTranslate }}"
                        formControlName="password"
                        [formGroup]="passwordForm">
                    </app-lb-reveal-password>
                    <app-validation-messages [control]="passwordForm.get('password')"></app-validation-messages>
                </div>
            </div>
        </form>
    </app-modal-body>
    <app-modal-footer>
        <!-- Can't use callback here because the callback function might not be an observable -->
        <app-loading-button
            buttonId="modal-confirm-user-password"
            buttonClass="primary"
            buttonText="submit"
            [isDisabled]="passwordForm.pristine || passwordForm.invalid"
            [callBack]="triggerCallBack.bind(this)">
        </app-loading-button>
    </app-modal-footer>
</app-modal-wrapper>
