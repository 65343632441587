<div
    class="drawer"
    [class.open]="drawVisible"
    (@showHide.start)="showHideShadow($event)"
    (@showHide.done)="showHideShadow($event)"
    [class.showShadow]="animationComplete"
    [@showHide]="{ value: isDrawerVisible() ? 'show' : 'hide', params:
                     { drawerWidth: width, transitionTiming: transitionTiming }
                }">
    <div class="drawer-wrapper" [ngStyle]="{'min-width.px': width}">
        <div class="drawer-header">
            <div class="left-content">
                <span *ngIf="!isLoading" class="drawer-title">{{ title }}</span>
            </div>
            <div class="right-content">
                <ng-container
                    *ngIf="!isLoading && actionDots && data"
                    [ngTemplateOutlet]="actionDots"
                    [ngTemplateOutletContext]="{row: data, displayAsButton: false, isDrawer: true}">
                </ng-container>
                <img
                    id="modal-close"
                    class="close"
                    ngSrc="../../../../assets/shared/icons/close-cross.svg"
                    height="20"
                    width="20"
                    alt="close drawer icon"
                    (click)="hide()"/>
            </div>
        </div>
        <ng-container *ngIf="!isLoading else loading">
            <div *ngIf="content" class="drawer-content" [innerHTML]="content | safeHtml"></div>
            <div *ngIf="template" class="drawer-content">
                <ng-template *ngTemplateOutlet="template"></ng-template>
            </div>
        </ng-container>
        <ng-template #loading>
            <div class="drawer-content loading">
                <img
                    class="loader"
                    ngSrc="assets/shared/icons/loading-spinner-primary.svg"
                    alt="loading spinner"
                    height="40"
                    width="40"/>
            </div>
        </ng-template>
    </div>
</div>
