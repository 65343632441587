<app-widget
    #widget
    class="widget-item col-span-2"
    title="{{ 'licence_widget_details' | myLbTranslate }} ({{license.companyName}})"
    id="widget-license-details"
    [loading]="loading.status()">
    <table *ngIf="!hideContent">
        <tr>
            <td>{{ 'licence_type' | myLbTranslate }}:</td>
            <td>{{ license.platform | uppercase }} {{ license.model | uppercase }} {{ license.licenceType | titlecase }}</td>
            <td></td>
        </tr>
        <tr>
            <td>{{ 'licence_widget_expiry' | myLbTranslate }}:</td>
            <td>{{ license.supportExpireDate }}</td>
            <td></td>
        </tr>
        <tr>
            <td>{{ 'serial' | myLbTranslate }}:</td>
            <td>{{ license.serial }}</td>
            <td></td>
        </tr>
        <tr>
            <td>{{ 'licence_widget_core_updates' | myLbTranslate }}:</td>
            <td>
                <span class="success"><i class="fas fa-check-circle"></i> {{ 'licenced' | myLbTranslate }}</span>
            </td>
            <td>({{ 'expires' | myLbTranslate }}: {{ license.daysToExpire }} {{ 'days' | myLbTranslate }})</td>
        </tr>
        <tr>
            <td>{{ 'licence_widget_security_updates' | myLbTranslate }}:</td>
            <td class="success">
                <span class="success"><i class="fas fa-check-circle"></i> {{ 'licenced' | myLbTranslate }}</span>
            </td>
            <td>{{ 'licence_widget_lifetime' | myLbTranslate }}</td>
        </tr>
    </table>
</app-widget>
