<div class="component-main">
    <div class="content-space-between">
        <h1>{{ 'view_config_snmp_conf' | myLbTranslate }}</h1>
        <button class="primary" routerLink="config">
            {{ (snmpOff ? 'snmp_conf_settings_add' : 'snmp_conf_settings_edit') | myLbTranslate }}
        </button>
    </div>

    <ng-container *ngIf="snmpOff else settings">
        <p>{{ 'nothing_configured'|myLbTranslate }}</p>
    </ng-container>
    <ng-template #settings>
        <div class="snmp-settings">
            <h3>{{ 'snmp_conf_protocol_in_use' | myLbTranslate }} {{ protocolsInUse }}</h3>
            <div id="snmp-settings-wrapper">
                <p>
                    <strong>{{ 'snmp_conf_snmp_community' | myLbTranslate }}:</strong>
                    {{ (snmpSettings.community || 'N/A') | titlecase }}
                </p>
                <p>
                    <strong>{{ 'snmp_conf_snmp_location' | myLbTranslate }}:</strong>
                    {{ snmpSettings.location | titlecase }}
                </p>
                <p>
                    <strong>{{ 'snmp_conf_snmp_contact' | myLbTranslate }}:</strong>
                    {{ snmpSettings.contact }}
                </p>
            </div>
        </div>
    </ng-template>

    <ng-container *ngIf="protocolsInUse?.includes('V3')">
        <div class="content-space-between">
            <h1>{{ 'users' | myLbTranslate }}</h1>
            <div class="add-user">
                <button class="primary" routerLink="user">Add user</button>
            </div>
        </div>
        <app-table
            name="snmps-users-list"
            [data]="userTableRows"
            [allowMultiSelect]="false"
            [showPaginator]="false"
            [stickyHeader]="true">
            <app-column [flexGrow]="1" prop="user" label="user"></app-column>
            <app-column [flexGrow]="1" prop="authentication" label="authentication"></app-column>
            <app-column [flexGrow]="1" prop="privacy" label="external_privacy"></app-column>
            <app-column [flexGrow]="1" prop="permission" label="permission"></app-column>
            <app-column
                [flexGrow]="1"
                prop="actions"
                label=""
                cellAlignment="right"
                [customCellTemplate]="actions">
                <ng-template let-row #actions>
                    <app-action-dots>
                        <button id="snmp-edit-user" routerLink="user/{{row.id}}">
                            {{ 'edit' | myLbTranslate }}
                        </button>
                        <button id="snmp-delete-user" (click)="confirmDeleteUser(row.id)">
                            {{ 'delete' | myLbTranslate }}
                        </button>
                    </app-action-dots>
                </ng-template>
            </app-column>
        </app-table>
    </ng-container>
</div>
