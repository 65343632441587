<img ngSrc="assets/shared/icons/burger-menu.svg" alt="Burger menu icon" height="22" width="26" (click)="toggleMenu()"/>

<div class="burger-menu-content" [class.open]="isOpen">
    <div class="burger-menu-header">
        <div class="menu-item-wrapper">
            <div id="burger-menu-close" (click)="toggleMenu()">
                <i class="fal fa-times"></i>
            </div>
        </div>
    </div>
    <div class="menuItems">
        <ng-container *ngFor="let primaryItem of menuConfig.menuItems">
            <div
                id="burger-menu-{{primaryItem.id}}"
                class="menu-item-wrapper"
                [class.active]="activeMenuItem === primaryItem.id"
                [ngClass]="toggleChevron(primaryItem)"
                (click)="toggleNav(primaryItem)">
                <div class="menu-image-wrapper">
                    <img
                        ngSrc="/assets/{{ primaryItem.iconLocation }}.svg"
                        alt="{{ primaryItem.heading | myLbTranslate}} icon"
                        height="20"
                        width="20">
                </div>
                <span>{{ primaryItem.heading | myLbTranslate }}</span>
            </div>
            <div class="level-2" [class.show]="showPrimaryMenuChildren === primaryItem.id">
                <ng-container *ngFor="let secondaryItem of primaryItem.children">
                    <div
                        id="burger-menu-{{secondaryItem.id}}"
                        class="menu-item-wrapper secondary"
                        [class.active]="activeMenuItem === secondaryItem.id"
                        [ngClass]="toggleChevron(secondaryItem)"
                        (click)="toggleNav(secondaryItem)">
                        <span>{{ secondaryItem.heading | myLbTranslate }}</span>
                    </div>
                    <ng-container *ngIf="showSecondaryMenuChildren === secondaryItem.id">
                        <ng-container *ngFor="let tertiaryItem of secondaryItem.children">
                            <div
                                id="burger-menu-{{tertiaryItem.id}}"
                                class="menu-item-wrapper secondary sub-item"
                                [class.active]="activeMenuItem === tertiaryItem.id"
                                (click)="toggleNav(tertiaryItem)">
                                <span>{{ tertiaryItem.heading | myLbTranslate }}</span>
                            </div>
                        </ng-container>
                    </ng-container>
                </ng-container>
            </div>
        </ng-container>
        <div id="burger-menu-logout" class="menu-item-wrapper" (click)="openLogoutModal()">
            <div class="menu-image-wrapper">
                <img
                    ngSrc="/assets/centralisedManagement/icons/logout.svg"
                    alt="{{'logs' | myLbTranslate}} icon"
                    height="19"
                    width="19">
            </div>
            <span>{{ 'logout' | myLbTranslate | titlecase }}</span>
        </div>
    </div>
    <div class="menu-footer">
        <ng-content select="[menu-footer]"></ng-content>
    </div>
</div>
