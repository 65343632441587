<div class="component-main">
    <div class="content-space-between">
        <h1>{{ 'manage_acls' | myLbTranslate }}</h1>
        <div>
            <button
                *ngIf="hangingAcls.length"
                id="manage-acls-hanging-backends"
                [disabled]="!permissionsLookup['manage-acls-hanging-backends']"
                class="primary"
                (click)="openHangingAclsModal()">
                {{ 'hanging_acls' | myLbTranslate }} ({{ hangingAcls.length }})
            </button>
            <button
                id="manage-acls-add-acl"
                class="primary"
                [disabled]="!frontends.length || !permissionsLookup['manage-acls-add-acl']"
                (click)="addAcl()">
                {{ 'manage_acls_add' | myLbTranslate }}
            </button>
        </div>
    </div>
    <app-table
        id="manage-acls"
        [data]="tableRows"
        [showPaginator]="false"
        [stickyHeader]="true">
        <app-column [flexGrow]="1" prop="frontend" label="frontend" [cellTemplate]="aclDetails">
            <ng-template let-row #aclDetails>
                <button
                    class="routerLink"
                    id="manage-services-service-details"
                    [disabled]="!permissionsLookup['manage-acls-acls-details']"
                    [routerLink]="['acls-details', row.frontendId]">
                    {{ row.frontendLabel }}
                </button>
            </ng-template>
        </app-column>
        <app-column [flexGrow]="0.5" prop="aclsCount" label="count"></app-column>
        <app-column [flexGrow]="1" prop="conditions" label="condition"></app-column>
    </app-table>
</div>

<!-- Container used for all modals -->
<ng-container #modalContainer></ng-container>
