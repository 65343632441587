<h1>{{ 'services_frontend_configuration' | myLbTranslate }}</h1>
<form [formGroup]="serviceForm" [class.full-width]="isFormFullWidth" [loaded]="!wizardService.isLoading">
    <!-- General Options start -->
    <div [formGroupName]="subStepEnum.GENERAL" [hidden]="getWizardSubStepState(subStepEnum.GENERAL)">
        <h2>{{ subStepEnum.GENERAL | myLbTranslate }}</h2>
        <section data-control-name="label">
            <div *ngIf="!isInputHidden('name')" class="form-group">
                <label for="frontendLabel{{templateIndex}}">{{ 'name' | myLbTranslate }}*</label>
                <div class="form-content">
                    <ng-container *ngIf="namePrefix">
                        <span class="name-prefix">{{ namePrefix }}</span>
                    </ng-container>
                    <input
                        id="frontendLabel{{templateIndex}}"
                        formControlName="name"
                        name="name"
                        title="name"
                        type="text">
                    <app-validation-messages [control]="serviceForm.get(subStepEnum.GENERAL + '.name')">
                    </app-validation-messages>
                    <app-help class="help-button" tag="l7-frontend_label"></app-help>
                </div>
            </div>
        </section>
        <section data-control-name="mode">
            <div *ngIf="!isInputHidden('mode')" class="form-group">
                <label for="mode{{templateIndex}}">{{ 'services_layer_7_mode' | myLbTranslate }}</label>
                <div class="form-content">
                    <app-dropdown
                        dropDownId="mode{{templateIndex}}"
                        [formGroup]="getFormGroup(subStepEnum.GENERAL)"
                        formControlName="mode"
                        [content]="dropdown.modes">
                    </app-dropdown>
                    <app-help class="help-button" tag="l7-frontend_mode"></app-help>
                </div>
                <span *ngIf="serviceForm.get(subStepEnum.GENERAL + '.mode').disabled" class="note">
                        {{ 'services_frontend_mode_disabled_message' | myLbTranslate }}
                    </span>
            </div>
        </section>
        <section data-control-name="ports" *ngIf="!isInputHidden('ports')">
            <app-lb-checkbox
                label="{{ 'services_individual_ports' | myLbTranslate }}"
                [hideValidation]="true"
                formControlName="enableIndividuals"
                [formGroup]="getFormGroup(subStepEnum.GENERAL)">
                <div class="form-group individual" *ngFor="let control of portIndividual.controls; index as i">
                    <label *ngIf="i === 0">Port</label>
                    <div class="form-content">
                        <input
                            id="individual{{i}}"
                            [formControl]="getFormControl(control)"
                            name="ports"
                            class="port"
                            placeholder="{{ 'services_ports_placeholder' | myLbTranslate }}"
                            title="ports"
                            type="number"
                            (change)="checkForDuplicatePorts()">
                        <app-validation-messages
                            [control]="serviceForm.get(subStepEnum.GENERAL + '.portIndividuals.' + i)">
                        </app-validation-messages>
                        <button
                            [disabled]="this.portIndividual.length === 1"
                            class="button-as-link"
                            (click)="removePortIndividual(i)">{{ 'delete' | myLbTranslate }}
                        </button>
                        <button
                            [disabled]="!serviceForm.get(subStepEnum.GENERAL + '.enableIndividuals').valid"
                            class="button-as-link"
                            (click)="addPortIndividual()">{{ 'add_another' | myLbTranslate }}
                        </button>
                    </div>
                </div>
            </app-lb-checkbox>
            <app-lb-checkbox
                [formGroup]="getFormGroup(subStepEnum.GENERAL)"
                formControlName="enableRanges"
                label="{{ 'services_port_ranges' | myLbTranslate }}">
                <ng-container *ngFor="let control of portRange.controls; index as i">
                    <div class="form-content">
                        <div class="form-row">
                            <div class="form-group">
                                <label *ngIf="i === 0">From</label>
                                <div class="form-content">
                                    <input
                                        [formControl]="control['controls']['portStart']"
                                        id="rangeStart{{i}}"
                                        [min]="getStartMinValue(i) || 0"
                                        class="port"
                                        max="1022"
                                        name="ports"
                                        title="ports"
                                        type="number" (change)="checkForOverlappingRange()">
                                    <app-validation-messages
                                        [control]="serviceForm.get(subStepEnum.GENERAL + '.portRanges.' + i + '.portStart')">
                                    </app-validation-messages>
                                </div>
                            </div>
                            <div class="form-group">
                                <label *ngIf="i === 0">To</label>
                                <div class="form-content">
                                    <input
                                        [formControl]="control['controls']['portEnd']"
                                        class="port"
                                        id="rangeEnd{{i}}"
                                        [min]="getEndMinValue(i)"
                                        max="1022"
                                        name="ports"
                                        [placeholder]="'min ' + getEndMinValue(i) || 'ports'"
                                        title="ports"
                                        type="number" (change)="checkForOverlappingRange()">
                                    <app-validation-messages
                                        [control]="serviceForm.get(subStepEnum.GENERAL + '.portRanges.' + i + '.portEnd')">
                                    </app-validation-messages>
                                    <button
                                        [disabled]="!control.valid || this.portRange.length === 1"
                                        class="button-as-link"
                                        (click)="removePortRange(i)">{{ 'delete' | myLbTranslate }}
                                    </button>
                                    <button
                                        [disabled]="!serviceForm.get(subStepEnum.GENERAL + '.portRanges').valid"
                                        class="button-as-link"
                                        (click)="addPortRange()">{{ 'add_another' | myLbTranslate }}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </app-lb-checkbox>
        </section>
        <section data-control-name="tcpKeepalive">
            <div *ngIf="!isInputHidden('tcpKeepalive')" class="form-group">
                <div class="form-content">
                    <input
                        formControlName="tcpKeepalive"
                        id="tcpKeepalive{{templateIndex}}"
                        class="lb-checkbox"
                        name="tcpKeepalive" type="checkbox">
                    <label for="tcpKeepalive{{templateIndex}}" class="lb-checkbox">
                        {{ 'services_tcp_keep_alive' | myLbTranslate }}
                    </label>
                    <app-help class="help-button" tag="l7-frontend_tcp-keepalive"></app-help>
                </div>
            </div>
        </section>
        <section data-control-name="acceptProxyProtocol">
            <div *ngIf="!isInputHidden('acceptProxyProtocol')" class="form-group">
                <div class="form-content">
                    <input
                        formControlName="acceptProxyProtocol"
                        id="acceptProxyProtocol{{templateIndex}}"
                        name="acceptProxyProtocol"
                        class="lb-checkbox"
                        type="checkbox">
                    <label for="acceptProxyProtocol{{templateIndex}}" class="lb-checkbox">
                        {{ 'services_proxy_protocol' | myLbTranslate }}
                    </label>
                    <app-help class="help-button" tag="l7-frontend_accept-proxy-protocol"></app-help>
                </div>
            </div>
        </section>
    </div>

    <!-- Floating IPs -->
    <div [formGroupName]="subStepEnum.FLOATING_IPS" [hidden]="getWizardSubStepState(subStepEnum.FLOATING_IPS)">
        <section data-control-name="fipId">
            <div class="embedded" *ngIf="!isInputHidden('fipId')">
                <app-lb-floating-ip
                    [fips]="floatingIPs"
                    [groups]="ipGroups"
                    [componentEmbedded]="true"
                    [selectedKey]="serviceForm.get(subStepEnum.FLOATING_IPS + '.fipId').value"
                    [validationError]="fipValidation"
                    (fipId)="setFipId($event)">
                </app-lb-floating-ip>
            </div>
        </section>
    </div>

    <!-- Header options start -->
    <div [formGroupName]="subStepEnum.HEADERS" [hidden]="getWizardSubStepState(subStepEnum.HEADERS)">
        <section data-control-name="headerIDs">
            <div *ngIf="!isInputHidden('headerIds')">
                <app-lb-l7headers
                    [serviceId]="template?.id?.value"
                    [templateIndex]="templateIndex"
                    [templateName]="componentTitle"
                    (headerIdsOut)="headerIds($event)">
                </app-lb-l7headers>
            </div>
        </section>
    </div>

    <!-- SSL options start -->
    <div [formGroupName]="subStepEnum.SSL" [hidden]="getWizardSubStepState(subStepEnum.SSL)">
        <h2>{{ subStepEnum.SSL | myLbTranslate }}</h2>
        <section data-control-name="SSLEnable">
            <div *ngIf="!isInputHidden('SSLEnable')">
                <app-lb-checkbox
                    [showHiddenContent]="!isInputHidden('SSLEnable', {force: true})"
                    [formGroup]="getFormGroup(subStepEnum.SSL)"
                    formControlName="SSLEnable"
                    label="{{ 'services_enable_ssl_termination' | myLbTranslate }}">
                    <div class="form-group">
                        <label for="SSLMinVer{{templateIndex}}">{{ 'ssl_min_version' | myLbTranslate }}</label>
                        <div class="form-content">
                            <app-dropdown
                                dropDownId="SSLMinVer{{templateIndex}}"
                                [formGroup]="getFormGroup(subStepEnum.SSL)"
                                formControlName="SSLMinVer"
                                [hiddenContentKeys]="minHiddenContentKeys"
                                [content]="dropdown.sslValues">
                            </app-dropdown>
                            <app-help class="help-button" tag="l7-frontend_ssl-min-version"></app-help>
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="SSLMaxVer{{templateIndex}}">{{ 'ssl_max_version' | myLbTranslate }}</label>
                        <div class="form-content">
                            <app-dropdown
                                dropDownId="SSLMaxVer{{templateIndex}}"
                                [formGroup]="getFormGroup(subStepEnum.SSL)"
                                formControlName="SSLMaxVer"
                                [hiddenContentKeys]="maxHiddenContentKeys"
                                [content]="dropdown.sslValues">
                            </app-dropdown>
                            <app-help class="help-button" tag="l7-frontend_ssl-max-version"></app-help>
                        </div>
                    </div>
                    <div *ngIf="!isInputHidden('SSLTLSv130rttEnable')">
                        <div class="form-group">
                            <div class="form-content">
                                <input
                                    formControlName="SSLTLSv130rttEnable"
                                    id="SSLTLSv130rttEnable{{templateIndex}}"
                                    class="lb-checkbox"
                                    name="SSLTLSv130rttEnable"
                                    type="checkbox">
                                <label for="SSLTLSv130rttEnable{{templateIndex}}" class="lb-checkbox">
                                    {{ 'services_enable_zero_round_trips' | myLbTranslate }}
                                </label>
                            </div>
                        </div>
                        <div class="warning">
                            <span class="warning-title">{{ 'warning' | myLbTranslate }}!</span>
                            <span class="warning-message">{{ 'services_ensure_requests_untouched' | myLbTranslate }}
                                </span>
                        </div>
                    </div>
                </app-lb-checkbox>
            </div>
        </section>

        <!--HTTPS options-->
        <section data-control-name="httpsForceToRedirect">
            <div class="form-group" *ngIf="!isInputHidden('httpsForceToRedirect')">
                <div class="form-content">
                    <input
                        formControlName="httpsForceToRedirect"
                        id="httpsForceToRedirect{{templateIndex}}"
                        name="httpsForceToRedirect"
                        type="checkbox"
                        class="lb-checkbox">
                    <label for="httpsForceToRedirect{{templateIndex}}" class="lb-checkbox">
                        {{ 'services_force_to_https' | myLbTranslate }}
                    </label>
                    <app-help class="help-button" tag="l7-frontend_force-https"></app-help>
                </div>
            </div>
        </section>
        <section data-control-name="httpsRedirectCode">
            <div *ngIf="!isInputHidden('httpsRedirectCode')" class="form-group">
                <label for="httpsRedirectCode{{templateIndex}}">
                    {{ 'services_https_redirect_code' | myLbTranslate }}
                </label>
                <div class="form-content">
                    <app-dropdown
                        dropDownId="httpsRedirectCode{{templateIndex}}"
                        [formGroup]="getFormGroup(subStepEnum.SSL)"
                        formControlName="httpsRedirectCode"
                        [content]="dropdown.httpsRedirects">
                    </app-dropdown>
                    <app-help class="help-button" tag="l7-frontend_force-https-code"></app-help>
                </div>
            </div>
        </section>
        <section data-control-name="hstsEnable">
            <div *ngIf="!isInputHidden('hstsEnable')" class="form-group">
                <div class="form-content">
                    <input
                        formControlName="hstsEnable"
                        id="hstsEnable{{templateIndex}}"
                        name="hstsEnable"
                        type="checkbox"
                        class="lb-checkbox">
                    <label for="hstsEnable{{templateIndex}}" class="lb-checkbox">
                        {{ 'services_enable_hsts' | myLbTranslate }}
                    </label>
                    <app-help class="help-button" tag="l7-frontend_enable-hsts"></app-help>
                </div>
            </div>
        </section>
        <section data-control-name="hstsTimeout">
            <div *ngIf="!isInputHidden('hstsTimeout')" class="form-group">
                <label for="hstsTimeout{{templateIndex}}">{{ 'services_hsts_timeout' | myLbTranslate }}*</label>
                <div class="form-content">
                    <input
                        formControlName="hstsTimeout"
                        id="hstsTimeout{{templateIndex}}"
                        name="hstsTimeout"
                        type="number">
                    <span>{{ 'months' | myLbTranslate }}</span>
                    <app-validation-messages [control]="serviceForm.get(subStepEnum.SSL + '.hstsTimeout')">
                    </app-validation-messages>
                    <app-help class="help-button" tag="l7-frontend_hsts-timeout"></app-help>
                </div>
            </div>
        </section>
    </div>

    <!-- New SSL Certificates section  -->
    <div [formGroupName]="subStepEnum.CERTIFICATES" [hidden]="getWizardSubStepState(subStepEnum.CERTIFICATES)">
        <section data-control-name="sslCerts">
            <div class="embedded" *ngIf="!isInputHidden('sslCerts')">
                <app-lb-ssl-offload-options
                    [minCipherSecVersion]="serviceForm.get('ssl.SSLMinVer').value"
                    [maxCipherSecVersion]="serviceForm.get('ssl.SSLMaxVer').value"
                    [componentEmbedded]="true"
                    [SSLCerts]="SSLCerts"
                    [selectedKey]="serviceForm.get(subStepEnum.CERTIFICATES + '.sslCerts').value"
                    [validationError]="SSLCertsValidation"
                    (sslCerts)="setSslCerts($event)">
                </app-lb-ssl-offload-options>
            </div>
        </section>
    </div>

    <!-- HTTP Connection start -->
    <div
        [formGroupName]="subStepEnum.HTTP_CONNECTIONS"
        [hidden]="getWizardSubStepState(subStepEnum.HTTP_CONNECTIONS)">
        <h2>{{ subStepEnum.HTTP_CONNECTIONS | myLbTranslate }}</h2>
        <section data-control-name="httpPipeLine">
            <div *ngIf="!isInputHidden('httpPipeLine')" class="form-group">
                <label>{{ 'services_http_pipeline_mode' | myLbTranslate }}</label>
                <div class="form-content">
                    <app-dropdown
                        dropDownId="httpPipeLine{{templateIndex}}-dropdown"
                        [formGroup]="getFormGroup(subStepEnum.HTTP_CONNECTIONS)"
                        formControlName="httpPipeLine"
                        [content]="dropdown.httpPipeLineModes">
                    </app-dropdown>
                    <app-help class="help-button" tag="l7-frontend_http-pipeline-mode"></app-help>
                </div>
            </div>
        </section>
        <section data-control-name="httpAcceptInvalidRequest">
            <div *ngIf="!isInputHidden('httpAcceptInvalidRequest')" class="form-group">
                <div class="form-content">
                    <input
                        formControlName="httpAcceptInvalidRequest"
                        id="httpAcceptInvalidRequest{{templateIndex}}"
                        name="httpAcceptInvalidRequest"
                        type="checkbox"
                        class="lb-checkbox">
                    <label for="httpAcceptInvalidRequest{{templateIndex}}" class="lb-checkbox">
                        {{ 'services_accept_invalid_http_requests' | myLbTranslate }}
                    </label>
                    <app-help class="help-button" tag="l7-frontend_invalid-http-requests"></app-help>
                </div>
            </div>
        </section>
        <section data-control-name="httpRequestTimeout">
            <div *ngIf="!isInputHidden('httpRequestTimeout')" class="form-group">
                <div class="form-content">
                    <input
                        formControlName="httpRequestTimeout"
                        id="httpRequestTimeout{{templateIndex}}"
                        class="lb-checkbox"
                        name="httpRequestTimeout"
                        type="checkbox">
                    <label for="httpRequestTimeout{{templateIndex}}" class="lb-checkbox">
                        {{ 'services_http_request_timeout' | myLbTranslate }}
                    </label>
                    <app-help class="help-button" tag="l7-frontend_http-request-timeout"></app-help>
                </div>
            </div>
        </section>
        <section data-control-name="httpPretendKeepalive">
            <div *ngIf="!isInputHidden('httpPretendKeepalive')" class="form-group">
                <div class="form-content">
                    <input
                        formControlName="httpPretendKeepalive"
                        id="httpPretendKeepalive{{templateIndex}}"
                        name="httpPretendKeepalive"
                        type="checkbox"
                        class="lb-checkbox">
                    <label for="httpPretendKeepalive{{templateIndex}}" class="lb-checkbox">
                        {{ 'services_http_pretend_keepalive' | myLbTranslate }}
                    </label>
                    <app-help class="help-button" tag="l7-frontend_http-pretend-keepalive"></app-help>
                </div>
            </div>
        </section>
        <section data-control-name="setXForwardedforHeader">
            <div *ngIf="!isInputHidden('setXForwardedforHeader')" class="form-group">
                <div class="form-content">
                    <input
                        formControlName="setXForwardedforHeader"
                        id="setXForwardedforHeader{{templateIndex}}"
                        name="setXForwardedforHeader"
                        type="checkbox"
                        class="lb-checkbox">
                    <label for="setXForwardedforHeader{{templateIndex}}" class="lb-checkbox">
                        {{ 'services_enable_x_forwarded' | myLbTranslate }}
                    </label>
                    <app-help class="help-button" tag="l7-frontend_xforward-for-header"></app-help>
                </div>
            </div>
        </section>
    </div>

    <!-- Client Connections start -->
    <div
        [formGroupName]="subStepEnum.CLIENT_CONNECTIONS"
        [hidden]="getWizardSubStepState(subStepEnum.CLIENT_CONNECTIONS)">
        <h2>{{ subStepEnum.CLIENT_CONNECTIONS | myLbTranslate }}</h2>
        <section data-control-name="maximumConnections">
            <div *ngIf="!isInputHidden('maximumConnections')" class="form-group">
                <label for="maximumConnections{{templateIndex}}">{{ 'max_conns' | myLbTranslate }}*
                </label>
                <div class="form-content">
                    <input
                        formControlName="maximumConnections"
                        id="maximumConnections{{templateIndex}}"
                        name="maximumConnections"
                        type="text">
                    <app-validation-messages
                        [control]="serviceForm.get(subStepEnum.CLIENT_CONNECTIONS + '.maximumConnections')">
                    </app-validation-messages>
                    <app-help class="help-button" tag="l7-frontend_maximum-connections"></app-help>
                </div>
            </div>
        </section>
        <section data-control-name="enableClientConnTimeout">
            <app-lb-checkbox
                label="{{ 'services_enable_client_conn_timeout' | myLbTranslate }}"
                [formGroup]="getFormGroup(subStepEnum.CLIENT_CONNECTIONS)"
                formControlName="enableClientConnTimeout"
                id="enableConnectTimeout{{templateIndex}}">
                <div *ngIf="!isInputHidden('clientTimeout')">
                    <div class="form-group">
                        <label for="clientTimeout{{templateIndex}}">
                            {{ 'services_client_connection_timeout' | myLbTranslate }}
                        </label>
                        <div class="form-content">
                            <app-time-selector
                                id="clientTimeout{{templateIndex}}"
                                [formGroup]="getFormGroup(subStepEnum.CLIENT_CONNECTIONS)"
                                formControlName="clientTimeout">
                            </app-time-selector>
                            <app-help
                                class="help-button"
                                tag="l7-frontend_client-timeout">
                            </app-help>
                        </div>
                    </div>
                </div>
            </app-lb-checkbox>
        </section>
    </div>
</form>

