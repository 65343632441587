<div *ngIf="logsLoaded" class="component-main">
    <div class="content">
        <app-lb-accordion>
            <app-lb-accordion-group
                title="<h1>{{ 'logs_log_config' | myLbTranslate }}</h1>"
                [open]="configOpen"
                [arrowNextToTitle]="true">
                <form [formGroup]="logsForm">
                    <div class="content-space-between">
                        <div class="services-wrapper">
                            <div class="section-header">
                                <span>{{ 'services' | myLbTranslate }}</span>
                                <button
                                    class="button-as-link"
                                    [disabled]="selectAllToggle"
                                    (click)="toggleSelectedServices(true)">
                                    {{ 'select_all' | myLbTranslate }}
                                </button>
                                <button
                                    class="button-as-link"
                                    [disabled]="clearAllActive"
                                    (click)="toggleSelectedServices(false)">
                                    {{ 'logs_clear_services' | myLbTranslate }}
                                </button>
                            </div>
                            <div class="section-inputs">
                                <div
                                    *ngFor="let service of servicesArray.controls; index as i"
                                    class="service-checkbox-wrapper"
                                    formArrayName="services">
                                    <div [formGroupName]="i.toString()" class="form-group">
                                        <div class="form-content">
                                            <input
                                                [id]="service.value.id"
                                                formControlName="selected"
                                                class="lb-checkbox"
                                                type="checkbox">
                                            <label [for]="service.value.name" class="lb-checkbox">
                                                {{ service.value.fullName }}
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="services-wrapper">
                            <div class="section-header">
                            </div>
                            <div class="lines-wrapper">
                                <p>{{ 'lines' | myLbTranslate }}</p>
                                <input type="number" formControlName="lines" name="lines" id="lines" min="1">
                            </div>
                            <div class="start-wrapper">
                                <p>{{ 'logs_start' | myLbTranslate }}</p>
                                <div class="form-group">
                                    <div class="form-row">
                                        <div class="form-content">
                                            <input
                                                id="logs-date"
                                                type="date"
                                                formControlName="endDate"
                                                [min]="minDate"
                                                [max]="maxDate"/>
                                            <app-validation-messages [control]="logsForm.controls['endDate']">
                                            </app-validation-messages>
                                        </div>
                                        <div class="form-content time">
                                            <input
                                                title="Time"
                                                type="time"
                                                name="time"
                                                formControlName="endTime">
                                            <app-validation-messages [control]="logsForm.controls['endTime']">
                                            </app-validation-messages>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="services-wrapper">
                            <button
                                id="logs-update-logs"
                                class="primary"
                                [disabled]="isButtonDisabled('logs-update-logs') || logsForm.invalid"
                                (click)="getLogs()">
                                {{ 'update' | myLbTranslate }}
                            </button>
                        </div>
                    </div>
                </form>
            </app-lb-accordion-group>
        </app-lb-accordion>
    </div>
    <div class="content-space-between">
        <button
            id="logs-back"
            class="primary"
            [disabled]="isButtonDisabled('logs-back')"
            (click)="goBackInLogs()">
            {{ 'back' | myLbTranslate }}
        </button>
        <p *ngIf="noMoreLogs" class="no-more-logs">{{ 'logs_no_more' | myLbTranslate }}</p>
        <button
            id="logs-forward"
            class="primary"
            [disabled]="isButtonDisabled('logs-forward')"
            (click)="goForwardInLogs()">
            {{ 'forward' | myLbTranslate }}
        </button>
    </div>
    <app-table
        id="logs-list"
        [data]="tableRows">
        <app-column [flexGrow]="1.25" prop="datetime" label="logs_date_time_format"></app-column>
        <app-column [flexGrow]="0.75" prop="comm" label="logs_comm"></app-column>
        <app-column [flexGrow]="0.75" prop="service" label="service"></app-column>
        <app-column [flexGrow]="0.5" prop="gid" label="gid" [columnHidden]="true"></app-column>
        <app-column [flexGrow]="1" prop="hostname" label="hostname" [columnHidden]="true"></app-column>
        <app-column [flexGrow]="2.25" prop="message" label="logs_message"></app-column>
        <app-column [flexGrow]="0.5" prop="pid" label="logs_pid" [columnHidden]="true"></app-column>
        <app-column [flexGrow]="0.5" prop="uid" label="logs_uid" [columnHidden]="true"></app-column>
    </app-table>
</div>

<ng-container *ngIf="!logsLoaded && !errorLoaded">
    <div class="content-vertical-center loader">
        <p>{{ 'logs_fetching_data' | myLbTranslate }}</p>
        <img ngSrc="assets/shared/icons/loading-spinner-primary.svg" alt="loading spinner" height="20" width="20"/>
    </div>
</ng-container>

<ng-container *ngIf="errorLoaded">
    <div class="content-vertical-center loader">
        <p>{{ 'logs_error_data' | myLbTranslate }}</p>
    </div>
</ng-container>
