<div class="component-main">
    <div class="content-space-between">
        <div class="content-left">
            <img
                *ngIf="selectedAdc.productVendor"
                class="company-logo"
                src="assets/centralisedManagement/icons/{{getVendorIconName(selectedAdc.productVendor)}}-colour.svg"
                alt="{{getVendorTitle(selectedAdc.productVendor)}} Logo"
                title="{{getVendorTitle(selectedAdc.productVendor)}}"/>
            <h1 id="component-title">{{ selectedAdc.adcLabel }}</h1>
        </div>
        <div class="content-right">
            <button
                class="outline"
                (click)="getHelp()">
                {{ 'security_need_help' | myLbTranslate }}
            </button>
            <app-loading-button
                buttonId="security-update-adc"
                buttonText="security_update_adc"
                buttonClass="primary"
                [callBack]="systemUpdateAdc.bind(this, selectedAdc)"
                [isDisabled]="!permissionsLookup['security-update-adc'] || selectedAdc.productVendor !== 5">
            </app-loading-button>
        </div>
    </div>
    <div class="content no-padding title {{selectedAdc.baseSeverity | lowercase}}">
        <img
            ngSrc="./assets/shared/icons/notification-solid-exclamation-circle.svg"
            alt="Expiry icon"
            height="20"
            width="20"/>
        {{ selectedAdc.severityScore }}
        <h5>{{ selectedAdc.severityScore }} {{ 'security_operational_risk' | myLbTranslate }}</h5>
        <h5 class="message">- {{ getRecommendationText(selectedAdc.baseSeverity) }}</h5>
    </div>
    <app-table
        name="security-details"
        [data]="tableRows"
        [showPaginator]="false"
        [allowMultiSelect]="false"
        (selectedRowsOut)="openDrawer($event[0])">
        <app-column [flexGrow]="0.5" prop="cveId" label="security_vulnerability_id" [customCellTemplate]="cveId">
            <ng-template let-row #cveId>
                <span class="routerLink" title="{{row.cveId}}">
                    {{ row.cveId }}
                </span>
            </ng-template>
        </app-column>
        <app-column
            [flexGrow]="0.25"
            prop="severityScore"
            label="security_severity_score"
            [customCellTemplate]="score">
            <ng-template let-row #score>
                <div class="security-risk-{{getSeverityScoreClass(row.severityScore)}}">
                    {{ row.severityScore }}
                </div>
            </ng-template>
        </app-column>
        <app-column [flexGrow]="2" prop="vulnerabilityInfo" label="security_vulnerability_info"></app-column>
    </app-table>
</div>

<ng-template #vulnerabilityInfo>
    <div *ngIf="templateContent" id="drawer-template">
        <div>
            <span class="notes"><strong>{{ 'description' | myLbTranslate }}</strong></span>
            <p>{{ templateContent.vulnerabilityInfo }}</p>
            <p>{{ templateContent.vectorString }}</p>
            <span class="notes"><strong>{{ 'security_how_to_fix' | myLbTranslate }}</strong></span>
            <p>{{ getRecommendationText(templateContent.baseSeverity) }}</p>
            <table>
                <tr>
                    <th>{{ 'security_severity_score' | myLbTranslate }}</th>
                    <td class="right">
                        <div class="security-risk-{{getSeverityScoreClass(templateContent.severityScore)}}">
                            {{ templateContent.severityScore }}
                        </div>
                    </td>
                </tr>
                <tr>
                    <th>{{ 'vendor' | myLbTranslate }}</th>
                    <td class="right">
                        <img
                            class="company-logo"
                            src="assets/centralisedManagement/icons/{{getVendorIconName(templateContent.productVendor)}}-colour.svg"
                            alt="{{getVendorTitle(templateContent.productVendor)}} Logo"
                            title="{{getVendorTitle(templateContent.productVendor)}}"/>
                    </td>
                </tr>
                <tr>
                    <th>{{ 'product' | myLbTranslate }}</th>
                    <td class="right">
                        {{ templateContent.productName | titlecase }}
                    </td>
                </tr>
                <tr>
                    <th>{{ 'security_affected_version' | myLbTranslate }}</th>
                    <td class="right">
                        {{ templateContent.affectedVersion }}
                    </td>
                </tr>
                <tr>
                    <th>{{ 'security_published' | myLbTranslate }}</th>
                    <td class="right">
                        {{ templateContent.published }}
                    </td>
                </tr>
                <tr>
                    <th>{{ 'security_published_last' | myLbTranslate }}</th>
                    <td class="right">
                        {{ templateContent.lastModified }}
                    </td>
                </tr>
                <tr>
                    <th>{{ 'security_publisher' | myLbTranslate }}</th>
                    <td class="right">
                        {{ templateContent.sourceIdentifier }}
                    </td>
                </tr>
                <tr>
                    <th>{{ 'security_cve_details' | myLbTranslate }}</th>
                    <td class="right">
                        <a
                            class="external"
                            href="https://www.cve.org/CVERecord?id={{templateContent.cveId}}"
                            target="_blank">
                            {{ templateContent.cveId }}
                        </a>
                    </td>
                </tr>
                <tr>
                    <th>{{ 'security_nist_details' | myLbTranslate }}</th>
                    <td class="right">
                        <a
                            class="external"
                            href="https://nvd.nist.gov/vuln/detail/{{templateContent.cveId}}"
                            target="_blank">
                            {{ templateContent.cveId }}
                        </a>
                    </td>
                </tr>
                <ng-container *ngIf="templateContent.references.length">
                    <tr *ngFor="let reference of templateContent.references; index as i">
                        <th>
                            <ng-container *ngIf="i === 0">
                                {{ 'references' | myLbTranslate }}
                            </ng-container>
                        </th>
                        <td class="right">
                            <a [href]="reference.url" class="external" target="_blank">{{ reference.domain }}</a>
                        </td>
                    </tr>
                </ng-container>
            </table>
        </div>
    </div>
</ng-template>
