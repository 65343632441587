<div class="component-main">
    <div class="content">
        <h1>{{ (editId ? 'gslb_edit_topology' : 'gslb_add_topology') | myLbTranslate }}</h1>
    </div>
    <form [formGroup]="topologyForm" id="geo_form" name="geo_form">
        <div class="form-group">
            <label for="geo_ip">{{ 'gslb_ip_subnet' | myLbTranslate }}*</label>
            <div class="form-content">
                <input formControlName="ip" id="geo_ip" name="geo_ip" type="text" value="">
                <app-validation-messages [control]="topologyForm.get('ip')"></app-validation-messages>
                <app-help class="help-button" tag="topologies_ip-subnet"></app-help>
            </div>
        </div>
        <div class="form-group">
            <label for="geo_mask">{{ 'mask' | myLbTranslate }}*</label>
            <div class="form-content">
                <input formControlName="mask" id="geo_mask" name="geo_mask" type="number" value="">
                <app-validation-messages [control]="topologyForm.get('mask')"></app-validation-messages>
                <app-help class="help-button" tag="topologies_mask"></app-help>
            </div>
        </div>
        <div class="form-group">
            <!--suppress XmlInvalidId -->
            <label for="gslb-topology">{{ 'members' | myLbTranslate }}*</label>
            <div class="form-content dropdown">
                <app-dropdown
                    dropDownId="gslb-topology"
                    [formGroup]="topologyForm"
                    [displayAsInput]="true"
                    formControlName="member"
                    [content]="dropdown.members">
                </app-dropdown>
                <app-help class="help-button" tag="topologies_members"></app-help>
            </div>
        </div>
    </form>
</div>
<div class="component-footer">
    <button class="outline" [routerLink]="editId ? '../../..' : '../..'" [queryParams]="{sFilter: 'topologies'}">
        {{ 'cancel' | myLbTranslate }}
    </button>
    <app-loading-button
        buttonText="save"
        buttonClass="primary"
        [isDisabled]="topologyForm.pristine || topologyForm.invalid"
        [callBack]="submitTopology.bind(this)">
    </app-loading-button>
</div>
