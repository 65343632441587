<h1>{{ 'services_configuration' | myLbTranslate }}</h1>
<form [formGroup]="serviceForm">
    <!-- General Options -->
    <div [formGroupName]="subStepEnum.GENERAL" [hidden]="getWizardSubStepState(subStepEnum.GENERAL)">
        <h2>{{ subStepEnum.GENERAL | myLbTranslate }}</h2>
        <section data-control-name="name">
            <div *ngIf="!isInputHidden('name')" class="form-group">
                <label for="backendLabel{{templateIndex}}">{{ 'name' | myLbTranslate }}</label>
                <div class="form-content">
                    <ng-container *ngIf="namePrefix">
                        <span class="name">{{ namePrefix }}</span>
                    </ng-container>
                    <input
                        id="backendLabel{{templateIndex}}"
                        name="name"
                        title="{{ 'name' | myLbTranslate }}"
                        formControlName="name"
                        type="text">
                    <app-validation-messages [control]="serviceForm.get(subStepEnum.GENERAL + '.name')">
                    </app-validation-messages>
                    <app-help class="help-button" tag="l4-backend_name"></app-help>
                </div>
            </div>
        </section>
        <section data-control-name="balanceMode">
            <div *ngIf="!isInputHidden('balanceMode')">
                <h2>{{ 'services_conn_distribution_method' | myLbTranslate }}</h2>
                <div class="form-group">
                    <label for="balanceMode{{templateIndex}}">{{ 'services_balance_mode' | myLbTranslate }}</label>
                    <div class="form-content">
                        <app-dropdown
                            dropDownId="balanceMode{{templateIndex}}"
                            [formGroup]="getFormGroup(subStepEnum.GENERAL)"
                            formControlName="balanceMode"
                            [content]="dropdown.balanceModes">
                        </app-dropdown>
                        <app-help class="help-button" tag="l4-backend_balance-mode"></app-help>
                    </div>
                </div>
            </div>
        </section>
    </div>

    <!-- Persistence options -->
    <div [formGroupName]="subStepEnum.PERSISTENCE" [hidden]="getWizardSubStepState(subStepEnum.PERSISTENCE)">
        <h2>{{ subStepEnum.PERSISTENCE | myLbTranslate }}</h2>
        <section data-control-name="persistenceEnabled">
            <div *ngIf="!isInputHidden('persistenceEnabled')">
                <app-lb-checkbox
                    helpTag="l4-backend_persistence-enable"
                    [showHiddenContent]="isInputHidden('persistenceEnabled')"
                    [formGroup]="getFormGroup(subStepEnum.PERSISTENCE)"
                    formControlName="persistenceEnabled"
                    label="{{ 'enable' | myLbTranslate }} {{ 'persistence' | myLbTranslate }}">
                    <div class="form-group">
                        <label for="persistenceTimeout{{templateIndex}}">
                            {{ 'services_timeout' | myLbTranslate }} ({{ 'seconds' | myLbTranslate }})
                        </label>
                        <div class="form-content">
                            <input
                                formControlName="persistenceTimeout"
                                id="persistenceTimeout"
                                name="persistenceTimeout"
                                type="number"
                                [style.width.px]="80">
                            <app-validation-messages
                                [control]="serviceForm.get(subStepEnum.PERSISTENCE + '.persistenceTimeout')">
                            </app-validation-messages>
                            <app-help class="help-button" tag="l4-backend_persistence-timeout"></app-help>
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="granularity{{templateIndex}}">{{ 'granularity' | myLbTranslate }}</label>
                        <div class="form-content">
                            <input
                                formControlName="granularity"
                                id="granularity"
                                name="granularity"
                                type="text"
                                maxlength="3"
                                [style.width.px]="80">
                            <app-validation-messages
                                [control]="serviceForm.get(subStepEnum.PERSISTENCE + '.granularity')">
                            </app-validation-messages>
                            <app-help class="help-button" tag="l4-backend_persistence-granularity"></app-help>
                        </div>
                    </div>
                </app-lb-checkbox>
            </div>
        </section>
    </div>
    <!-- Health check options -->
    <div [formGroupName]="subStepEnum.HEALTH" [hidden]="getWizardSubStepState(subStepEnum.HEALTH)">
        <h2>{{ subStepEnum.HEALTH | myLbTranslate }}</h2>
        <section data-control-name="healthCheckType">
            <div class="form-group">
                <label for="healthCheckType{{templateIndex}}">{{ 'services_health_check_type' | myLbTranslate }}</label>
                <div class="form-content">
                    <app-dropdown
                        dropDownId="healthCheckType{{templateIndex}}"
                        [formGroup]="getFormGroup(subStepEnum.HEALTH)"
                        formControlName="healthCheckType"
                        [dropdownWidth]="220"
                        [search]="true"
                        [content]="dropdown.healthCheckTypes">
                    </app-dropdown>
                    <app-help class="help-button" tag="l4-backend_health-check-type"></app-help>
                </div>
            </div>
        </section>
        <section data-control-name="healthCheckPort" [hidden]="healthCheckFieldsLookup['healthCheckPort'].hidden">
            <div class="form-group">
                <label for="healthCheckPort{{templateIndex}}">{{ 'services_check_port' | myLbTranslate }}</label>
                <div class="form-content">
                    <input
                        id="healthCheckPort{{templateIndex}}"
                        type="number"
                        class="port"
                        name="healthCheckPort"
                        formControlName="healthCheckPort"/>
                    <app-validation-messages [control]="serviceForm.get(subStepEnum.HEALTH + '.healthCheckPort')">
                    </app-validation-messages>
                    <app-help class="help-button" tag="l4-backend_check-port"></app-help>
                </div>
            </div>
        </section>
        <section
            data-control-name="healthCheckProtocol"
            [hidden]="healthCheckFieldsLookup['healthCheckProtocol'].hidden">
            <div class="form-group">
                <label for="healthCheckProtocol{{templateIndex}}">{{ 'protocol' | myLbTranslate }}*</label>
                <div class="form-content">
                    <app-dropdown
                        dropDownId="healthCheckProtocol{{templateIndex}}"
                        [formGroup]="getFormGroup(subStepEnum.HEALTH)"
                        formControlName="healthCheckProtocol"
                        [dropdownWidth]="220"
                        [search]="true"
                        [content]="dropdown.protocols">
                    </app-dropdown>
                    <app-help class="help-button" tag="l4-backend_protocol"></app-help>
                </div>
            </div>
        </section>
        <section
            data-control-name="radius"
            [hidden]="healthCheckFieldsLookup['healthCheckRadiusSecret'].hidden">
            <div class="form-group">
                <label for="healthCheckRadiusSecret{{templateIndex}}">
                    {{ 'health_checks_radius_secret' | myLbTranslate }}*
                </label>
                <div class="form-content">
                    <input
                        id="healthCheckRadiusSecret{{templateIndex}}"
                        type="text"
                        formControlName="healthCheckRadiusSecret"
                        name="healthCheckRadiusSecret"
                        autocomplete="off"/>
                    <app-validation-messages
                        [control]="serviceForm.get(subStepEnum.HEALTH + '.healthCheckRadiusSecret')">
                    </app-validation-messages>
                    <app-help class="help-button" tag="l4-backend_radius-secret"></app-help>
                </div>
            </div>
        </section>
        <section
            data-control-name="databaseName"
            [hidden]="healthCheckFieldsLookup['healthCheckDatabaseName'].hidden">
            <div class="form-group">
                <label for="healthCheckDatabaseName{{templateIndex}}">
                    {{ 'database_name' | myLbTranslate }}*
                </label>
                <div class="form-content">
                    <input
                        id="healthCheckDatabaseName{{templateIndex}}"
                        type="text"
                        formControlName="healthCheckDatabaseName"
                        name="healthCheckDatabaseName"
                        autocomplete="off"/>
                    <app-validation-messages
                        [control]="serviceForm.get(subStepEnum.HEALTH + '.healthCheckDatabaseName')">
                    </app-validation-messages>
                    <app-help class="help-button" tag="l4-backend_database-name"></app-help>
                </div>
            </div>
        </section>
        <section
            data-control-name="authentication"
            [hidden]="healthCheckFieldsLookup['healthCheckUsername'].hidden">
            <div class="form-group">
                <label for="healthCheckUsername{{templateIndex}}">{{ 'username' | myLbTranslate }}*</label>
                <div class="form-content">
                    <input
                        id="healthCheckUsername{{templateIndex}}"
                        type="text"
                        formControlName="healthCheckUsername"
                        name="healthCheckUsername"
                        autocomplete="off"/>
                    <app-validation-messages [control]="serviceForm.get(subStepEnum.HEALTH + '.healthCheckUsername')">
                    </app-validation-messages>
                    <app-help class="help-button" tag="l4-backend_username"></app-help>
                </div>
            </div>
            <div class="form-group">
                <label for="healthCheckPassword{{templateIndex}}">{{ 'password' | myLbTranslate }}*</label>
                <div class="form-content">
                    <app-lb-reveal-password
                        id="healthCheckPassword{{templateIndex}}"
                        title="{{'password' | myLbTranslate }}"
                        formControlName="healthCheckPassword"
                        [formGroup]="getFormGroup(subStepEnum.HEALTH)">
                    </app-lb-reveal-password>
                    <app-help class="help-button" tag="l4-backend_password"></app-help>
                </div>
            </div>
        </section>
        <section
            data-control-name="healthCheckHostHeader"
            [hidden]="healthCheckFieldsLookup['healthCheckHostHeader'].hidden">
            <div class="form-group">
                <label for="healthCheckHostHeader{{templateIndex}}">{{ 'services_host_header' | myLbTranslate }}
                    *</label>
                <div class="form-content">
                    <input
                        id="healthCheckHostHeader{{templateIndex}}"
                        type="text"
                        name="healthCheckHostHeader"
                        formControlName="healthCheckHostHeader"/>
                    <app-validation-messages [control]="serviceForm.get(subStepEnum.HEALTH + '.healthCheckHostHeader')">
                    </app-validation-messages>
                    <app-help class="help-button" tag="l4-backend_host-header"></app-help>
                </div>
            </div>
        </section>
        <section
            data-control-name="healthCheckRequestToSend"
            [hidden]="healthCheckFieldsLookup['healthCheckRequestToSend'].hidden">
            <div class="form-group">
                <label for="healthCheckRequestToSend{{templateIndex}}">
                    {{ 'services_request_to_send' | myLbTranslate }}*
                </label>
                <div class="form-content">
                    <input
                        id="healthCheckRequestToSend{{templateIndex}}"
                        type="text"
                        name="healthCheckRequestToSend"
                        formControlName="healthCheckRequestToSend"/>
                    <app-validation-messages
                        [control]="serviceForm.get(subStepEnum.HEALTH + '.healthCheckRequestToSend')">
                    </app-validation-messages>
                    <app-help class="help-button" tag="l4-backend_request-to-send"></app-help>
                </div>
            </div>
        </section>
        <section
            data-control-name="healthCheckResponseExpected"
            [hidden]="healthCheckFieldsLookup['healthCheckResponseExpected'].hidden">
            <div class="form-group">
                <label for="healthCheckResponseExpected{{templateIndex}}">
                    {{ 'services_response_expected' | myLbTranslate }}*
                </label>
                <div class="form-content">
                    <input
                        id="healthCheckResponseExpected{{templateIndex}}"
                        type="text"
                        name="healthCheckResponseExpected"
                        formControlName="healthCheckResponseExpected"/>
                    <app-validation-messages
                        [control]="serviceForm.get(subStepEnum.HEALTH + '.healthCheckResponseExpected')">
                    </app-validation-messages>
                    <app-help class="help-button" tag="l4-backend_response-expected"></app-help>
                </div>
            </div>
        </section>
        <section
            data-control-name="healthCheckScriptId"
            [hidden]="healthCheckFieldsLookup['healthCheckScriptId'].hidden">
            <div class="form-group">
                <label>{{ 'services_external_check_script' | myLbTranslate }}*</label>
                <div class="form-content">
                    <app-dropdown
                        dropDownId="healthCheckScriptId{{templateIndex}}-dropdown"
                        [formGroup]="getFormGroup(subStepEnum.HEALTH)"
                        formControlName="healthCheckScriptId"
                        [dropdownWidth]="220"
                        [content]="dropdown.checkScripts">
                    </app-dropdown>
                    <app-help class="help-button" tag="l4-backend_external-check-script"></app-help>
                </div>
            </div>
        </section>
    </div>

    <div [formGroupName]="subStepEnum.FEEDBACK" [hidden]="getWizardSubStepState(subStepEnum.FEEDBACK)">
        <h2>{{ subStepEnum.FEEDBACK | myLbTranslate }}</h2>
        <section data-control-name="feedbackMethod">
            <div class="form-group">
                <label for="feedbackMethod{{templateIndex}}">{{ 'feedback_method' | myLbTranslate }}</label>
                <div class="form-content">
                    <app-dropdown
                        dropDownId="feedbackMethod{{templateIndex}}"
                        [formGroup]="getFormGroup(subStepEnum.FEEDBACK)"
                        formControlName="feedbackMethod"
                        [dropdownWidth]="220"
                        [search]="true"
                        [content]="dropdown.feedbackMethods">
                    </app-dropdown>
                    <app-help class="help-button" tag="l4-backend_feedback-method"></app-help>
                </div>
            </div>
        </section>
        <section data-control-name="feedbackAgentPort" *ngIf="showFeedbackPort">
            <div class="form-group">
                <label for="feedbackAgentPort{{templateIndex}}">{{ 'feedback_agent_port' | myLbTranslate }}*</label>
                <div class="form-content">
                    <input
                        id="feedbackAgentPort{{templateIndex}}"
                        type="number"
                        name="feedbackAgentPort"
                        class="port"
                        formControlName="feedbackAgentPort"/>
                    <app-validation-messages [control]="serviceForm.get(subStepEnum.FEEDBACK + '.feedbackAgentPort')">
                    </app-validation-messages>
                    <app-help class="help-button" tag="l4-backend_feedback-agent-port"></app-help>
                </div>
            </div>
        </section>
    </div>

    <div [formGroupName]="subStepEnum.FALLBACK" [hidden]="getWizardSubStepState(subStepEnum.FALLBACK)">
        <h2>{{ subStepEnum.FALLBACK | myLbTranslate }}</h2>
        <section data-control-name="fallbackServerId">
            <div class="form-group">
                <label for="fallbackServerId{{templateIndex}}">{{ 'fallback_server' | myLbTranslate }}*</label>
                <div class="form-content">
                    <app-dropdown
                        dropDownId="fallbackServerId{{templateIndex}}"
                        [formGroup]="getFormGroup(subStepEnum.FALLBACK)"
                        formControlName="fallbackServerId"
                        [dropdownWidth]="220"
                        [search]="true"
                        [content]="dropdown.fallbackServers">
                    </app-dropdown>
                    <app-help class="help-button" tag="l4-backend_fallback-server-id"></app-help>
                </div>
            </div>
        </section>
        <ng-container *ngIf="showFallbackPort">
            <section data-control-name="fallbackPort">
                <div class="form-group">
                    <label for="fallbackPort{{templateIndex}}">{{ 'port' | myLbTranslate }}*</label>
                    <div class="form-content">
                        <input
                            id="fallbackPort{{templateIndex}}"
                            type="number"
                            name="fallbackPort"
                            class="port"
                            formControlName="fallbackPort"/>
                        <app-validation-messages [control]="serviceForm.get(subStepEnum.FALLBACK + '.fallbackPort')">
                        </app-validation-messages>
                        <app-help class="help-button" tag="l4-backend_fallback-port"></app-help>
                    </div>
                </div>
            </section>
            <section data-control-name="enabledMASQFallback">
                <div class="form-group">
                    <div class="form-content">
                        <input
                            id="enabledMASQFallback{{templateIndex}}"
                            type="checkbox"
                            name="enabledMASQFallback"
                            class="lb-checkbox"
                            formControlName="enabledMASQFallback"/>
                        <label for="enabledMASQFallback{{templateIndex}}">{{ 'fallback_masq' | myLbTranslate }}</label>
                        <app-help class="help-button" tag="l4-backend_enable-masq"></app-help>
                    </div>
                </div>
            </section>
        </ng-container>
    </div>
</form>
