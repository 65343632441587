<ng-container *ngIf="!tableRows?.length else table">
    <ng-container *ngIf="isLoading else emptyStates">
        <div class="loader-wrapper">
            <img
                class="loader"
                ngSrc="assets/shared/icons/loading-spinner-primary.svg"
                height="40"
                width="40"
                alt="loading spinner"/>
        </div>
    </ng-container>
    <ng-template #emptyStates>
        <app-alternative-state-page
            header="backups"
            titleText="storage_empty_title"
            messageHtml="{{ 'storage_empty_message' | myLbTranslate }}"
            [mockTableHeaders]="['product_name', 'product_type', 'date_time', 'file_size', 'backups_used']"
            imagePath="assets/centralisedManagement/empty-state/backups.svg">
        </app-alternative-state-page>
    </ng-template>
</ng-container>
<ng-template #table>
    <div class="component-main">
        <div class="content-space-between">
            <h1 id="component-title">{{ 'backups' | myLbTranslate }}</h1>
        </div>
        <form [formGroup]="filterForm">
            <div class="form-group">
                <!--suppress XmlInvalidId -->
                <label for="backups-product-type">{{ 'product_type' | myLbTranslate }}</label>
                <div class="form-content">
                    <app-dropdown
                        dropDownId="backups-product-type"
                        [dropdownWidth]="125"
                        [formGroup]="filterForm"
                        formControlName="productType"
                        [content]="dropdown.productType">
                    </app-dropdown>
                </div>
            </div>
        </form>
        <app-table
            name="backups-list"
            [data]="tableRows"
            [allowMultiSelect]="false"
            [showPaginator]="false"
            [stickyHeader]="true"
            [searchTerm]="searchTerm">
            <app-column [flexGrow]="1.5" prop="productName" label="product_name" [customCellTemplate]="productName">
                <ng-template let-row #productName>
                    <span class="routerLink" [routerLink]="'/cm/storage/backups/' + row.adcId">
                        {{ row.productName }}
                    </span>
                </ng-template>
            </app-column>
            <app-column [flexGrow]="1" prop="productType" label="product_type"></app-column>
            <app-column [flexGrow]="1" prop="backupDateTime" label="date_time"></app-column>
            <app-column [flexGrow]="1" prop="size" label="file_size"></app-column>
            <app-column [flexGrow]="1" prop="backupsUsed" label="backups_used"></app-column>
            <app-column [flexGrow]="1" prop="actions" label="" cellAlignment="right" [customCellTemplate]="actions">
                <ng-template let-row #actions>
                    <app-action-dots>
                        <button id="storage-backup-download" (click)="startDownload(row)">
                            {{ 'storage_download_latest' | myLbTranslate }}
                        </button>
                        <button
                            id="storage-backup-delete"
                            [disabled]="!permissionsLookup['storage-backup-delete']"
                            (click)="confirmDeleteBackup(row)">
                            {{ 'delete' | myLbTranslate }}
                        </button>
                    </app-action-dots>
                </ng-template>
            </app-column>
        </app-table>
    </div>
</ng-template>


<ng-container #modalContainer></ng-container>

<app-modal-wrapper
    modalId="copy-appliance-id"
    modalTitle="storage_modal_id_title"
    [showCancelButton]="false"
    [showCloseButton]="false">
    <app-modal-body>
        <p>{{ 'storage_modal_id_message' | myLbTranslate }}</p>
        <div class="content-center-middle">
            <code>{{ copyApplianceId }}</code>
            <button
                class="copy-icon"
                appCopyClipboard
                [textToCopy]="copyApplianceId"
                [updateButtonText]="false"
                (copied)="confirmModalCopy()">
            </button>
        </div>
    </app-modal-body>
    <app-modal-footer>
        <button class="primary" [disabled]="modalButtonDisabled" (click)="modalService.close('copy-appliance-id')">
            {{ 'ok' | myLbTranslate }}
        </button>
    </app-modal-footer>
</app-modal-wrapper>
