<h1>{{ (editId ? 'gslb_edit_topology' : 'gslb_add_topology') | myLbTranslate }}</h1>
<form [formGroup]="serviceForm" [loaded]="!wizardService.isLoading">
    <div [formGroupName]="subStepEnum.GENERAL" [hidden]="getWizardSubStepState(subStepEnum.GENERAL)">
        <h2>{{ subStepEnum.GENERAL | myLbTranslate }}</h2>
        <section data-control-name="ip">
            <div *ngIf="!isInputHidden('ip')" class="form-group">
                <label for="geo_ip">{{ 'gslb_ip_subnet' | myLbTranslate }}*</label>
                <div class="form-content">
                    <input formControlName="ip" id="geo_ip" name="geo_ip" type="text" value="">
                    <app-validation-messages
                        [control]="serviceForm.get(subStepEnum.GENERAL + '.ip')"></app-validation-messages>
                    <app-help class="help-button" tag="topologies_ip-subnet"></app-help>
                </div>
            </div>
        </section>
        <section data-control-name="mask">
            <div *ngIf="!isInputHidden('mask')" class="form-group">
                <label for="geo_mask">{{ 'mask' | myLbTranslate }}*</label>
                <div class="form-content">
                    <input formControlName="mask" id="geo_mask" name="geo_mask" type="number" value="">
                    <app-validation-messages
                        [control]="serviceForm.get(subStepEnum.GENERAL + '.mask')"></app-validation-messages>
                    <app-help class="help-button" tag="topologies_mask"></app-help>
                </div>
            </div>
        </section>
        <section data-control-name="member">
            <div *ngIf="!isInputHidden('member')" class="form-group">
                <!--suppress XmlInvalidId -->
                <label for="gslb-topology">{{ 'members' | myLbTranslate }}*</label>
                <div class="form-content dropdown">
                    <app-dropdown
                        dropDownId="gslb-topology"
                        [formGroup]="getFormGroup(subStepEnum.GENERAL)"
                        [displayAsInput]="true"
                        formControlName="member"
                        [content]="dropdown.members">
                    </app-dropdown>
                    <app-help class="help-button" tag="topologies_members"></app-help>
                </div>
            </div>
        </section>
    </div>
</form>

