<div class="component-main">
    <h1>{{ 'l7_stick_table' | myLbTranslate }}</h1>
    <div style="text-align: right;margin-bottom: 10px">
        <button class="primary" (click)="refreshTable()">{{ 'l7_stick_table_refresh' | myLbTranslate }}</button>
    </div>

    <div class="dropdown-wrapper">
        <!--suppress XmlInvalidId -->
        <label for="sticktable-backends">{{ 'l7_stick_table_select_backend' | myLbTranslate }}</label>
        <app-dropdown
            dropDownId="sticktable-backends"
            [content]="backendsDropdown"
            [selectedKey]="selectedBackend"
            (keyOut)="updateTableForSelectedBackend($event)">
        </app-dropdown>
    </div>

    <app-table id="stick-tables" [data]="tableRows">
        <app-column [flexGrow]="1" prop="statsid" label="ID"></app-column>
        <app-column [flexGrow]="1" prop="key" label="Key"></app-column>
        <app-column [flexGrow]="1" prop="use" label="Use"></app-column>
        <app-column [flexGrow]="1" prop="expires" label="Expires"></app-column>
        <app-column [flexGrow]="1" prop="riplabel" label="Server"></app-column>
        <app-column [flexGrow]="0.3" prop="actions" label="" cellAlignment="right"
                    [cellTemplate]="stickTableActions">
            <ng-template let-row #stickTableActions>
                <app-action-dots>
                    <button (click)="deleteEntry(row.key)">
                        {{ 'delete' | myLbTranslate }}
                    </button>
                </app-action-dots>
            </ng-template>
        </app-column>
    </app-table>
</div>
