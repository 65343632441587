<app-lb-accordion>
    <ng-container *ngIf="grouping?.length else basic">
        <div class="row expand-all">
            <button class="button-as-link" *ngIf="enableToggleOfAccordions" (click)="toggleAccordions()">
                {{ (expandAll ? 'rbac_roles_edit_collapse_all' : 'rbac_roles_edit_expand_all') | myLbTranslate }}
            </button>
        </div>
        <ng-container *ngFor="let group of formattedGroups; index as i">
            <div class="group-wrapper">
                <app-lb-accordion-group
                    title="<strong>{{group.friendlyName}}</strong>"
                    [enableToggle]="enableToggleOfAccordions"
                    [open]="expandAll"
                    [forceClosed]="forceCollapseAll"
                    [fullWidth]="true"
                    [titleWrapperHeight]="accordionTitleHeight"
                    (toggle)="storeToggledAccordions($event, i)">
                    <ng-container
                        [ngTemplateOutlet]="titles" [ngTemplateOutletContext]="{index: i, group}">
                    </ng-container>
                    <ng-container
                        [ngTemplateOutlet]="content"
                        [ngTemplateOutletContext]="{endpoints:filteredFormGroups[group.service], group}">
                    </ng-container>
                </app-lb-accordion-group>
                <ng-container *ngIf="showAccordionCounter(group, i)">
                    <div class="counter" [style.maxHeight.px]="accordionTitleHeight">
                        <span>{{ getCounterByGroup(group.service) }} {{ 'selected' | myLbTranslate }}</span>
                    </div>
                </ng-container>
            </div>
        </ng-container>
        <ng-container *ngIf="!environment.production">
            <p class="footer-counter">
                <span class="note">Dev only:</span>
                <span>{{ pathCounter.displayed }} of {{ pathCounter.total }} paths displayed</span>
            </p>
        </ng-container>
    </ng-container>
    <ng-template #basic>
        <ng-container [ngTemplateOutlet]="titles"></ng-container>
        <ng-container
            [ngTemplateOutlet]="content"
            [ngTemplateOutletContext]="{endpoints: formEndpoints.controls}">
        </ng-container>
    </ng-template>
</app-lb-accordion>

<ng-template #titles let-group="group" let-index="index">
    <div class="row title-wrapper">
        <div class="path"></div>
        <div class="checkbox">
            <button class="button-as-link" (click)="toggleColumn('POST', group, index)">
                {{ 'create' | myLbTranslate }}
            </button>
        </div>
        <div class="checkbox">
            <button class="button-as-link" (click)="toggleColumn('GET', group, index)">
                {{ 'read' | myLbTranslate }}
            </button>
        </div>
        <div class="checkbox">
            <button class="button-as-link" (click)="toggleColumn('PUT', group, index)">
                {{ 'update' | myLbTranslate }}
            </button>
        </div>
        <div class="checkbox">
            <button class="button-as-link" (click)="toggleColumn('DELETE', group, index)">
                {{ 'delete' | myLbTranslate }}
            </button>
        </div>
        <div class="checkbox">
            <button class="button-as-link" (click)="toggleColumn('ALL', group, index)">
                {{ 'select_all' | myLbTranslate }}
            </button>
        </div>
    </div>
</ng-template>

<ng-template #content let-endpoints="endpoints" let-group="group">
    <form [formGroup]="formGroup" [class.full-width]="true" [disableFocus]="true">
        <div
            *ngFor="let endpoint of endpoints; index as j"
            class="row"
            formArrayName="endpoints">
            <div class="path" [formGroupName]="j.toString()">
                {{ endpoint.value.friendlyName ? endpoint.value.friendlyName : endpoint.value.path }}
            </div>
            <div class="checkbox">
                <input
                    class="lb-checkbox"
                    type="checkbox"
                    name="create"
                    id="create{{[j]}}"
                    [formControl]="getFormControlForCheckboxes(endpoint, 'POST')"
                    (mouseup)="checkboxHandler(endpoint.get('index').value,  'POST')"/>
            </div>
            <div class="checkbox">
                <input
                    class="lb-checkbox"
                    type="checkbox"
                    name="console"
                    id="read{{[j]}}"
                    [formControl]="getFormControlForCheckboxes(endpoint, 'GET')"
                    (mouseup)="checkboxHandler(endpoint.get('index').value, 'GET' )"/>
            </div>
            <ng-container *ngIf="showHidePut(endpoint) else patch">
                <div class="checkbox">
                    <input
                        class="lb-checkbox"
                        type="checkbox"
                        name="console"
                        id="update{{[j]}}"
                        [formControl]="getFormControlForCheckboxes(endpoint, 'PUT')"
                        (change)="updateHiddenMethod(endpoint.get('index').value, 'PUT','PATCH')"
                        (mouseup)="checkboxHandler(endpoint.get('index').value, 'PATCH')"/>
                </div>
            </ng-container>
            <ng-template #patch>
                <div class="checkbox">
                    <input
                        class="lb-checkbox"
                        type="checkbox"
                        name="console"
                        id="update{{[j]}}"
                        [formControl]="getFormControlForCheckboxes(endpoint, 'PATCH')"
                        (change)="updateHiddenMethod(endpoint.get('index').value, 'PATCH','PUT')"
                        (mouseup)="checkboxHandler(endpoint.get('index').value , 'PUT')"/>
                </div>
            </ng-template>
            <div class="checkbox">
                <input
                    class="lb-checkbox"
                    type="checkbox"
                    name="console"
                    id="delete{{[j]}}"
                    [formControl]="getFormControlForCheckboxes(endpoint, 'DELETE')"
                    (mouseup)="checkboxHandler(endpoint.get('index').value, 'DELETE' )"/>
            </div>
            <div class="checkbox">
                <input
                    #selectAlls
                    class="lb-checkbox"
                    type="checkbox"
                    name="console"
                    id="select{{[endpoint.get('index').value]}}"
                    data-groupName="{{group.service}}"
                    (click)="selectAll(endpoint?.value?.index, $event.target)"/>
            </div>
        </div>
    </form>
</ng-template>
