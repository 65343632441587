<h1>{{ 'services_real_server' | myLbTranslate }}</h1>
<form [formGroup]="serviceForm">
    <div [formGroupName]="subStepEnum.GENERAL" [hidden]="getWizardSubStepState(subStepEnum.GENERAL)">
        <h2>{{ subStepEnum.GENERAL | myLbTranslate }}</h2>
        <section data-control-name="backendID">
            <div *ngIf="!isInputHidden('backendId')" class="form-group">
                <label for="backend{{templateIndex}}">{{ 'backend' | myLbTranslate }}</label>
                <div class="form-content">
                    <app-dropdown
                        dropDownId="backend{{templateIndex}}"
                        [formGroup]="getFormGroup(subStepEnum.GENERAL)"
                        formControlName="backendId"
                        [content]="dropdown.backends">
                    </app-dropdown>
                    <app-help class="help-button" tag="l4-realserver_backend-selector"></app-help>
                </div>
            </div>
        </section>
        <section data-control-name="name">
            <div *ngIf="!isInputHidden('name')" class="form-group">
                <label for="realLabel{{templateIndex}}">{{ 'name' | myLbTranslate }}</label>
                <div class="form-content">
                    <ng-container *ngIf="namePrefix">
                        <span class="name-prefix">{{ namePrefix }}</span>
                    </ng-container>
                    <input id="realLabel{{templateIndex}}" name="name" formControlName="name" type="text">
                    <app-validation-messages [control]="serviceForm.get(subStepEnum.GENERAL + '.name')">
                    </app-validation-messages>
                    <app-help class="help-button" tag="l4-realserver_name"></app-help>
                </div>
            </div>
        </section>
        <section data-control-name="ipAddress">
            <div *ngIf="!isInputHidden('ipAddress')" class="form-group">
                <label for="realIp{{templateIndex}}">{{ 'services_real_server_ip' | myLbTranslate }}</label>
                <div class="form-content">
                    <input id="realIp{{templateIndex}}" name="ipAddress" formControlName="ipAddress" type="text">
                    <app-validation-messages [control]="serviceForm.get(subStepEnum.GENERAL + '.ipAddress')">
                    </app-validation-messages>
                    <app-help class="help-button" tag="l4-realserver_ip-address"></app-help>
                </div>
            </div>
        </section>
        <section data-control-name="enabledCount">
            <div *ngIf="!isInputHidden('count')">
                <app-lb-checkbox
                    [formGroup]="getFormGroup(subStepEnum.GENERAL)"
                    formControlName="enabledCount"
                    label="{{ 'services_enable_duplicates' | myLbTranslate }}"
                    helpTag="l7-realserver_enable-duplicates">
                    <div class="form-group content-top">
                        <label for="count{{templateIndex}}">
                            {{ 'services_number_of_servers' | myLbTranslate }}
                        </label>
                        <div class="form-content">
                            <input
                                id="count{{templateIndex}}"
                                name="count" title="{{ 'services_count' | myLbTranslate }}"
                                formControlName="count"
                                type="number"
                                min="1"
                                max="100">
                            <app-validation-messages [control]="serviceForm.get(subStepEnum.GENERAL + '.count')">
                            </app-validation-messages>
                            <app-help class="help-button" tag="l4-realserver_enable-duplicates"></app-help>
                        </div>
                    </div>
                    <small class="form-group-note">
                        {{ 'services_number_real_servers_message' | myLbTranslate }}
                    </small>
                </app-lb-checkbox>
            </div>
        </section>
        <section data-control-name="port">
            <div *ngIf="!isInputHidden('port')" class="form-group">
                <label for="realPort{{templateIndex}}">{{ 'services_real_server_port' | myLbTranslate }}</label>
                <div class="form-content">
                    <input id="realPort{{templateIndex}}" name="port" formControlName="port" type="number">
                    <app-validation-messages [control]="serviceForm.get(subStepEnum.GENERAL + '.port')">
                    </app-validation-messages>
                    <app-help class="help-button" tag="l4-realserver_port"></app-help>
                </div>
            </div>
        </section>
        <section data-control-name="weight">
            <div *ngIf="!isInputHidden('weight')" class="form-group">
                <label for="weight{{templateIndex}}">{{ 'weight' | myLbTranslate }}</label>
                <div class="form-content">
                    <input
                        id="weight{{templateIndex}}"
                        class="port"
                        min="0"
                        max="100"
                        name="weight"
                        title="{{ 'weight' | myLbTranslate }}"
                        formControlName="weight"
                        type="number">
                    <app-validation-messages [control]="serviceForm.get(subStepEnum.GENERAL + '.weight')">
                    </app-validation-messages>
                    <app-help class="help-button" tag="l4-realserver_weight"></app-help>
                </div>
            </div>
        </section>
        <section data-control-name="minimumConnections">
            <div *ngIf="!isInputHidden('minimumConnections')" class="form-group">
                <label for="minimumConnections{{templateIndex}}">{{ 'services_minimum_conns' | myLbTranslate }}</label>
                <div class="form-content">
                    <input
                        id="minimumConnections{{templateIndex}}"
                        class="port"
                        min="0"
                        max="65535"
                        name="minimumConnections"
                        formControlName="minimumConnections"
                        type="number">
                    <app-validation-messages [control]="serviceForm.get(subStepEnum.GENERAL + '.minimumConnections')">
                    </app-validation-messages>
                    <app-help class="help-button" tag="l4-realserver_minconns"></app-help>
                </div>
            </div>
        </section>
        <section data-control-name="maximumConnections">
            <div *ngIf="!isInputHidden('maximumConnections')" class="form-group">
                <label for="maximumConnections{{templateIndex}}">{{ 'max_conns' | myLbTranslate }}</label>
                <div class="form-content">
                    <input
                        id="maximumConnections{{templateIndex}}"
                        class="port"
                        min="0"
                        max="65535"
                        name="maximumConnections"
                        formControlName="maximumConnections"
                        type="number">
                    <app-validation-messages [control]="serviceForm.get(subStepEnum.GENERAL + '.maximumConnections')">
                    </app-validation-messages>
                    <app-help class="help-button" tag="l4-realserver_maxconns"></app-help>
                </div>
            </div>
        </section>
    </div>
</form>
