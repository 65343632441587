<app-widget
    #widget
    *ngIf="metric === MetricsEnum.CPU
        || metric === MetricsEnum.INTERFACES
        || metric === MetricsEnum.MEMORY"
    [class]="colSpan ? 'widget-item col-span-' + colSpan : ''"
    [title]="metric"
    [state]="state"
    [id]="id"
    [loading]="loading">
    <div class="gauge">
        <div class="meter"
             [ngStyle]="{'transform': 'rotate(' + (value / 2) / 100 + 'turn)'}"
             [class.success]="value < 70"
             [class.warning]="value >= 70 && value < 90"
             [class.critical]="value >= 90">
        </div>
        <div class="percentage-container">
            <div class="percentage-indicator">{{ value }}{{ 'percentage' | myLbTranslate }}</div>
        </div>
    </div>
</app-widget>

<app-widget
    #widget
    *ngIf="metric === MetricsEnum.DISKS"
    [class]="colSpan ? 'widget-item col-span-' + colSpan : ''"
    [title]="metric"
    [state]="state"
    [id]="id"
    [loading]="loading">
    <div class="progress-wrapper">
        <ng-container
            *ngFor="let disk of diskData">
            <div class="progress-group thin"
                 [class.bar-success]="disk.value < 70"
                 [class.bar-warning]="disk.value >= 70 && disk.value < 90"
                 [class.bar-critical]="disk.value >= 90">
                <div class="progress-outer">
                    <div class="progress-inner" [style.width]="disk.value + '%'"></div>
                </div>
            </div>
            <small>{{ disk.value }}{{ 'percentage' | myLbTranslate }} - {{ disk.label }}</small>
        </ng-container>
    </div>
</app-widget>

<app-widget
    #widget
    *ngIf="metric === MetricsEnum.LOADAVERAGEGRAPH
        || metric === MetricsEnum.MEMORYGRAPH
        || metric === MetricsEnum.NETWORKGRAPH"
    [class]="colSpan ? 'widget-item col-span-' + colSpan : ''"
    [title]="metric + ' (' + scale + ')'"
    [state]="state"
    [id]="id"
    [loading]="loading">
    <canvas
        baseChart
        height="100"
        type="line"
        [datasets]="data.datasets"
        [options]="graphOptions">
    </canvas>
</app-widget>

<app-widget
    #widget
    *ngIf="metric === MetricsEnum.CONNECTIONS"
    [class]="colSpan ? 'widget-item col-span-' + colSpan : ''"
    [title]="metric"
    [state]="state"
    [id]="id"
    [loading]="loading">
    <div class="content-vertical-middle">
        <h1>{{ getWidgetTitle() }}</h1>
    </div>
    <div *ngIf="metricValue" class="dot-traffic-wrapper">
        <span *ngIf="metricValue > 0" class="animation-dot-traffic">
            <span class="anim-helper-1"></span>
            <span *ngIf="metricValue >= 100" class="anim-helper-2"></span>
            <span *ngIf="metricValue >= 1000" class="anim-helper-3"></span>
        </span>
    </div>
</app-widget>
