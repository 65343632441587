<div class="component-main">
    <div class="content-left">
        <img
            ngSrc="/assets/centralisedManagement/icons/{{selectedShuttle?.icon}}-colour.svg"
            height="24"
            width="24"
            alt="{{selectedShuttle?.title}} Icon">
        <h1 id="component-title">{{ selectedShuttle?.title }}</h1>
    </div>
    <ng-container *ngIf="platformRows.length else noPlatforms">
        <div class="divider first"></div>
        <ng-container *ngFor="let platform of platformRows; let i = index;">
            <div
                class="content-space-between no-padding"
                [class.coming-soon]="!platform.active">
                <div class="content-left-vertical">
                    <div class="content">
                        <h2>{{ platform?.title }}</h2>
                    </div>
                    <p class="description">{{ platform.description | myLbTranslate }}</p>
                    <div *ngIf="platform.services?.length" class="services">
                        <!--  TODO temp removal until tracking is setup in portal 13/09/24 -->
                        <!--                        <ng-container *ngFor="let service of platform.services; index as j">-->

                        <!--                            <div *ngIf="j !== 0" class="simple-download-wrapper">-->
                        <!--                                <ng-container-->
                        <!--                                    *ngIf="downloadedList.includes(platform?.name + '-' + service?.fileName) else showDownloadButton">-->
                        <!--                                    <input class="lb-checkbox download-icon" type="checkbox" checked/>-->
                        <!--                                    <button class="button-as-link quinary">{{ service?.name }}</button>-->
                        <!--                                </ng-container>-->
                        <!--                                <ng-template #showDownloadButton>-->
                        <!--                                    <img-->
                        <!--                                        class="download-icon"-->
                        <!--                                        ngSrc="/assets/shared/icons/download.svg"-->
                        <!--                                        alt="Download Icon"-->
                        <!--                                        height="16"-->
                        <!--                                        width="14"-->
                        <!--                                        (click)="triggerSimpleDownload(platform, service)"/>-->
                        <!--                                    <button-->
                        <!--                                        class="button-as-link quinary"-->
                        <!--                                        (click)="triggerSimpleDownload(platform, service)">-->
                        <!--                                        {{ service?.name }}-->
                        <!--                                    </button>-->
                        <!--                                </ng-template>-->
                        <!--                            </div>-->
                        <!--                        </ng-container>-->
                    </div>
                </div>
                <div class="content-right">
                    <ng-container *ngIf="clickedItemTitle !== platform?.title">
                        <!--  TODO temp removal until tracking is setup in portal 13/09/24 -->
                        <!--                        <button-->
                        <!--                            class="primary"-->
                        <!--                            (click)="triggerDownload(platform, i)">-->
                        <!--                            {{ 'download' | myLbTranslate }}-->
                        <!--                        </button>-->
                        <button class="button-as-link" (click)='tempLinkToWebsite()'>
                            {{ 'start_free_trial' | myLbTranslate }}
                        </button>
                    </ng-container>
                    <ng-container #progressContainer></ng-container>
                </div>
            </div>
            <div class="divider"></div>
        </ng-container>
    </ng-container>
    <ng-template #noPlatforms>
        <p class="no-platforms">{{ 'resources_no_images_available' | myLbTranslate }}</p>
    </ng-template>
</div>
<div class="component-footer">
    <button class="outline" routerLink="/cm/resources/downloads">
        {{ 'cancel' | myLbTranslate }}
    </button>
</div>
