<div class="component-main">
    <div class="content-space-between">
        <h1>{{ 'backup_restore_restore_from' | myLbTranslate }}</h1>
    </div>
    <p><strong>{{ 'date' | myLbTranslate }}</strong> {{ restoreData.viewDateTime }}</p>
    <div *ngIf="!isNodeInCluster">
        <h3>{{ 'backup_restore_select_type' | myLbTranslate }}</h3>
        <p>{{ 'backup_restore_select_node_message' | myLbTranslate }}</p>
        <app-dropdown
            dropDownId="restore-type"
            [dropdownWidth]="436"
            [displayAsInput]="true"
            [content]="dropdownContent"
            (keyOut)="setRestoreType($event)">
        </app-dropdown>
    </div>

    <br>
    <div>
        <h3>{{ 'restore' | myLbTranslate }} {{ 'options' | myLbTranslate }}</h3>
        <p>{{ 'backup_restore_select_section_message' |myLbTranslate }}</p>
        <form [formGroup]="restoreForm">
            <app-table
                [name]="'restore-backup-list'"
                [data]="sectionTableRows"
                [selectedRows]="selectedSectionRows"
                [allowMultiSelect]="true"
                (selectedRowsOut)="setSelectedSections($event)">
                <app-column [flexGrow]="1" prop="name" label="name" [customCellTemplate]="serviceName">
                    <ng-template let-row #serviceName>
                        {{ row.nameFull }}
                        <ng-container *ngIf="row.name === 'fip'">
                            <div class="make-fip-active">
                                <input
                                    id="backupRestore-fip-active"
                                    formControlName="fipActive"
                                    class="lb-checkbox"
                                    name="services"
                                    type="checkbox"/>
                                <label for="backupRestore-fip-active" class="lb-checkbox">
                                    {{ 'backup_restore_make_fip_active' | myLbTranslate }}
                                </label>
                            </div>
                        </ng-container>
                    </ng-template>
                </app-column>
                <app-column [flexGrow]="1" prop="version" label="version"></app-column>
            </app-table>
        </form>
    </div>
</div>
<div class="component-footer">
    <button id="restore-cancel" class="outline" [routerLink]="'../'">
        {{ 'cancel' | myLbTranslate }}
    </button>
    <app-loading-button
        buttonId="backupRestore-confirm-restore"
        buttonClass="primary"
        buttonText="restore"
        [isDisabled]="restoreForm.invalid || restoreForm.pristine || restoreType === null"
        [callBack]="restore.bind(this)">
    </app-loading-button>
</div>
