<div *ngIf="!loading" class="component-main">
    <div class="content">
        <h1>{{ 'service' | myLbTranslate }} {{ 'details' | myLbTranslate }}</h1>
    </div>
    <div class="widgetContainer">
        <app-widget class="widget-item col-span-2" [sslEnabled]="frontend?.SSLEnable" title="{{frontend?.name}}">
            <div class="frontend-details-widget">
                <h1>
                    <app-tooltip trigger="<span class='frontend-fip-mask'>{{frontendFip?.ip}}</span>" [width]="200">
                        <div class="frontend-fip-mask">
                            <span>{{ 'services_frontend_floating_ip' | myLbTranslate }}</span>
                        </div>
                    </app-tooltip>
                </h1>
                <h2>
                    <app-tooltip trigger="<span class='frontend-ports'>{{frontend?.ports}}</span>" [width]="200">
                        <div>
                            <span>{{ 'services_frontend_floating_port' | myLbTranslate }}</span>
                        </div>
                    </app-tooltip>
                </h2>
                <h2 *ngIf="getStatusesByServiceName(frontend, 'status')">
                    {{ getStatusesByServiceName(frontend, 'status') | titlecase }}
                </h2>
            </div>
        </app-widget>
        <app-lb-widget-extended-alerts
            class="widget-item col-span-2"
            [externalStatuses]="utilsService.duplicateObject(serviceStatusByName)"
            [callBackFunction]="callBackReference">
        </app-lb-widget-extended-alerts>
        <!--        <app-widget-sys-metrics-->
        <!--            [colSpan]="1"-->
        <!--            [metric]="MetricsEnum"-->
        <!--            [metricValue]="socketData.sessionRate">-->
        <!--        </app-widget-sys-metrics>-->
    </div>

    <div class="content-space-between search-button-wrapper">
        <div> <!-- Spacer div in case there are no backends and search is hidden -->
            <app-search-rows
                [rows]="backendRows"
                [searchKeys]="['id', 'label']"
                [removeMargin]="true"
                (searchResults)="updateTableRows($event)">
            </app-search-rows>
        </div>
        <button
            id="service-details-add-backend"
            class="primary"
            [disabled]="!permissionsLookup['service-details-add-backend'] || transactionActive || templateType === 'layer4'"
            appHint
            [hintText]="templateType === 'layer4' ? ('service_details_add_backend_disabled' | myLbTranslate) : ''"
            hintPosition="left"
            (click)="addEditBackend()">
            {{ 'service_details_add_backend' | myLbTranslate }}
        </button>
    </div>

    <ng-container *ngIf="!backendRows.length; else backendContainers">
        <div class="content-center-middle">
            <h1>{{ 'services_no_backends' | myLbTranslate }}</h1>
        </div>
    </ng-container>

    <ng-template #backendContainers>
        <h2>{{ 'backends' | myLbTranslate }}</h2>
        <app-lb-accordion>
            <div
                *ngFor="let backend of backendRows; let i = index;"
                id="service-details-backend-{{i}}"
                class="backend-container">
                <div class="content-space-between backend-header">
                    <div class="service-details" (click)="toggleCollapse(i)">
                        <span [class]="!backend.collapsed ? 'arrow arrow-up' : 'arrow arrow-down'"></span>
                        <h2 class="service-title">{{ 'backend' | myLbTranslate }}:
                            <span>{{ backend.name }}</span>
                        </h2>
                        <h2 class="service-connection">{{ 'connections' | myLbTranslate }}:
                            <span>{{ backend.connections | titlecase }}</span>
                        </h2>
                        <h2 class="service-status">{{ 'status' | myLbTranslate }}:
                            <span [class]="'' + backend.status">
                                {{ backend.status | titlecase }}
                            </span>
                        </h2>
                    </div>
                    <div class="action-dots-wrapper">
                        <app-action-dots>
                            <button
                                id="service-details-online-backend"
                                [hidden]="backend.status === 'online'"
                                [disabled]="!permissionsLookup['service-details-online-backend']"
                                (click)="backendAction('online-Backend', backend)">
                                {{ 'online' | myLbTranslate }}
                            </button>
                            <button
                                id="service-details-drain-backend"
                                [hidden]="backend.status === 'down'"
                                [disabled]="!permissionsLookup['service-details-drain-backend']"
                                (click)="backendAction('drain-Backend', backend)">
                                {{ 'drain' | myLbTranslate }}
                            </button>
                            <button
                                id="service-details-halt-backend"
                                [hidden]="backend.status === 'down'"
                                [disabled]="!permissionsLookup['service-details-halt-backend']"
                                (click)="backendAction('halt-Backend', backend)">
                                {{ 'halt' | myLbTranslate }}
                            </button>
                            <button
                                id="service-details-edit-backend"
                                [disabled]="!permissionsLookup['service-details-edit-backend']"
                                (click)="addEditBackend(backend)">{{ 'edit' | myLbTranslate }}
                            </button>
                            <button
                                id="service-details-duplicate-backend"
                                [disabled]="!permissionsLookup['service-details-add-backend']"
                                (click)="addEditBackend(backend, true)">
                                {{ 'duplicate' | myLbTranslate }}
                            </button>
                            <button
                                id="service-details-delete-backend"
                                [disabled]="!permissionsLookup['service-details-delete-backend']"
                                (click)="backendAction('delete-Backend', backend)">
                                {{ 'delete' | myLbTranslate }}
                            </button>
                        </app-action-dots>
                    </div>
                </div>
                <app-lb-accordion-group [open]="!backend.collapsed" [showTitle]="false" [showArrow]="false">
                    <div class="content-right">
                        <button
                            id="service-details-add-real-server-{{i}}"
                            class="primary"
                            [disabled]="!permissionsLookup['service-details-add-real-server'] || transactionActive"
                            (click)="addEditRealServer(backend)">
                            {{ 'services_add_real_server' | myLbTranslate }}
                        </button>
                    </div>
                    <app-table
                        id="realServers-table"
                        [data]="realServerRows[i]">
                        <app-column [flexGrow]="1" prop="name" label="services_real_server_name"></app-column>
                        <app-column [flexGrow]="1" prop="connections" label="connections"></app-column>
                        <app-column [flexGrow]="1.5" prop="ip" label="ip"></app-column>
                        <app-column [flexGrow]="1" prop="port" label="port"></app-column>
                        <app-column [flexGrow]="1" prop="status" label="status" [cellTemplate]="rsStatus">
                            <ng-template let-row #rsStatus>
                                <span>{{ row.status }}</span>
                            </ng-template>
                        </app-column>
                        <app-column
                            [flexGrow]="0.5"
                            prop="actions"
                            label=""
                            cellAlignment="right"
                            [cellTemplate]="rsActionsi">
                            <ng-template let-row #rsActionsi>
                                <app-action-dots>
                                    <button
                                        id="service-details-online-real-server"
                                        [hidden]="servicesUtilsService.showButton(row.status, statusEnum.ONLINE)"
                                        [disabled]="!permissionsLookup['service-details-online-real-server']"
                                        (click)="realServerAction('online-RealServer', row)">
                                        {{ 'online' | myLbTranslate }}
                                    </button>
                                    <button
                                        id="service-details-drain-real-server"
                                        [hidden]="servicesUtilsService.showButton(row.status, statusEnum.MAINT)"
                                        [disabled]="!permissionsLookup['service-details-drain-real-server']"
                                        (click)="realServerAction('drain-RealServer', row)">
                                        {{ 'drain' | myLbTranslate }}
                                    </button>
                                    <button
                                        id="service-details-halt-real-server"
                                        [hidden]="servicesUtilsService.showButton(row.status, statusEnum.HALT)"
                                        [disabled]="!permissionsLookup['service-details-halt-real-server']"
                                        (click)="realServerAction('halt-RealServer', row)">
                                        {{ 'halt' | myLbTranslate }}
                                    </button>
                                    <button
                                        id="service-details-restart-haproxy"
                                        [hidden]="!servicesUtilsService.showButton(row.status, statusEnum.PENDING)"
                                        [disabled]="!permissionsLookup['service-details-restart-haproxy']"
                                        (click)="servicesUtilsService.restartHaProxy()">
                                        {{ 'restart_haproxy' | myLbTranslate }}
                                    </button>
                                    <button
                                        id="service-details-edit-real-server"
                                        [disabled]="!permissionsLookup['service-details-edit-real-server']"
                                        (click)="addEditRealServer(backend, row.id)">
                                        {{ 'edit' |  myLbTranslate }}
                                    </button>
                                    <button
                                        id="service-details-duplicate-real-server"
                                        [disabled]="!permissionsLookup['service-details-add-real-server']"
                                        (click)="addEditRealServer(backend, row.id, true)">
                                        {{ 'duplicate' | myLbTranslate }}
                                    </button>
                                    <button
                                        id="service-details-delete-real-server"
                                        [disabled]="!permissionsLookup['service-details-delete-real-server']"
                                        (click)="realServerAction('delete-RealServer', row)">
                                        {{ 'delete' | myLbTranslate }}
                                    </button>
                                </app-action-dots>
                            </ng-template>
                        </app-column>
                    </app-table>
                </app-lb-accordion-group>
            </div>
        </app-lb-accordion>
    </ng-template>
</div>

<ng-container *ngIf="loading">
    <div class="page-loader"></div>
</ng-container>
