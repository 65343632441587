<div class="component-main">
    <div class="content-space-between">
        <h1 id="component-title">{{ 'backup_for' | myLbTranslate }} {{ adc?.label }}</h1>
    </div>
    <app-backup-widget [sitInlineWithSearch]="true" [serviceId]="adcId"></app-backup-widget>
    <app-table
        name="backup-details"
        [data]="tableRows"
        [allowMultiSelect]="false"
        [showPaginator]="false"
        [stickyHeader]="true"
        [searchTerm]="searchTerm">
        <app-column [flexGrow]="1" prop="backupDateTime" label="date_time"></app-column>
        <app-column [flexGrow]="1" prop="size" label="file_size"></app-column>
        <app-column [flexGrow]="1" prop="actions" label="" cellAlignment="right" [customCellTemplate]="actions">
            <ng-template let-row #actions>
                <app-action-dots>
                    <button id="storage-backup-download" (click)="startDownload(row)">
                        {{ 'download' | myLbTranslate }}
                    </button>
                    <button
                        id="storage-backup-delete"
                        [disabled]="!permissionsLookup['storage-backup-delete']"
                        (click)="confirmDeleteBackup(row)">
                        {{ 'delete' | myLbTranslate }}
                    </button>
                </app-action-dots>
            </ng-template>
        </app-column>
    </app-table>
</div>

<ng-container #modalContainer></ng-container>

<app-modal-wrapper
    modalId="copy-appliance-id"
    modalTitle="storage_modal_id_title"
    [showCancelButton]="false"
    [showCloseButton]="false">
    <app-modal-body>
        <p>{{ 'storage_modal_id_message' | myLbTranslate }}</p>
        <div class="content-center-middle">
            <code>{{ copyApplianceId }}</code>
            <button
                class="copy-icon"
                appCopyClipboard
                [textToCopy]="copyApplianceId"
                [updateButtonText]="false"
                (copied)="confirmModalCopy()">
            </button>
        </div>
    </app-modal-body>
    <app-modal-footer>
        <button class="primary" [disabled]="modalButtonDisabled" (click)="modalService.close('copy-appliance-id')">
            {{ 'ok' | myLbTranslate }}
        </button>
    </app-modal-footer>
</app-modal-wrapper>
