<app-dropdown
    dropDownId="logged-in-user"
    selectedTitle="{{loggedInUsername | titlecase }}"
    [titleAlign]="titleAlignment"
    [forceOpen]="forceOpen"
    [contentWidth]="180"
    [dropdownWidth]="140">
    <div class="drop-container-html" [class.forceOpen]="forceOpen">
        <div
            (click)="modalService.openModalInParent('logoutConfirm')"
            class="menu-item-wrapper">
            <img ngSrc="assets/centralisedManagement/icons/logout.svg" alt="logout" height="19" width="19">
            <span>{{ 'logout' | myLbTranslate }}</span>
        </div>
    </div>
</app-dropdown>
