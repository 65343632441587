<ng-container *ngIf="pageReady; else pageLoading">
    <div class="component-main">
        <div class="content">
            <h1 id="component-title">{{ 'advanced_services' | myLbTranslate }}</h1>
        </div>
        <app-tab-container
            id="advanced-config"
            [vertical]="false"
            [widthPercent]="100"
            (eventEmitter)="setActiveTabId($event)">
            <app-tab id="l7" tabTitle="l7">
                <form [formGroup]="l7GlobalForm" [class.disabled]="l7GlobalForm.disabled">
                    <h2>{{ 'advanced_services_logging' | myLbTranslate }}</h2>
                    <div class="form-group">
                        <!--suppress XmlInvalidId -->
                        <label for="adv-services">{{ 'log_level' | myLbTranslate }}</label>
                        <div class="form-content">
                            <app-dropdown
                                dropDownId="adv-services"
                                formControlName="loggingLevel"
                                [formGroup]="l7GlobalForm"
                                [content]="dropdown?.logLevel">
                            </app-dropdown>
                            <app-help tag="advanced-services_l7-global-enable-logging"></app-help>
                        </div>
                    </div>
                    <h2>{{ 'connections' | myLbTranslate }}</h2>
                    <div class="form-group">
                        <label>{{ 'advanced_services_conn_timeout' | myLbTranslate }}</label>
                        <div class="form-content">
                            <app-time-selector [formGroup]="l7GlobalForm" formControlName="connectionTimeout">
                            </app-time-selector>
                            <app-help tag="advanced-services_l7-global-connection-timeout"></app-help>
                        </div>
                    </div>
                    <div class="form-group">
                        <label>{{ 'advanced_services_client_timeout' | myLbTranslate }}</label>
                        <div class="form-content">
                            <app-time-selector [formGroup]="l7GlobalForm" formControlName="clientTimeout">
                            </app-time-selector>
                            <app-help tag="advanced-services_l7-global-client-timeout"></app-help>
                        </div>
                    </div>
                    <div class="form-group">
                        <label>{{ 'advanced_services_real_server_timeout' | myLbTranslate }}</label>
                        <div class="form-content">
                            <app-time-selector [formGroup]="l7GlobalForm" formControlName="serverTimeout">
                            </app-time-selector>
                            <app-help tag="advanced-services_l7-global-real-server-timeout"></app-help>
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="maximum-conns">{{ 'max_conns' | myLbTranslate }}*</label>
                        <div class="form-content">
                            <input type="number" name="maxConns" id="maximum-conns" formControlName="maximumConns"/>
                            <app-validation-messages [control]="l7GlobalForm.controls['maximumConns']">
                            </app-validation-messages>
                            <app-help tag="advanced-services_l7-global-max-conns"></app-help>
                        </div>
                    </div>
                    <h2>{{ 'advanced_services_email_alerts' | myLbTranslate }}</h2>
                    <div class="form-group">
                        <label for="email-alert-to">{{ 'advanced_services_email_alert_to' | myLbTranslate }}</label>
                        <div class="form-content">
                            <input type="text" id="email-alert-to" name="emailAlertTo" formControlName="emailAlertTo"/>
                            <app-validation-messages
                                [control]="l7GlobalForm.controls['emailAlertTo']"></app-validation-messages>
                            <app-help tag="advanced-services_l7-global-email-alert-address"></app-help>
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="email-alert-from">{{ 'advanced_services_email_alert_from' | myLbTranslate }}</label>
                        <div class="form-content">
                            <input
                                type="text" id="email-alert-from" name="emailAlertFrom"
                                formControlName="emailAlertFrom"/>
                            <app-validation-messages [control]="l7GlobalForm.controls['emailAlertFrom']">
                            </app-validation-messages>
                            <app-help tag="advanced-services_l7-global-email-alert-from-address"></app-help>
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="email-server-mail">
                            {{ 'advanced_services_email_server_addr' | myLbTranslate }}
                        </label>
                        <div class="form-content">
                            <input
                                type="text"
                                name="emailServerMail"
                                id="email-server-mail"
                                formControlName="emailAlertMailer"/>
                            <app-help tag="advanced-services_l7-global-email-alert-server"></app-help>
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="email-server-port">
                            {{ 'advanced_services_email_server_port' | myLbTranslate }}
                        </label>
                        <div class="form-content">
                            <input
                                type="number"
                                name="emailServerPort"
                                id="email-server-port"
                                formControlName="emailMailerPort"/>
                            <app-validation-messages [control]="l7GlobalForm.controls['emailMailerPort']">
                            </app-validation-messages>
                            <app-help tag="advanced-services_l7-global-email-alert-server-port"></app-help>
                        </div>
                    </div>
                    <h2>{{ 'advanced_services_statistics_page' | myLbTranslate }}</h2>
                    <app-lb-checkbox
                        [formGroup]="l7GlobalForm"
                        formControlName="haproxyStatsEnable"
                        label="{{ 'advanced_services_enable_stats' | myLbTranslate }}"
                        helpTag="advanced-services_l7-global-statistics-enable">
                        <div class="form-group">
                            <label for="haproxy-stats-password">{{ 'password' | myLbTranslate }}*</label>
                            <div class="form-content">
                                <input
                                    type="password"
                                    name="haproxyStatsPassword"
                                    id="haproxy-stats-password"
                                    formControlName="haproxyStatsPassword"/>
                                <app-validation-messages [control]="l7GlobalForm.controls['haproxyStatsPassword']">
                                </app-validation-messages>
                                <app-help tag="advanced-services_l7-global-statistics-password"></app-help>
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="haproxy-stats-port">{{ 'port' | myLbTranslate }}</label>
                            <div class="form-content">
                                <input
                                    type="number"
                                    name="haproxyStatsPort"
                                    id="haproxy-stats-port"
                                    formControlName="haproxyStatsPort"/>
                                <app-validation-messages [control]="l7GlobalForm.controls['haproxyStatsPort']">
                                </app-validation-messages>
                                <app-help tag="advanced-services_l7-global-statistics-port"></app-help>
                            </div>
                        </div>
                        <div class="form-group">
                            <div class="form-content">
                                <input
                                    class="lb-checkbox"
                                    id="haproxyStatsAdvanced"
                                    type="checkbox"
                                    name="haproxyStatsAdvanced"
                                    formControlName="haproxyStatsAdvanced"/>
                                <label for="haproxyStatsAdvanced" class="lb-checkbox">
                                    {{ 'advanced_services_advanced_stats' | myLbTranslate }}
                                </label>
                                <app-help tag="advanced-services_l7-global-advanced-stats"></app-help>
                            </div>
                        </div>
                        <div class="form-group">
                            <div class="form-content">
                                <input
                                    type="checkbox"
                                    class="lb-checkbox"
                                    id="haproxyStatsSSL"
                                    name="haproxyStatsSSL"
                                    formControlName="haproxyStatsSSL"/>
                                <label for="haproxyStatsSSL" class="lb-checkbox"></label>
                                {{ 'advanced_services_enable_ssl' | myLbTranslate }}
                                <app-help tag="advanced-services_l7-global-statistics-ssl"></app-help>
                            </div>
                        </div>
                        <div class="form-group">
                            <!--suppress XmlInvalidId -->
                            <label for="haproxy-ssl-min">{{ 'ssl_min_version' | myLbTranslate }}</label>
                            <div class="form-content">
                                <app-dropdown
                                    dropDownId="haproxy-ssl-min"
                                    [formGroup]="l7GlobalForm"
                                    formControlName="haproxyStatsSSLMinVer"
                                    [content]="dropdown?.tls"></app-dropdown>
                                <app-help tag="advanced-services_haproxy-stats-ssl-min-ver"></app-help>
                            </div>
                        </div>
                        <div class="form-group">
                            <!--suppress XmlInvalidId -->
                            <label for="haproxy-ssl-max">{{ 'ssl_max_version' | myLbTranslate }}</label>
                            <div class="form-content">
                                <app-dropdown
                                    dropDownId="haproxy-ssl-max"
                                    [formGroup]="l7GlobalForm"
                                    formControlName="haproxyStatsSSLMaxVer"
                                    [content]="dropdown?.tls"></app-dropdown>
                                <app-help tag="advanced-services_haproxy-stats-ssl-max-ver"></app-help>
                            </div>
                        </div>
                        <div class="form-group">
                            <!--suppress XmlInvalidId -->
                            <label for="haproxy-certs">{{ 'ssl_certificate' | myLbTranslate }}</label>
                            <div class="form-content">
                                <app-dropdown
                                    dropDownId="haproxy-certs"
                                    [formGroup]="l7GlobalForm"
                                    formControlName="haproxyStatsCertID"
                                    [content]="dropdown?.sslCerts">
                                </app-dropdown>
                                <app-help tag="advanced-services_l7-global-statistics-ssl-cert"></app-help>
                            </div>
                        </div>
                    </app-lb-checkbox>
                    <h2>{{ 'advanced_services_buffer_size' | myLbTranslate }}</h2>
                    <div class="form-group">
                        <label for="request-buffer-length">
                            {{ 'advanced_services_req_buf_len' | myLbTranslate }} *
                        </label>
                        <div class="form-content">
                            <input
                                type="number"
                                name="requestBufferLength"
                                id="request-buffer-length"
                                formControlName="requestBuffLen"/>
                            <span [class.disabled]="l7GlobalForm.get('requestBuffLen').disabled">
                                {{ 'bytes' | myLbTranslate }}
                            </span>
                            <app-validation-messages
                                [control]="l7GlobalForm.controls['requestBuffLen']"></app-validation-messages>
                            <app-help tag="advanced-services_l7-global-request-buffer-length"></app-help>
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="header-buffer-length">{{ 'advanced_services_hdr_buf_len' | myLbTranslate }}*</label>
                        <div class="form-content">
                            <input
                                type="number"
                                name="headerBufferLength"
                                id="header-buffer-length"
                                formControlName="headerBuffLen"/>
                            <span [class.disabled]="l7GlobalForm.get('headerBuffLen').disabled">
                                {{ 'bytes' | myLbTranslate }}
                            </span>
                            <app-validation-messages
                                [control]="l7GlobalForm.controls['headerBuffLen']"></app-validation-messages>
                            <app-help tag="advanced-services_l7-global-header-buffer-length"></app-help>
                        </div>
                    </div>
                    <h2>{{ 'advanced_services_persistence_table' | myLbTranslate }}</h2>
                    <app-lb-checkbox
                        [formGroup]="l7GlobalForm"
                        formControlName="enablePersistenceReplication"
                        helpTag="advanced-services_l7-global-replication-peer"
                        label="{{ 'advanced_services_persist_replication' | myLbTranslate }}">
                        <div class="form-group">
                            <label
                                for="persistence-replication-port">{{ 'advanced_services_persist_repl_port' | myLbTranslate }}</label>
                            <div class="form-content">
                                <input
                                    type="number"
                                    name="persistenceReplicationPort"
                                    id="persistence-replication-port"
                                    formControlName="persistenceReplicationPort"/>
                                <app-validation-messages
                                    [control]="l7GlobalForm.controls['persistenceReplicationPort']"></app-validation-messages>
                                <app-help tag="advanced-services_l7-global-replication-port"></app-help>
                            </div>
                        </div>
                    </app-lb-checkbox>
                    <h2>{{ 'advanced_services_multi_thread' | myLbTranslate }}</h2>
                    <app-lb-checkbox
                        [formGroup]="l7GlobalForm"
                        formControlName="enableThreads"
                        label="{{ 'advanced_services_enable_multi_thread' | myLbTranslate }}"
                        helpTag="advanced-services_l7-global-enable-threads">
                        <div class="form-group">
                            <label for="number-of-threads">
                                {{ 'advanced_services_no_of_threads' | myLbTranslate }}
                            </label>
                            <div class="form-content">
                                <input
                                    type="number"
                                    name="numberOfThreads"
                                    id="number-of-threads"
                                    formControlName="numberOfThreads"/>
                                <app-validation-messages
                                    [control]="l7GlobalForm.controls['numberOfThreads']"></app-validation-messages>
                                <app-help tag="advanced-services_l7-global-number-of-threads"></app-help>
                            </div>
                        </div>
                    </app-lb-checkbox>
                    <h2>{{ 'advanced_services_ssl_cache_options' | myLbTranslate }}</h2>
                    <div class="form-group">
                        <label for="ssl-cache-lifetime">SSL cache lifetime</label>
                        <div class="form-content">
                            <input
                                type="number"
                                name="sslCacheLifetime"
                                id="ssl-cache-lifetime"
                                formControlName="sslCacheLifetime"/>
                            <span [class.disabled]="l7GlobalForm.get('sslCacheLifetime').disabled">
                                {{ 'ms' | myLbTranslate }}
                            </span>
                            <app-help tag="advanced-services_l7-global-ssl-cache-lifetime"></app-help>
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="ssl-cache-size">SSL cache size</label>
                        <div class="form-content">
                            <input
                                title="{{ 'advanced_services_ssl_cache_size' | myLbTranslate}}"
                                type="number"
                                name="sslCacheSize"
                                id="ssl-cache-size"
                                formControlName="sslCacheSize"/>
                            <span [class.disabled]="l7GlobalForm.get('sslCacheSize').disabled">
                                {{ 'bytes' | myLbTranslate }}
                            </span>
                            <app-help tag="advanced-services_l7-global-ssl-cache-size"></app-help>
                        </div>
                    </div>
                </form>
            </app-tab>
            <!--        <app-lb-tab id="l4" tabTitle="l4">-->
            <!--            <p>do me</p>-->
            <!--        </app-lb-tab>-->
            <!--        <app-lb-tab id="ssl_termination" tabTitle="ssl_termination">-->
            <!--            <p>stuff</p>-->
            <!--        </app-lb-tab>-->
        </app-tab-container>
    </div>
    <div class="component-footer">
        <app-loading-button
            *ngIf="activeTabId === 'l7'"
            buttonClass="primary"
            buttonId="advanced-services-update"
            buttonText="submit"
            [isDisabled]="l7GlobalForm.invalid || l7GlobalForm.pristine || !permissionsLookup['advanced-services-update']"
            [callBack]="submitL7Global.bind(this)">
        </app-loading-button>
        <!--    <ng-container *ngIf="activeTab === 'l4'">-->
        <!--        <app-loading-button-->
        <!--            buttonClass="primary"-->
        <!--            buttonId="adv-services-update"-->
        <!--            buttonText="different_button"-->
        <!--            [isDisabled]="l7GlobalForm.invalid || l7GlobalForm.pristine"-->
        <!--            [callBack]="submitL7Global.bind(this)">-->
        <!--        </app-loading-button>-->
        <!--    </ng-container>-->
    </div>
</ng-container>
<ng-template #pageLoading>
    <div class="page-loader">
        <img ngSrc="assets/shared/icons/loading-spinner-primary.svg" alt="loading spinner" height="20" width="20"/>
    </div>
</ng-template>


