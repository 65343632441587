<ng-container *ngIf="isLoading && !componentEmbedded">
    <div class="loader-wrapper">
        <img
            class="loader"
            ngSrc="assets/shared/icons/loading-spinner-primary.svg"
            height="40"
            width="40"
            alt="loading spinner"/>
    </div>
</ng-container>

<div [hidden]="isLoading && !componentEmbedded">
    <div *ngIf="showSearch" class="table-function-row">
        <form class="table-function-left" [disableFocus]="true">
            <input
                id="search-rows-input"
                type="search"
                placeholder="{{'search_placeholder' | myLbTranslate }}"
                required
                [value]="searchTerm || null"
                (keyup)="applyFilter($event)"/>
            <button class="clear-search" type="reset" (click)="clearSearch()">
                <img ngSrc="assets/shared/icons/close-cross.svg" height="20" width="20" alt="Clear search"/>
            </button>
        </form>
        <ng-container *ngIf="embeddedButtons">
            <div class="button-container">
                <ng-container *ngTemplateOutlet="embeddedButtons"></ng-container>
            </div>
        </ng-container>
    </div>

    <ng-container *ngIf="dataSource?.data?.length && showPaginator">
        <app-custom-paginator
            [id]="id"
            [datasource]="dataSource"
            [selectedRows]="selectedRows"
            [filteredData]="paginatorFilteredData"
            (paginatorData)="applyPaginatorUpdates()">
        </app-custom-paginator>
    </ng-container>

    <div
        #tableContainer
        [id]="id"
        [attr.data-testid]="id"
        class="table-container"
        [class.wizard]="componentEmbedded"
        tabindex="0"
        [class.table-no-data]="!dataSource?.filteredData?.length">
        <table mat-table [dataSource]="dataSource" [class.show-footer]="showFooter">
            <ng-content></ng-content>
            <app-column
                prop="select"
                [data]="dataSource.filteredData"
                [cellTemplate]="checkboxTemplate"
                [selectedRows]="tempSelectedRows"
                [allRowsChecked]="dataSource?.paginator ? dataSource.paginator && allPageRowsSelected : allPageRowsSelected"
                (toggle)="toggleAllRows($event)">
                <ng-template let-data #checkboxTemplate>
                    <input
                        id="select-individual"
                        class="lb-checkbox"
                        type="checkbox"
                        [checked]="selectionHasValue(data)">
                </ng-template>
            </app-column>
            <tr *matNoDataRow class="row-no-data">
                <td [attr.colspan]="displayedColumns.length">
                    {{ 'table_no_data' | myLbTranslate }}
                </td>
            </tr>
            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr
                mat-row
                class="table-ng-row"
                tabindex="0"
                *matRowDef="let data; columns: displayedColumns"
                [class.isSelected]="selectionHasValue(data)"
                (keydown)="onKeyPress(data, $event)"
                (click)="selectRow(data, $event)">
            </tr>
            <ng-container *ngIf="!dataSource?.filteredData.length">
                <tr class="row-no-data">
                    <td [attr.colspan]="displayedColumns.length">
                        {{ 'table_no_data' | myLbTranslate }}
                    </td>
                </tr>
            </ng-container>
        </table>
    </div>
    <div *ngIf="allowMultiSelect || showCSVDownload" class="table-information">
        <div class="items-selected">
            <ng-container *ngIf="allowMultiSelect && dataSource?.filteredData.length else total">
                <span>Selected: {{ tempSelectedRows?.length }}/{{ dataSource.filteredData?.length }} total</span>
            </ng-container>
            <ng-template #total>
                <span>{{ dataSource.filteredData?.length }} total</span>
            </ng-template>
        </div>
        <div *ngIf="showCSVDownload" class="csv-download">
            <button class="button-as-link" (click)="downloadCSV()">
                {{ 'download_csv' | myLbTranslate }}
            </button>
        </div>
    </div>
</div>
