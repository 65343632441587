<div [hidden]="addEditCipherListsVisible" [class.component-main]="!componentEmbedded">
    <div class="content-space-between" [class.embedded]="componentEmbedded">
        <div class="content-left">
            <ng-container *ngIf="!componentEmbedded; else embeddedTitle">
                <h1>{{ 'cipher_list_title' | myLbTranslate }}</h1>
            </ng-container>
            <ng-template #embeddedTitle>
                <h2>{{ 'cipher_list_title' | myLbTranslate }}</h2>
            </ng-template>
        </div>
        <div class="content-right">
            <button
                id="ssl-cipher-lists-new"
                class="primary"
                [disabled]="!permissionsLookup['ssl-cipher-lists-new']"
                (click)="addEditCipherList()">
                {{ 'cipher_list_new' | myLbTranslate }}
            </button>
        </div>
    </div>
    <app-table
        id="cipher-list"
        [data]="tableRows"
        [showPaginator]="false"
        [stickyHeader]="true">
        <app-column prop="name" label="cipher_list_name"></app-column>
        <app-column prop="builtin" label="type"></app-column>
        <app-column prop="usage" label="usage" [cellTemplate]="cipherUsage">
            <ng-template let-row #cipherUsage>
                <app-usage [usage]="row.usage" modalTitle="cipher_usage"></app-usage>
            </ng-template>
        </app-column>
        <app-column prop="actions" label="" cellAlignment="right" [cellTemplate]="cipherActions">
            <ng-template let-row #cipherActions>
                <app-action-dots>
                    <ng-container *ngIf="row.builtin === 'User'; else viewDuplicate">
                        <button
                            id="ssl-cipher-lists-edit"
                            [disabled]="!permissionsLookup['ssl-cipher-lists-edit']"
                            (click)="addEditCipherList(row)">
                            {{ 'edit' | myLbTranslate }}
                        </button>
                        <button
                            id="ssl-cipher-lists-delete"
                            [disabled]="!permissionsLookup['ssl-cipher-lists-delete'] || row.usage?.length"
                            (click)="confirmDelete(row.id, row.name)">
                            {{ 'delete' | myLbTranslate }}
                        </button>
                    </ng-container>
                    <ng-template #viewDuplicate>
                        <button
                            id="view-list"
                            (click)="viewCipherList(row)">
                            {{ 'view' | myLbTranslate }}
                        </button>
                        <button
                            id="ssl-cipher-lists-duplicate-cipher-list"
                            [disabled]="!permissionsLookup['ssl-cipher-lists-new']"
                            (click)="addEditCipherList(row, true)">
                            {{ 'duplicate' | myLbTranslate }}
                        </button>
                    </ng-template>
                </app-action-dots>
            </ng-template>
        </app-column>
    </app-table>
</div>
<div *ngIf="componentEmbedded" class="component-footer embedded">
    <button
        class="outline"
        id="cancel"
        (click)="callBack()">{{ 'cancel' | myLbTranslate }}
    </button>
</div>

<ng-container #addEditCipherListContainer></ng-container>
<app-modal-wrapper modalTitle="cipher_list_view" modalId="viewCipherList">
    <app-modal-body>
        <ul *ngFor="let cipher of this.builtinCiphers">
            <li>{{ cipher }}</li>
        </ul>
    </app-modal-body>
</app-modal-wrapper>

