<h1>{{ (editId ? 'gslb_edit_member' : 'gslb_add_member') | myLbTranslate }}</h1>
<form [formGroup]="serviceForm" [loaded]="!wizardService.isLoading">
    <div [formGroupName]="subStepEnum.GENERAL" [hidden]="getWizardSubStepState(subStepEnum.GENERAL)">
        <h2>{{ subStepEnum.GENERAL | myLbTranslate }}</h2>
        <section data-control-name="name">
            <div *ngIf="!isInputHidden('name')" class="form-group">
                <label for="domain{{templateIndex}}">{{ 'name' | myLbTranslate }}*</label>
                <div class="form-content">
                    <ng-container *ngIf="namePrefix">
                        <span class="name-prefix">{{ namePrefix }}</span>
                    </ng-container>
                    <input
                        id="name{{templateIndex}}"
                        formControlName="name"
                        name="name"
                        title="name"
                        type="text">
                    <app-validation-messages [control]="serviceForm.get(subStepEnum.GENERAL + '.name')">
                    </app-validation-messages>
                    <app-help class="help-button" tag="members_name"></app-help>
                </div>
            </div>
        </section>
        <section data-control-name="ip">
            <div *ngIf="!isInputHidden('ip')" class="form-group">
                <label for="ip{{templateIndex}}">{{ 'ip' | myLbTranslate }}*</label>
                <div class="form-content">
                    <input
                        formControlName="ip"
                        id="ip{{templateIndex}}"
                        name="ip"
                        type="text">
                    <app-validation-messages [control]="serviceForm.get(subStepEnum.GENERAL + '.ip')">
                    </app-validation-messages>
                    <app-help class="help-button" tag="members_ip"></app-help>
                </div>
            </div>
        </section>
        <section data-control-name="monitorIp">
            <div *ngIf="!isInputHidden('monitorIp')" class="form-group">
                <label for="monitorIp{{templateIndex}}">{{ 'gslb_monitor_ip' | myLbTranslate }}*</label>
                <div class="form-content">
                    <input
                        formControlName="monitorIp"
                        id="monitorIp"
                        name="monitorIp"
                        type="text">
                    <app-validation-messages [control]="serviceForm.get(subStepEnum.GENERAL + '.monitorIp')">
                    </app-validation-messages>
                    <app-help class="help-button" tag="members_monitor-ip"></app-help>
                </div>
            </div>
        </section>
        <section data-control-name="monitorIp">
            <div *ngIf="!isInputHidden('weight')" class="form-group">
                <label for="members-weight{{templateIndex}}">{{ 'weight' | myLbTranslate }}*</label>
                <div class="form-content">
                    <app-dropdown
                        dropDownId="members-weight{{templateIndex}}"
                        formControlName="weight"
                        [formGroup]="getFormGroup(subStepEnum.GENERAL)"
                        [content]="dropdown.membersWeight">
                    </app-dropdown>
                    <app-validation-messages [control]="serviceForm.get(subStepEnum.GENERAL + '.weight')">
                    </app-validation-messages>
                    <app-help class="help-button" tag="members_weight"></app-help>
                </div>
            </div>
        </section>
    </div>
</form>
