<div id="menu-footer-details-wrapper" [class]="menuOpen ? 'show' : 'hide'">
    <app-license-pill></app-license-pill>
    <app-lb-client-details></app-lb-client-details>
</div>

<div id="client-details-trigger" [class]="!menuOpen ? 'show' : 'hide'" (click)="toggleTooltip()">
    <img ngSrc='../../../../../../assets/shared/icons/help-i.svg' alt="help icon" height="20" width="20">
</div>

<ng-container *ngIf="tooltipToggle">
    <div class="client-details-container">
        <app-license-pill></app-license-pill>
        <app-lb-client-details></app-lb-client-details>
    </div>
</ng-container>
