<div class="component-main">
    <div class="content">
        <h1>{{ (editId ? 'gslb_edit_member' : 'gslb_add_member') | myLbTranslate }}</h1>
    </div>
    <form [formGroup]="memberForm" id="real_server_form" name="real_server_form">
        <div class="form-group">
            <label for="real_server_name">{{ 'name' | myLbTranslate }}*</label>
            <div class="form-content">
                <input
                    formControlName="name"
                    id="real_server_name"
                    name="real_server_name"
                    type="text"
                    value="">
                <app-validation-messages [control]="memberForm.get('name')"></app-validation-messages>
                <app-help class="help-button" tag="members_name"></app-help>
            </div>
        </div>
        <div class="form-group">
            <label for="real_server_ip">{{ 'ip' | myLbTranslate }}*</label>
            <div class="form-content">
                <input
                    formControlName="ip"
                    id="real_server_ip"
                    name="real_server_ip"
                    type="text"
                    value="">
                <app-validation-messages [control]="memberForm.get('ip')"></app-validation-messages>
                <app-help class="help-button" tag="members_ip"></app-help>
            </div>
        </div>
        <div class="form-group">
            <label for="real_server_monitorIp">{{ 'gslb_monitor_ip' | myLbTranslate }}*</label>
            <div class="form-content">
                <input
                    formControlName="monitorIp"
                    id="real_server_monitorIp"
                    name="real_server_monitorIp"
                    type="text"
                    value="">
                <app-validation-messages [control]="memberForm.get('monitorIp')"></app-validation-messages>
                <app-help class="help-button" tag="members_monitor-ip"></app-help>
            </div>
        </div>
        <div class="form-group">
            <!--suppress XmlInvalidId -->
            <label for="gslb-members">{{ 'weight' | myLbTranslate }}*</label>
            <div class="form-content">
                <app-dropdown
                    dropDownId="gslb-members"
                    formControlName="weight"
                    [formGroup]="memberForm"
                    [content]="dropdown.membersWeight">
                </app-dropdown>
                <app-help class="help-button" tag="members_weight"></app-help>
            </div>
        </div>
    </form>
</div>
<div class="component-footer">
    <button class="outline" [routerLink]="editId ? '../../..' : '../..'" [queryParams]="{sFilter: 'members'}">
        {{ 'cancel' | myLbTranslate }}
    </button>
    <app-loading-button
        buttonText="save"
        buttonClass="primary"
        [isDisabled]="memberForm.invalid || memberForm.pristine"
        [callBack]="submitMember.bind(this)">
    </app-loading-button>
</div>
