<h1>{{ 'services_frontend_configuration' | myLbTranslate }}</h1>
<form [formGroup]="serviceForm" [class.full-width]="isFormFullWidth" [loaded]="!wizardService.isLoading">
    <!-- General Options start -->
    <div [formGroupName]="subStepEnum.GENERAL" [hidden]="getWizardSubStepState(subStepEnum.GENERAL)">
        <h2>{{ subStepEnum.GENERAL | myLbTranslate }}</h2>
        <section data-control-name="label">
            <div *ngIf="!isInputHidden('name')" class="form-group">
                <label for="frontendLabel{{templateIndex}}">{{ 'name' | myLbTranslate }}*</label>
                <div class="form-content">
                    <ng-container *ngIf="namePrefix">
                        <span class="name-prefix">{{ namePrefix }}</span>
                    </ng-container>
                    <input
                        id="frontendLabel{{templateIndex}}"
                        formControlName="name"
                        name="name"
                        title="name"
                        type="text">
                    <app-validation-messages [control]="serviceForm.get(subStepEnum.GENERAL + '.name')">
                    </app-validation-messages>
                    <app-help class="help-button" tag="l4-frontend_name"></app-help>
                </div>
            </div>
        </section>
        <section data-control-name="mode">
            <div *ngIf="!isInputHidden('mode')" class="form-group">
                <label for="mode{{templateIndex}}">{{ 'services_layer_4_mode' | myLbTranslate }}</label>
                <div class="form-content">
                    <app-dropdown
                        dropDownId="mode{{templateIndex}}"
                        [formGroup]="getFormGroup(subStepEnum.GENERAL)"
                        formControlName="mode"
                        [content]="dropdown.modes">
                    </app-dropdown>
                    <app-help class="help-button" tag="l4-frontend_mode"></app-help>
                </div>
                <span *ngIf="serviceForm.get(subStepEnum.GENERAL + '.mode').disabled" class="note">
                        {{ 'services_frontend_mode_disabled_message' | myLbTranslate }}
                    </span>
            </div>
        </section>
        <section data-control-name="forwardingMethod">
            <div class="form-group">
                <label for="forwardingMethod{{templateIndex}}">{{ 'forwarding_method' | myLbTranslate }}</label>
                <div class="form-content">
                    <app-dropdown
                        dropDownId="forwardingMethod{{templateIndex}}"
                        [formGroup]="getFormGroup(subStepEnum.GENERAL)"
                        formControlName="forwardingMethod"
                        [content]="dropdown.forwarding">
                    </app-dropdown>
                    <app-help class="help-button" tag="l4-frontend_forwarding-method"></app-help>
                </div>
            </div>
        </section>
        <section data-control-name="ports" *ngIf="!isInputHidden('ports')">
            <app-lb-checkbox
                label="{{ 'services_individual_ports' | myLbTranslate }}"
                [hideValidation]="true"
                formControlName="enableIndividuals"
                [formGroup]="getFormGroup(subStepEnum.GENERAL)">
                <div class="form-group individual" *ngFor="let control of portIndividual.controls; index as i">
                    <label *ngIf="i === 0">Port</label>
                    <div class="form-content">
                        <input
                            id="individual{{i}}"
                            [formControl]="getFormControl(control)"
                            name="ports"
                            class="port"
                            placeholder="{{ 'services_ports_placeholder' | myLbTranslate }}"
                            title="ports"
                            type="number"
                            (change)="checkForDuplicatePorts()">
                        <app-validation-messages
                            [control]="serviceForm.get(subStepEnum.GENERAL + '.portIndividuals.' + i)">
                        </app-validation-messages>
                        <button
                            [disabled]="this.portIndividual.length === 1"
                            class="button-as-link"
                            (click)="removePortIndividual(i)">{{ 'delete' | myLbTranslate }}
                        </button>
                        <button
                            [disabled]="!serviceForm.get(subStepEnum.GENERAL + '.enableIndividuals').valid"
                            class="button-as-link"
                            (click)="addPortIndividual()">{{ 'add_another' | myLbTranslate }}
                        </button>
                    </div>
                </div>
            </app-lb-checkbox>
            <app-lb-checkbox
                [formGroup]="getFormGroup(subStepEnum.GENERAL)"
                formControlName="enableRanges"
                label="{{ 'services_port_ranges' | myLbTranslate }}">
                <ng-container *ngFor="let control of portRange.controls; index as i">
                    <div class="form-content">
                        <div class="form-row">
                            <div class="form-group">
                                <label *ngIf="i === 0">From</label>
                                <div class="form-content">
                                    <input
                                        [formControl]="control['controls']['portStart']"
                                        id="rangeStart{{i}}"
                                        [min]="getStartMinValue(i) || 0"
                                        class="port"
                                        max="1022"
                                        name="ports"
                                        title="ports"
                                        type="number" (change)="checkForOverlappingRange()">
                                    <app-validation-messages
                                        [control]="serviceForm.get(subStepEnum.GENERAL + '.portRanges.' + i + '.portStart')">
                                    </app-validation-messages>
                                </div>
                            </div>
                            <div class="form-group">
                                <label *ngIf="i === 0">To</label>
                                <div class="form-content">
                                    <input
                                        [formControl]="control['controls']['portEnd']"
                                        class="port"
                                        id="rangeEnd{{i}}"
                                        [min]="getEndMinValue(i)"
                                        max="1022"
                                        name="ports"
                                        [placeholder]="'min ' + getEndMinValue(i) || 'ports'"
                                        title="ports"
                                        type="number" (change)="checkForOverlappingRange()">
                                    <app-validation-messages
                                        [control]="serviceForm.get(subStepEnum.GENERAL + '.portRanges.' + i + '.portEnd')">
                                    </app-validation-messages>
                                    <button
                                        [disabled]="!control.valid || this.portRange.length === 1"
                                        class="button-as-link"
                                        (click)="removePortRange(i)">{{ 'delete' | myLbTranslate }}
                                    </button>
                                    <button
                                        [disabled]="!serviceForm.get(subStepEnum.GENERAL + '.portRanges').valid"
                                        class="button-as-link"
                                        (click)="addPortRange()">{{ 'add_another' | myLbTranslate }}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </app-lb-checkbox>
        </section>
    </div>

    <!-- Floating IPs -->
    <div [formGroupName]="subStepEnum.FLOATING_IPS" [hidden]="getWizardSubStepState(subStepEnum.FLOATING_IPS)">
        <section data-control-name="fipId">
            <div class="embedded" *ngIf="!isInputHidden('fipId')">
                <app-lb-floating-ip
                    [fips]="floatingIPs"
                    [groups]="ipGroups"
                    [componentEmbedded]="true"
                    [selectedKey]="serviceForm.get(subStepEnum.FLOATING_IPS + '.fipId').value"
                    [validationError]="fipValidation"
                    (fipId)="setFipId($event)">
                </app-lb-floating-ip>
            </div>
        </section>
    </div>
</form>

