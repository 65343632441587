<ng-container *ngIf="!isAddFallbackPageVisible">
    <div class="content-space-between">
        <h2>{{ 'fallback' | myLbTranslate }}</h2>
        <button class="primary" (click)="setUpFallbackPageComponent()">{{ 'fallback_add' | myLbTranslate }}</button>
    </div>
    <div class="form-group">
        <!--suppress XmlInvalidId -->
        <label for="fallbackServerId">{{ 'fallback_server' | myLbTranslate }}*</label>
        <div class="form-content">
            <app-dropdown
                dropDownId="fallbackServerId"
                [formGroup]="formGroup"
                [selectedKey]="selectedKey"
                formControlName="fallbackServerId"
                [dropdownWidth]="220"
                [search]="true"
                [content]="dropdown.fallbackServers">
            </app-dropdown>
            <app-help class="help-button" tag="l4-backend_fallback-server-id"></app-help>
        </div>
    </div>
    <ng-container *ngIf="showFallbackForwardingMode">
        <section data-control-name="fallbackServerForwardingMode">
            <div class="form-group">
                <!--suppress XmlInvalidId -->
                <label for="fallbackServerForwardingMode">{{ 'forwarding_mode' | myLbTranslate }}</label>
                <div class="form-content">
                    <app-dropdown
                        dropDownId="fallbackServerForwardingMode"
                        [formGroup]="formGroup"
                        [selectedKey]="formGroup.controls['fallbackServerForwardingMode'].value"
                        formControlName="fallbackServerForwardingMode"
                        [content]="dropdown.forwardingModes">
                    </app-dropdown>
                    <app-validation-messages
                        [control]="formGroup.controls['fallbackServerForwardingMode']">
                    </app-validation-messages>
                    <app-help class="help-button" tag="l4-backend_fallback-server-forwarding-mode"></app-help>
                </div>
            </div>
        </section>
    </ng-container>
</ng-container>

<ng-container #addContainer></ng-container>
